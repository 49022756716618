import { defineStore } from 'pinia';

export const useLabStore = defineStore('lab', {
    state: () => {
        return {
            loading: false,
            values: [],
            filter: {
                search: '',
                page: 1,
                pageSize: 10,
                active: false,
                withoutGoods: false,
                withoutRecipes: false,
                classes: [],
                archived: false
            },
            isSpecUpdated: false
        }
    },
    getters: {
        getValues: (state) => state.values,
        getFilters: (state) => state.filter,
        getIsSpecUpdated: (state) => state.isSpecUpdated
    },
    actions: {
        setLabFilter(key, value) {
            this.filter[key] = value;
        },
        setLabValues(values) {
            this.values = values
        },
        setIsSpecUpdated(value) {
            this.isSpecUpdated = value
        },
        resetFilters() {
            this.filters = {
                search: null,
                page: 1,
                pageSize: 10,
            }
        },
        resetData() {
            this.values = []
            this.filter = {
                search: null,
                page: 1,
                pageSize: 10,
                classes: [],
                archived: false,
                active: false,
                withoutRecipes: false,
                withoutGoods: false
            }
        }
    }
})
