<template>
    <div class="chart-block">
        <VueEcharts
            ref="chart"
            :option="option"
        />
    </div>
</template>

<script>
import VueEcharts from "vue-echarts";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment/moment";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import {httpService} from "@/services/http.service";
import {useCommonStore} from "@/store/common";

export default {
    name: "SummaryVehiclesCountBar",
    components: {VueEcharts},
    mixins: [functionsMixin],
    data() {
        return {
            schedules: []
        }
    },
    props: {
        mixOrders: Array,
        hours: Array
    },
    created() {
        this.getSchedules()
    },
    mounted() {
        this.chart = this.$refs.chart;
    },
    methods: {
        getSchedules(data) {
            httpService().post(this.api.vehicle.getVehicleSchedulesForDay, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: data ? data : this.filtersDate
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.schedules = data.schedules
                }
            })
        },
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            filters: 'getFilters',
        }),
        ...mapState(useCommonStore, {
            vehicles: 'getVehicles',
        }),
        filtersDate() {
            return this.filters['filter[date]'];
        },
        values() {
            const hoursValues = this.hours.map(hour => {
                let count = 0;
                const uniqueVehicles = new Set();
                this.mixOrders.forEach(order => {
                    let startAt = moment(order.start_at).tz(this.moduleTimezone);
                    if (startAt.hour() === hour.value) {
                        uniqueVehicles.add(order.vehicle_id);
                    }

                });
                const allVehicles = this.schedules.length ? this.schedules.length : this.vehicles.length
                count = uniqueVehicles.size
                const percent = Math.round(count / allVehicles * 100)
                return { value: percent, count: count };
            });

            const maxCount = Math.max(...hoursValues.map(h => h.value));
            const threshold80 = maxCount * 0.8;
            const threshold30 = maxCount * 0.3;

            return hoursValues.map(hour => {
                if (hour.value > threshold80) {
                    return { ...hour, itemStyle: { color: '#1984e6' } };
                } else if (hour.value >= threshold30 && hour.value <= threshold80) {
                    return { ...hour, itemStyle: { color: '#49a7fd' } };
                } else {
                    return { ...hour, itemStyle: { color: '#b8ddfe' } };
                }
            });
        },
        option() {
            return {
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        const hourData = this.values[params.dataIndex];
                        return `${params.name}:00 - ${hourData.count} авто в работе`;
                    }
                },
                grid: {
                    left: '10%',
                    right: '0%',
                    bottom: '5%',
                    top: '0%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: this.hours,
                    axisLabel: {
                        color: '#c9ccd0' // Add this line to change the label color to red
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#8a909a' // Change the line color to red
                        }
                    }
                },
                yAxis: {
                    max: 100,
                    min: 0,
                    type: 'value',
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                series: [
                    {
                        data: this.values,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: '#F9F9F9'
                        }
                    }
                ]
            }
        }
    },
    watch: {
        'filtersDate': function(val, prevVal) {
            if(val && prevVal) {
                const date = moment(val).format('YYYY-MM-DD');
                const prevDate = moment(prevVal).format('YYYY-MM-DD');
                if(date !== prevDate) {
                    this.getSchedules(date);
                }
            }
        }
    }
}
</script>

<style scoped>
.chart-block {
    width: 230px;
    height: 60px;
    margin-bottom: -5px;

    @media screen and (max-width: 1433px) {
        width: 180px;
        padding-right: 12px;
    }

    @media screen and (max-width: 1024px) {
        width: 170px;
        margin-block: 0px;
    }

    @media screen and (max-width: 930px) {
        width: 160px;
    }

    @media screen and (max-width: 880px) {
        width: 200px;
    }

    @media screen and (max-width: 630px) {
        width: 160px;
    }

    @media screen and (max-width: 480px) {
        width: 150px;
        padding-right: 0;
    }

    @media screen and (max-width: 440px) {
        width: 250px;
        height: 60px;
    }

        @media screen and (max-width: 360px) {
        width: 210px;
    }
}
</style>
