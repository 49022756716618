<template>
	<el-form-item v-if="select !== false" :label="label" :label-width="labelWidth" v-bind:class="[extraClass, { 'is-required': required }]">
		<div class="inputModal" :class="[ {disabled: disabled}, {pointer: !disabled}, {empty: !itemId}]">
			<div class="d-flex">
				<div class="inputModalWrapper" @click="openSelectDialog">
					{{ itemId ? getInfo(this.itemId, this.values) : this.placeholder }}
				</div>
				<div class="inputModalClear" v-if="itemId" @click="clearItemId()">
					<i class="el-icon el-input__icon el-input__clear">
						<CloseRoundIcon/>
					</i>
				</div>
			</div>
		</div>
	</el-form-item>

	<el-dialog
		v-model="selectDialog"
		:title="dialogTitle"
		:width="dialogSize"
		:destroy-on-close="true"
        v-if="!createDialog"
	>
        <template v-if="loadingTable">
            <TableDataLoader text="тарифов"/>
        </template>
        <template v-else>
            <TableHeadBlock v-model="search"
                            :loading="loading"
                            :disable-filter="true"
                            :disable-update-button="true"
                            :search-focused="true"
                            :disable-create-button="disableCreateButton"
                            create-button-label="Прикрепить тариф"
                            @openCreateValueDrawer="() => createDialog = true"/>
            <el-table empty-text="Информация не найдена"
                      class="mt-15 w-100 defaultTableHeight"
                      v-loading="loadingTable"
                      @current-change="handleCurrentChange"
                      :data="displayData">
                <el-table-column prop="id"
                                 label="#"
                                 width="100"
                                 class-name="pointer"
                                 sortable>
                    <template v-slot="scope">
                                <span class="textSM textMedium"
                                      :class="[{ neutral200: scope.row.id !== itemId }, { primary600: scope.row.id === itemId }]">
                                    #{{ formatNumber(scope.row.id) }}
                                </span>
                    </template>
                </el-table-column>
                <el-table-column prop="name"
                                 label="Название"
                                 class-name="pointer"
                                 sortable>
                    <template v-slot="scope">
                        <span class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="integration"
                                 label="Тип"
                                 class-name="pointer"
                                 width="220px">
                    <template v-slot="scope">
                        <template v-if="scope.row.depends_on">
                            <span
                                class="textRegular textSM neutral400">{{ getInfo(scope.row.depends_on, dependsTypes) }}</span>
                        </template>
                        <template v-else>
                            <span class="textRegular textSM neutral200">-</span>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
            <TablePagination v-model="pageSize"
                             :total="valueLength"
                             :loading="loading"
                             @action="setPage"/>
        </template>
	</el-dialog>
    <el-dialog
        v-model="createDialog"
        title="Новый тариф"
        :width="mobile ? '100%' : '80%'"
        :destroy-on-close="true"
    >
        <ServiceTariffCreateDialog
            :defaultType="type"
            @create="handleAddServiceTariff"
        />
    </el-dialog>
</template>

<script>
import {mapState, mapActions} from 'pinia'
import {useCommonStore} from '@/store/common'

import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import tableMixin from "@/mixins/tableMixin";
import CloseRoundIcon from "@/views/components/Icons/CloseRoundIcon.vue";
import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import ServiceTariffCreateDialog from "@/views/settings/serviceTariffs/ServiceTariffCreateDialog";

export default {
	name: 'FormSelectTariff',
	components: {
		CloseRoundIcon, TableDataLoader, TableHeadBlock, TablePagination, ServiceTariffCreateDialog
	},
	mixins: [functionsMixin, mobileCheckMixin, tableMixin],
	inject: ['api'],
	props: [
        'addedTariffs',
		'modelValue',
		'items', // список тарифов для отображения в таблице
		'label',
		'labelWidth',
		'placeholder',
		'newItem',
		'disabled', // неактивное поле
		'required', // обязательное поле
		'extraClass', // дополнительные классы для стилизации,
		'disableCreateButton', // cкрытие кнопки добавления нового элемента в модалке
		'type' // тип тарифа: mix(доставка бетона), downtime(простой), pump(насос)
	],
	data() {
		return {
			itemId: this.modelValue,
            loading: false,
			selectDialog: false,
            createDialog: false,
            search: '',
            loadingTable: false,
            pageSize: 10,
		}
	},
    created() {
    },
	computed: {
        ...mapState(useCommonStore, {
            serviceTariffs: 'getServiceTariffs',
        }),
		dialogTitle() {
			const titles = {
				pump: 'Выберите тариф на насос',
				mix: 'Выберите тариф на доставку',
				downtime: 'Выберите тариф на простой'
			}
			return titles[this.type]
		}
	},
	methods: {
        ...mapActions(useCommonStore, [
            'pullServiceTariffs',
        ]),
		openSelectDialog() {
			if (!this.disabled) {
				this.selectDialog = true
			}
		},
		clearItemId() {
			this.itemId = null
		},
		handleCurrentChange(tariff) {
            if(tariff) {
                this.selectItem(tariff?.id);
            }
            this.selectDialog = false;
		},
        filterValues() {
            this.filtered = this.items?.length ? this.items : this.values;

            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            }

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        selectItem(id) {
            this.itemId = id;
            this.$emit('update:modelValue', id);
        },
        handleAddServiceTariff(val) {
            if (val) {
                this.selectItem(val.id)
                if (this.items) {
                    this.$emit('update')
                }
                else this.pullServiceTariffs()
            }
            this.createDialog = false;
            this.selectDialog = false;
        },
        setValues() {
            this.values = this.type
                    ? this.serviceTariffs.filter(item => item.type === this.type)
                    : this.serviceTariffs
                this.valueLength = this.values.length
        }
	},
	watch: {
		'modelValue': {
			handler: function () {
				this.itemId = this.modelValue
			},
			deep: true,
			immediate: true
		},
		'itemId': {
			handler: function (val) {
				if (val) {
					this.values.sort((a, b) => {
						if (a.id === val) return -1;
						if (b.id === val) return 1;
						return 0;
					});
				}
			},
			deep: true,
			immediate: true
		},
        type: {
            handler: function (val) {
                this.setValues(val);
            },
            deep: true,
            immediate: true
        },
        serviceTariffs: {
            handler: function (val) {
                this.setValues(val);
            },
            deep: true,
            immediate: true
        },
        'items': {
			handler: function (val) {
				if (val && val.length) {
					this.values = this.items
					this.valueLength = this.items.length
				} else {
                    this.setValues()
                }
			},
			deep: true,
			immediate: true
		},
	}
}
</script>
