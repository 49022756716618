<template>
    <EmptyState
        :values="displayData"
        :filter="filter"
        :search="search"
        :loading="loading"
        list-types="заявок"
    />

    <div v-if="displayData.length > 0">
        <div class="el-table ordersTable mt-15">
            <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                <thead>
                <tr>
                    <th style="width: 70px">
                        <div class="cell textXS textRegular neutral200">
                            #
                        </div>
                    </th>
                    <th style="width: 84px" v-if="isDocRequired">
                        <div class="cell textXS textRegular neutral200">
                            # с АСУ
                        </div>
                    </th>
                    <th style="width: 90px">
                        <div class="cell textXS textRegular neutral200">
                            Заказ
                        </div>
                    </th>
                    <th :style='windowWidth > 1220 ? "width: 130px" : "width: 100px"'>
                        <div class="cell textXS textRegular neutral200">
                            Статус
                        </div>
                    </th>
                    <th style="width: 85px">
                        <div class="cell textXS textRegular neutral200">
                            Объем
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '65' : '75'}px`">
                        <div class="cell textXS textRegular neutral200">
                            <el-tooltip effect="dark" placement="top" content="Старт загрузки на заводе"
                                        :show-after="150">
                                Загрузка
                            </el-tooltip>
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '70' : '80'}px`">
                        <div class="cell textXS textRegular neutral200">
                            <el-tooltip effect="dark" placement="top" content="Время прибытия на объект"
                                        :show-after="150">
                                Доставка
                            </el-tooltip>
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '65' : '70'}px`">
                        <div class="cell textXS textRegular neutral200">
                            <el-tooltip effect="dark" placement="top" content="Время возвращения на завод"
                                        :show-after="150">
                                Возврат
                            </el-tooltip>
                        </div>
                    </th>
                    <th  :style="`width: ${windowWidth < 1400 ? '120px' : 'initial'}`">
                        <div class="cell textXS textRegular neutral200">
                            Контрагент
                        </div>
                    </th>
                    <th :style="`width: ${vehicleColumnWidth}px`">
                        <div class="cell textXS textRegular neutral200 nowrap">
                            ТС
                        </div>
                    </th>
                    <th :style="`width: ${driverColumnWidth}px`">
                        <div class="cell textXS textRegular neutral200 maxLines2">
                            Водитель
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '120px' : 'initial'}`">
                        <div class="cell textXS textRegular neutral200 maxLines2">
                            Рецепт
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '80' : mixersColumnWidth}px;`">
                        <div class="cell textXS textRegular neutral200 maxLines2">
                            Завод
                        </div>
                    </th>
                    <th style="width: 48px">
                        <div class="cell textXS textRegular neutral200"></div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="mixOrdersByHour in mixOrdersByHours" :key="'hours' + mixOrdersByHour.hour">
                    <tr class="el-table__row ordersTableBorder">
                        <td :colspan="isDocRequired ? 4 : 3" class="bg-neutral30">
                            <div class="cell">
                                <div class="ordersTableItem">
                                    {{ mixOrdersByHour.hour }}:00
                                </div>
                            </div>
                        </td>
                        <td colspan="1" class="bg-neutral30">
                            <div class="cell">
                                <div class="ordersTableItem">
                                    {{ getSumOrdersByHour(mixOrdersByHour.orders) }} м³
                                </div>
                            </div>
                        </td>
                        <td colspan="9" class="bg-neutral30">

                        </td>
                    </tr>
                    <template v-for="order in mixOrdersByHour.orders" :key="order.id">
                        <tr class="el-table__row"
                            :class="{ordersTableBorder: order.status === 'new' || order.status === 'done'}">
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem">
                                        <span class="textMedium" :class="[
                                            { warning300 : order.confirm && order.status === 'new' },
                                            { primary600 : order.confirm && (order.status !== 'new' && order.status !== 'done') },
                                            { success400 : order.status === 'done' },
                                        ]">#{{
                                                order.id
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td v-if="isDocRequired">
                                <div class="cell">
                                    <div class="ordersTableItem">
                                        <span :class="order.confirm ? 'neutral900' : 'neutral200'">
                                                {{ order.doc ? '#' + order.doc : '-' }}
                                            </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem">
                                        <span class="textMedium primary600 pointer"
                                              @click="$router.push({name: 'DispatchOrderGroupsDetails', params: { groupId: order.order_group_id }})">{{
                                                order.order_group_id ? '№' + order.order_group_id : '-'
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem">
                                        <template v-if="order.confirm">
                                            <span class="textMedium success400">{{ getStatusLabel(order.status) }}</span>
                                        </template>
                                        <template v-else>
                                            <span class="textRegular neutral200">Не подтвержд.</span>
                                        </template>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItem__volume"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span>{{
                                                (order.status === 'new' || order.status === 'loading') ? order.total : (order.done === 0 ? order.total : order.done)
                                            }} м³</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub ordersTableItemTime"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <div class="ordersTableItem__loading-time">
                                            <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    dateToTime(order.start_at)
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub ordersTableItemTime"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <div class="ordersTableItem__market-time">
                                            <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    dateToTime(order.arrive_at)
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub ordersTableItemTime"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <div class="ordersTableItem__market-time">
                                            <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    dateToTime(order.return_at)
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem" :class="{ ordersTableItemConfirm: order.confirm }">
                                        <el-tooltip
                                            placement="top"
                                            :content="order.company" :disabled="windowWidth > 1560">
                                            <span class="maxLines2">{{ order.company }}</span>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem" :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span class="cutOverflow">{{
                                                order.vehicle?.number ? getVehicleNumber(order.vehicle.number) : '-'
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                order.driver?.name ? formatName(order.driver?.name) : '-'
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <el-tooltip effect="dark" placement="top"
                                            :content="order.good?.name ?? '-'"
                                            :disabled="windowWidth > 1560">
                                            <span class="maxLines2" :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                order.good?.name ?? '-'
                                            }}</span>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                mixerName(order.factory_name, order.mix.mix_name, order.mixer.mixer_name)
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div :class="{ ordersTableItemConfirm: order.confirm }">
                                        <el-button v-if="order.order_group_id"
                                                   @click="openMixOrderDetails(order, order.order_group_id)"
                                                   size="small"
                                                   style="height: 24px !important; width: 24px !important;">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.0498 6L8 13" stroke="#505968" stroke-width="1.3"
                                                      stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 3H8.1V3.1H8V3Z" stroke="#505968" stroke-width="2"
                                                      stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </el-button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
                </tbody>
            </table>
        </div>

        <TablePagination
            v-model="pageSize"
            :total="valueLength"
            @action="setPage"
        />
    </div>

    <el-dialog
        class="group-order-action-dialog"
        v-model="orderDetailsDialog"
        title=""
        :width="dialogSize"
        :destroy-on-close="true"
        @close="unlockOrder"
    >
        <MixOrderDetailsGroup
            v-if="!orderDetailsDialogLoading"
            :mix-order-id="selectedMixOrderId"
            :order-group="selectedGroup"
            :mix-order-details="selectedMixOrder"
            @close="closeOrderDetailsDialog"
            @get="moduleGet"
        />
        <div v-else class="text-center mt-30">
            <ReportLoader/>
            <h3>Загрузка данных об отгрузке</h3>
        </div>
    </el-dialog>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import functionsMixin from '@/mixins/functionsMixin'
import getUnitFromGoodsMixin from '@/mixins/getUnitFromGoodsMixin'
import {mapState} from 'pinia'
import {httpService} from '@/services/http.service'
import {useCommonStore} from '@/store/common'
import iconsMixin from "@/mixins/iconsMixin";
import EmptyState from "@/views/components/EmptyState.vue";
import mixTabs from "@/mixins/tabs/mixTabs";
import moment from "moment/moment";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import MixOrderDetailsGroup from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroup.vue";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";

export default {
    name: 'DispatchOrdersTable',
    props: [
        'tab',
        'date',
        'needUpdate'
    ],
    components: {
        ReportLoader,
        MixOrderDetailsGroup,
        TablePagination,
        EmptyState,
    },
    mixins: [
        tableMixin, mobileCheckMixin, functionsMixin, getUnitFromGoodsMixin, iconsMixin, mixTabs, mixOrderInfo
    ],
    inject: [
        'api'
    ],
    data() {
        return {
            loading: false,
            loadOrders: false,
            createMixOrderDrawer: false,
            createMixOrderMixDialog: false,
            orderDetailsDrawer: false,
            orderEdit: null,
            orderDetails: {
                createMix: false,
            },
            tableData: [],
            firstLoad: true,
            pageSize: 30,
            orderDetailsDialog: false,
            selectedMixOrderId: null,
            selectedGroup: null,
            selectedMixOrder: null,
            mixOrdersByHours: [],
            orderDetailsDialogLoading: true
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations'
        }),
        isDocRequired() {
            const mixIntegrations = this.integrations.filter(el => el.account_module?.module_key === 'mix');
            return !!mixIntegrations.length
        },
        uniqueNames() {
            const extractors = {
                mixers: (order) =>
                    this.mixerName(order.factory_name, order.mix.mix_name, order.mixer.mixer_name),
                drivers: (order) =>
                    order.driver?.name ? this.formatName(order.driver.name) : '-',
                vehicles: (order) =>
                    order.vehicle?.number ? this.getVehicleNumber(order.vehicle.number) : '-',
            };

            const types = ['mixers', 'drivers', 'vehicles'];

            return types.reduce((result, type) => {
                result[type] = [...new Set(
                    this.mixOrdersByHours.flatMap(mixOrder =>
                        mixOrder.orders.map(extractors[type])
                    )
                )];
                return result;
            }, {});
        },
        mixersColumnWidth() {
            return this.calculateColumnWidth(this.uniqueNames.mixers, 60);
        },
        driverColumnWidth() {
            return this.calculateColumnWidth(this.uniqueNames.drivers, 100, 160);
        },
        vehicleColumnWidth() {
            return this.calculateColumnWidth(this.uniqueNames.vehicles, 110);
        },
    },
    created() {
        this.moduleGet()
        this.firstLoad = false
        window.Echo.channel('laravel_database_ChannelDispatchOrderGroup')
            .listen(`.Algorithm.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Create.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Update.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Delete.${this.$route.params.id}`, () => {
                this.moduleGet()
            });

    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelDispatchOrderGroup`);
    },
    methods: {
        dateToTime(data) {
            if (data) {
                return moment.parseZone(data).format('HH:mm')
            }
            return '-'
        },
        unlockOrder() {
            if (this.selectedMixOrderId) {
                httpService().post(this.api.mixOrder.unlockOrder + '/' + this.selectedMixOrderId, {
                    account_modules_id: this.$route.params.id,
                    account_module_reserve_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                })
            }
        },
        getSumOrdersByHour(orders) {
            let sum = 0
            orders.forEach((order) => {
                sum += order.total
            })
            return sum
        },
        async moduleGet() {
            if (!this.loadOrders) {
                this.loadOrders = true
                this.loading = true
                let moduleKey = this.$route.meta.moduleKey
                let url = this.api.dispatch.orders.index

                try {
                    const response = await httpService().post(url, {
                        account_module_reserve_id: this.$route.params.id,
                        account_modules_id: this.$route.params.id,
                        module_key: moduleKey,
                        date: this.date
                    });

                    let data = response.data;
                    if (data.success) {
                        this.values = data.values;
                        this.valueLength = data.total;
                        this.$emit('updateCountOrders', this.values)
                        this.loading = false;
                    }

                } catch (error) {
                    this.loading = false;
                    console.log(error);
                }

                this.loadOrders = false
            }
        },
        openMixOrderDetails(order, groupId) {
            this.selectedMixOrder = order
            this.selectedMixOrderId = order.id
            this.selectedGroupId = groupId
            this.selectedGroup = null
            this.orderDetailsDialogLoading = true
            this.orderDetailsDialog = true

            httpService().post(this.api.mixOrderGroup.getOrder, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.selectedGroupId,
                type: 'full'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.selectedGroup = data.order
                    this.orderDetailsDialogLoading = false
                } else {
                    this.$message({
                        message: 'Ошибка получения заказа',
                        showClose: true,
                        type: 'error'
                    })
                    this.$router.push({name: 'dashboard'})
                    this.orderDetailsDialogLoading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.orderDetailsDialogLoading = false
            })

        },
        closeOrderDetailsDialog() {
            this.orderDetailsDialog = false
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length

            let result = this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

            // берем values, по свойсту hour (час) объединяем заявки. Заявки с одинаковым часом объединяем в один список
            this.mixOrdersByHours = result.reduce((acc, item) => {
                const hour = item.hour;
                if (!acc.find(obj => obj.hour === hour)) {
                    acc.push({ hour, orders: [item] });
                } else {
                    const existingObj = acc.find(obj => obj.hour === hour);
                    existingObj.orders.push(item);
                }
                return acc;
            }, []);

            return result
        },
        calculateColumnWidth(names, minWidth = 0, maxWidth) {
            const padding = this.windowWidth > 1440 ? 28 : 20;
            const stringWidth = this.findLongestStringWidth(names) + padding;
            if(maxWidth) {
                return (stringWidth < minWidth) ? minWidth : (stringWidth > maxWidth) ? maxWidth : stringWidth;
            }
            return (stringWidth < minWidth) ? minWidth : stringWidth;
        },
    },
    watch: {
        'date': {
            handler: function () {
                if (this.date) {
                    this.moduleGet()
                }
            },
            deep: true,
            immediate: true
        },
        'needUpdate': {
            handler: function () {
                this.moduleGet()
            },
            deep: false,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
