<template>
    <div class="page-header">
        <el-row :gutter="15">
            <el-col :md="17"
                    :lg="19"
                    :sm="24"
                    :xs="24">
                <div class="page-headerTitle"
                     v-bind:class="{
                        'page-headerBreadcrumb': this.checkSettingsPage(),
                    }">
                    <el-skeleton :loading="!title"
                                 animated>
                        <template #template>
                            <el-skeleton-item variant="h3"
                                              style="width: 280px; height: 18px" />
                        </template>
                        <template #default
                                  v-if="this.checkSettingsPage()">
                            <Breadcrumb :title="title" />
                        </template>
                        <template #default
                                  v-else>
                            <div class="page-headerTitleText d-flex">
                                <span style="width: 2px" @click="changeBG()"/>
                                <span class="title">{{ title }}</span>
                                <router-link v-if="!$route.name.includes('Faq') && user.role_id !== 6"
                                             :to="{ name: 'FaqIndex' }">
                                    <el-tooltip content="Помощь по разделу"
                                                placement="bottom">
                                        <div class="faqIcon">
                                            <FaqIcon />
                                        </div>
                                    </el-tooltip>
                                </router-link>

                                <router-link v-if="accountModuleId && user.role_id !== 3"
                                             :to="{
                                                name: 'ModuleEdit',
                                                params: { id: accountModuleId },
                                            }">
                                    <el-tooltip content="Настройки модуля"
                                                placement="bottom">
                                        <div class="settings">
                                            <SettingsIcon />
                                        </div>
                                    </el-tooltip>
                                </router-link>
                                <div v-if="user.id === 1" class="ml-5 content-center" @click="changeBG()">{{now}}</div>
                            </div>
                            <div style="display: flex; margin-top: -6px"
                                 v-show="!mobile">
                                <div v-if="relations"
                                     style="display: flex">
                                    <div class="page-headerTitleRelation"
                                         v-for="(
                                            relation, index
                                        ) in relations[0]"
                                         :key="index">
                                        <el-tooltip content="Bottom center"
                                                    placement="bottom"
                                                    effect="light">
                                            <template #content>
                                                Отправляет данные в<br />модуль
                                                "{{
                                                    relation.recipientName
                                                }}"<br /><br />
                                                <router-link v-if="user.role_id !== 3"
                                                             :to="{
                                                                name: 'RelationEdit',
                                                                params: {
                                                                    id: relation.id,
                                                                },
                                                            }">
                                                    Настроить
                                                </router-link>
                                            </template>
                                            <el-button>
                                                <ModuleIcon :module-key="relation.recipientKey
                                                    " />
                                            </el-button>
                                        </el-tooltip>
                                    </div>
                                    <div class="page-headerTitleRelation"
                                         v-for="(
                                            relation, index
                                        ) in relations[1]"
                                         :key="index">
                                        <el-tooltip content="Bottom center"
                                                    placement="bottom"
                                                    effect="light">
                                            <template #content>
                                                Получает данные из<br />модуля
                                                "{{
                                                    relation.senderName
                                                }}"<br /><br />
                                                <router-link v-if="user.role_id !== 3"
                                                             :to="{
                                                                name: 'RelationEdit',
                                                                params: {
                                                                    id: relation.id,
                                                                },
                                                            }">
                                                    Настроить
                                                </router-link>
                                            </template>
                                            <el-button>
                                                <ModuleIcon :module-key="relation.senderKey
                                                    " />
                                            </el-button>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="page-headerTitleIntegration"
                                     v-for="(integration, index) in integrations"
                                     :key="index">
                                    <el-tooltip content="Bottom center"
                                                placement="bottom"
                                                effect="light">
                                        <template #content>
                                            <span v-if="
                                                integration.key ===
                                                'VesySoft'
                                            ">
                                                Данный модуль интегрирован<br />с
                                                продуктом 1C Весовой терминал<br />
                                                компании "ВесыСофт"
                                            </span>
                                            <span v-if="
                                                integration.key ===
                                                'SmartWeight'
                                            ">
                                                Данный модуль интегрирован<br />с
                                                продуктом SmartWeight<br />
                                                компании "ТензоТехСервис"
                                            </span>
                                            <span v-if="
                                                integration.key ===
                                                'SmartMix'
                                            ">
                                                Данный модуль интегрирован<br />с
                                                продуктом SmartMix<br />
                                                компании "ТензоТехСервис"
                                            </span>
                                            <span v-if="integration.key === '1C'">
                                                Данный модуль интегрирован<br />с
                                                1C Бухгалтерия
                                            </span>
                                            <span v-if="
                                                integration.key ===
                                                'ConceptBCY'
                                            ">
                                                Данный модуль интегрирован<br />с
                                                продуктом Concept БСУ<br />
                                                компании "КИП-Сервис"
                                            </span>

                                            <br /><br />
                                            <router-link v-if="user.role_id !== 3"
                                                         :to="{
                                                            name: 'IntegrationsIndex',
                                                        }">Настроить
                                            </router-link>
                                        </template>
                                        <el-button>
                                            <VesySoftLogo v-if="
                                                integration.key ===
                                                'VesySoft'
                                            " />
                                            <TTSSmartWeightLogo v-if="
                                                integration.key ===
                                                'SmartWeight'
                                            " />
                                            <TTSSmartMixLogo v-if="
                                                integration.key ===
                                                'SmartMix'
                                            " />
                                            <OneCBuhLogo v-if="integration.key === '1C'" />
                                            <KIPConceptLogo v-if="
                                                integration.key ===
                                                'ConceptBCY'
                                            " />
                                        </el-button>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-skeleton>
                </div>
            </el-col>
            <el-col :md="7"
                    :lg="5"
                    v-show="!mobile">
                <el-dropdown>
                    <div class="page-headerUser">
                        <el-row>
                            <el-col :span="20">
                                <p>{{ user.fio }}</p>
                                <span>{{ user.company }}</span>
                            </el-col>
                            <el-col :span="4">
                                <el-button :icon="icons.arrowDown"
                                           circle></el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="$router.push({ name: 'PaymentIndex' })"
                                              v-if="user.balance && user.role_id !== 3">
                                <el-row>
                                    <el-col :span="9"> Баланс </el-col>
                                    <el-col :span="15">
                                        {{ formatNumber(user.balance) }} руб.
                                    </el-col>
                                </el-row>
                            </el-dropdown-item>
                            <el-dropdown-item @click="$router.push({ name: 'PaymentIndex' })"
                                              v-if="user.tariffName && user.role_id !== 3">
                                <el-row>
                                    <el-col :span="8"> Тариф </el-col>
                                    <el-col :span="16">
                                        {{ user.tariffName }}
                                    </el-col>
                                </el-row>
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="user.role_id !== 6"
                                @click="$router.push({
                                    name: 'UsersEdit',
                                    params: { id: user.id },
                                })
                                ">
                                <el-row>
                                    <el-col :span="21"> Профиль </el-col>
                                    <el-col :span="3">
                                        <el-icon>
                                            <ArrowRightIcon />
                                        </el-icon>
                                    </el-col>
                                </el-row>
                            </el-dropdown-item>
                            <el-dropdown-item @click="doLogout">
                                <el-row>
                                    <el-col :span="24"> Выйти </el-col>
                                </el-row>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import SettingsIcon from "./Icons/SettingsIcon";
import TTSSmartWeightLogo from "./Logo/TTSSmartWeightLogo";
import VesySoftLogo from "./Logo/VesySoftLogo";
import functionsMixin from "../../mixins/functionsMixin";
import ModuleIcon from "./ModuleIcon";
import mobileCheckMixin from "../../mixins/mobileCheckMixin";
import TTSSmartMixLogo from "@/views/components/Logo/TTSSmartMixLogo";
import { mapState } from "pinia";
import { useCommonStore } from "@/store/common";
import iconsMixin from "@/mixins/iconsMixin";
import { authService } from "@/services/auth.service";
import OneCBuhLogo from "@/views/components/Logo/OneCBuhLogo";
import FaqIcon from "@/views/components/Icons/FaqIcon";
import KIPConceptLogo from "@/views/components/Icons/KIPConceptLogo.vue";
import Breadcrumb from "./Breadcrumb.vue";
import moment from "moment";

export default {
    components: {
        KIPConceptLogo,
        FaqIcon,
        OneCBuhLogo,
        TTSSmartMixLogo,
        ModuleIcon,
        VesySoftLogo,
        TTSSmartWeightLogo,
        SettingsIcon,
        Breadcrumb,
    },
    props: ["title", "integrations", "relations", "settings"],
    mixins: [functionsMixin, mobileCheckMixin, iconsMixin],
    name: "PageHeader",
    data() {
        return {
            now: moment().format('HH:mm'),
        }
    },
    methods: {
        doLogout() {
            authService().doLogout();
        },
        checkSettingsPage() {
            return (
                this.$route.matched[0].name === "SettingsIndex" ||
                this.$route.matched[0].name === "report"
            );
        },
        changeBG() {
            document.body.classList.toggle('dark-mode');
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            user: "getUser",
            modules: "getModules",
        }),
        accountModuleId() {
            let id = this.settings ?? null;

            if (!id) {
                let moduleKey = this.$route.meta.moduleKey;
                let moduleId = this.$route.params.id;

                if (moduleKey === "dispatch" || moduleKey === "crm") {
                    id = moduleId;
                } else {
                    id = this.modules.find(
                        (module) =>
                            module.key == moduleKey && module.id == moduleId
                    )?.accountModuleId;
                }
            }

            return id;
        },
    },
};
</script>
