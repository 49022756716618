<template>
    <div>
        <div class="add-shipping-form__header">
            <template v-if="mixOrder">
                <div class="d-flex-full align-center">
                    <div class="cutOverflow">
                        {{
                            (mixOrder.status === 'new' && !mixOrder.confirm) ? 'Подтверждение отгрузки' : 'Подробности отгрузки'
                        }} #{{ formatNumber(mixOrder.id) }}
                    </div>
                    <div>
                        <template v-if="mixOrder.status === 'new'">
                            <div class="group-orders-status-text"
                                 :class="confirm ? 'group-orders-status-text-done ' : 'group-orders-status-text-unconfirmed'">
                                {{ confirm ? 'Подтверждена' : 'Неподтверждена' }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="group-orders-status-text group-orders-status-text-confirmed">
                                В работе
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="add-shipping-form__header__text">
                    Новая отгрузка -
                    {{orderGroupItem.company.name ?? orderGroupItem.company}}
                </div>
            </template>

        </div>
        <div class="add-shipping-form__content">
            <div class="add-shipping-form__content__title">
                Добавление отгрузки
            </div>
            <hr class="add-shipping__content__hr"/>
                <el-row :gutter="16" style="width: 100%">
                    <el-col :sm="6">
                        <div class="addDeliveryFormItem addDeliveryFormTotal addDeliveryVolume">
                            <div>
                                <el-tooltip effect="dark" content="Объем" placement="top">
                                    <div class="addDeliveryFormItemIcon">
                                        <PackageGoodIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                    </div>
                                </el-tooltip>
                            </div>
                            <el-input-number
                                v-model="form.total"
                                placeholder="0"
                                :step="1"
                                controls-position="right"
                                @change="getTimeInfo()"
                            />
                        </div>
                    </el-col>

                    <el-col :sm="7" v-if="orderMixers.length > 1">
                        <div class="addDeliveryFormItem pointer" @click="openSelectDialog('mix')">
                            <div>
                                <el-tooltip effect="dark" content="Завод" placement="top">
                                    <div class="addDeliveryFormItemIcon">
                                        <BuildingIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="addDeliveryFormItemInputText">
                                <div v-if="!form.mix_id" class="neutral200">Выберите завод</div>
                                <div class="cutOverflow" v-else>
                                    {{ getLabel('mix') }}
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :sm="4" :style="{ maxWidth: '150px' }">
                        <el-row gutter="10">
                            <el-col>
                                <div class="addDeliveryFormItem addDeliveryFormTotal">
                                    <div>
                                        <el-tooltip effect="dark" content="Время загрузки" placement="top">
                                            <div class="addDeliveryFormItemIcon">
                                                <ArrowDownRightIcon class="neutral700" :currentColor="true" :width='20' :height='20' :strokeWidth='1' />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                    <el-input
                                        v-model="form.start_at"
                                        @change="changedOrder('start_at')"
                                        placeholder="00:00"
                                        type="time"
                                        :disabled="!form.total"
                                    />
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>

                    <el-col :sm="7">
                        <div class="addDeliveryFormItem pointer" @click="openSelectDialog('vehicle')">
                            <div>
                                <el-tooltip effect="dark" content="ТС" placement="top">
                                    <div class="addDeliveryFormItemIcon">
                                        <SolidMixerIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="addDeliveryFormItemInputText">
                                <div v-if="!form.vehicle_id" class="neutral200">Авто</div>
                                <div class="cutOverflow" v-else>
                                    {{ getLabel('vehicle') }}
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :sm="10">
                        <div class="addDeliveryFormItem pointer" @click="openSelectDialog('driver')">
                            <div>
                                <el-tooltip effect="dark" content="Водитель" placement="top">
                                    <div class="addDeliveryFormItemIcon">
                                        <SolidUserProfileIcon class="neutral700" :currentColor="true" :width='20' :height='20'  :strokeWidth="2"/>
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="addDeliveryFormItemInputText">
                                <div v-if="!form.driver_id" class="neutral200">Водитель</div>
                                <div class="cutOverflow" v-else>
                                    {{ getLabel('driver') }}
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            <hr class="add-shipping__content__hr"/>

            <div class="d-flex justify-between w-100">
                <MixPageOrderTimeline :order="order" v-show="false"/>

                <div class="add-shipping__content__order-terms__title__item"
                     v-if="orderGroupItem.delivery_type === 'take-away'">
                    <ArrowDownRightIcon class="add-shipping__content__order-terms__title__item__icon" :currentColor="true"/>
                    <div class="neutral900"><span class="neutral300">Время на загрузку:</span> {{ timeInfo.loading }} мин</div>
                </div>
            </div>

        </div>
        <div class="add-shipping-form__footer">
            <el-button
                @click="close"
                class="ci-btn ci-btn_close"
                type="primary"
            >
                Отмена
            </el-button>
            <el-button
                class="ci-btn ci-btn_blue"
                type="primary"
                @click="createOrder"
            >
                Добавить
            </el-button>
        </div>
    </div>

    <el-dialog
        v-model="selectDialog"
        :title="selectDialogTitle"
        :destroy-on-close="true"
        :width="dialogSize"
    >
        <template v-if="selectType === 'mix'">
            <EditSelectMixes
                :items="orderMixers"
                @updateItem="updateMixes"
                @close="() => selectDialog = false"
                :is-all-mixers-is-in-order="isAllMixersIsInOrder"
                :order-mixers="orderMixers"
            />
        </template>
        <template v-else-if="selectType === 'vehicle'">
            <EditSelectVehicles
                :vehicles="schedules"
                :schedule=true
                @update="updateVehicle"
            />
        </template>
        <template v-else-if="selectType === 'driver'">
            <EditSelectDrivers
                v-model="form.driver_id"
                :items="drivers"
                @close="() => selectDialog = false"
            />
        </template>
    </el-dialog>

</template>
<script>
import {httpService} from "@/services/http.service";
import EditSelectMixes from "@/views/dispatch/components/editSelects/EditSelectMixes.vue";
import EditSelectVehicles from "@/views/dispatch/components/editSelects/EditSelectVehicles.vue";
import EditSelectDrivers from "@/views/dispatch/components/editSelects/EditSelectDrivers.vue";
import MixPageOrderTimeline from "@/views/components/MixPage/OrdersGroup/MixPageOrderTimeline.vue";
import SolidMixerIcon from "@/views/components/Icons/SolidMixerIcon.vue";
import SolidUserProfileIcon from "@/views/components/Icons/SolidUserProfileIcon.vue";
import functionsMixin from "@/mixins/functionsMixin";
import mobileMixin from "@/mixins/mobileCheckMixin";
import moment from "moment/moment";
import {useCommonStore} from '@/store/common';
import {mapState} from 'pinia'

// icons
import PackageGoodIcon from "@/views/components/Icons/PackageGoodIcon.vue";
import BuildingIcon from "@/views/components/Icons/BuildingIcon.vue";
import ArrowDownRightIcon from '@/views/components/Icons/ArrowDownRightIcon.vue';

export default {
    name: "MixOrderGroupAddShippingForm",
    components: {
        EditSelectMixes,
        EditSelectVehicles,
        EditSelectDrivers,
        MixPageOrderTimeline,
        PackageGoodIcon,
        BuildingIcon,
        ArrowDownRightIcon,
        SolidMixerIcon,
        SolidUserProfileIcon,
    },
    props: ['orderGroupItem'],
    inject: ['api'],
    mixins: [functionsMixin, mobileMixin],
    data() {
        return {
            form:{
                total: 1,
                mix_id: null,
                mixer_id: null,
                start_at: null,
                arrive_at: null,
                driver_id: null,
                vehicle_id: null,
                vehicle: null,
            },
            selectDialog: false,
            selectType: null,
            selectDialogTitle: null,
            vehicles: [],
            schedules: [],
            timeInfo: {
                loading: 0,
                toObject: 0,
                unloading: 0,
                return: 0,
                dist: 0
            },
        }
    },
    created() {
        this.getVehicles()

        // проставляем объем и время для отгрузки самовывоза
        if (this.orderGroupItem.deliveryType === 'take-away') {
            if (this.orderGroupItem.total < 12) this.form.total = this.orderGroupItem.total
            const currentMinutes = moment().minutes();
            const roundedMinutes = Math.ceil(currentMinutes / 5) * 5;
            this.form.start_at = moment().minutes(roundedMinutes).format('HH:mm');
        }
    },
    methods: {
        async getTimeInfo() {
            if (this.orderGroupItem.deliveryType === 'take-away') return
            if (!this.form.start_at) {
                this.form.start_at = moment().add(5, 'minutes').format('HH:mm')
                this.changedOrder('start_at')
            }
            if (this.form.mix_id) {
                await httpService().post(this.api.dispatch.orderGroups.getTimeInfo, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    order_group_item_id: this.orderGroupItem.id,
                    mixId: this.form.mix_id,
                    total: this.form.total
                }).then((response) => {
                    let data = response.data
                    if (data.success) {
                        this.timeInfo = data.timeInfo
                    }
                })
            }
        },
        async changedOrder(time_type) {
            await this.getTimeInfo()
            if (this.start_at !== this.arrive_at && this.total) {
                const timeLoading = this.total * 2.5 * 60

                let timeLoad = moment(this.start_at)
                let timeObject = moment(this.arrive_at).subtract(timeLoading, 'seconds').add(this.timeLoading, 'seconds')

                // let minutesDiffLoad = timeObject.diff(timeLoad, "minutes") + 1
                let minutesDiffLoad = timeObject.diff(timeLoad, "minutes")


                if (time_type === "start_at") {

                    this.form.arrive_at = moment(this.form.start_at, 'HH:mm')
                        .add(minutesDiffLoad, "minutes")
                        .format("HH:mm")
                }


                if (time_type === "arrive_at") {
                    this.form.start_at = moment(this.form.arrive_at, 'HH:mm')
                        .subtract(minutesDiffLoad, "minutes")
                        .format("HH:mm");
                }
            } else {
                if (this.form.start_at || this.form.arrive_at) {
                    const timeLoading = this.timeInfo.loading ?? 0
                    const timeDelivery = this.timeInfo.toObject ?? 0

                    const addTime = timeLoading + timeDelivery
                    if (time_type === "start_at") {
                        this.form.arrive_at = moment(this.form.start_at, 'HH:mm')
                            .add(addTime, "minutes")
                            .format("HH:mm")
                    }

                    if (time_type === "arrive_at") {
                        this.form.start_at = moment(this.form.arrive_at, 'HH:mm')
                            .subtract(addTime, "minutes")
                            .format("HH:mm");
                    }
                }
            }
        },
        createOrder() {
            const now = moment(this.orderGroupItem.date_shipment);

            if (!this.form.start_at) {
                this.$message({
                    message: 'Поле Дата должна быть заполнена',
                    showClose: true,
                    type: 'error'
                });
                return;
            }

            httpService().post(this.api.dispatch.orders.create, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    order: {
                        order_group_id: this.orderGroupItem.id,
                        mix_id: this.form.mix_id,
                        mixer_id: this.form.mixer_id,
                        total: this.form.total,
                        vehicle_id: this.form.vehicle_id,
                        driver_id: this.form.driver_id,
                        arrive_at: moment.tz(`${now.format('YYYY-MM-DD')}T${this.form.arrive_at}`, 'YYYY-MM-DDTHH:mm', this.moduleTimezone).utc(),
                        start_at: moment.tz(`${now.format('YYYY-MM-DD')}T${this.form.start_at}`, 'YYYY-MM-DDTHH:mm', this.moduleTimezone).utc(),
                    },
                }
            ).then(() => {
                setTimeout(() => {
                    this.$emit('get')
                    this.$emit('close')
                }, 150)
            }).catch((error) => {
                const {errors} = error.response.data
                if (errors === undefined){
                    this.$message({
                        message: error.response.data.message,
                        type: 'error',
                        showClose: true,
                    })
                }

                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
            });
        },
        updateMixes(mix){
            this.form.mix_id = mix.mix_id
            this.form.mixer_id = mix.id
        },
        updateVehicle(vehicle) {
            this.form.vehicle = vehicle
            this.form.vehicle_id = vehicle?.id
            if (!this.form.driver_id && vehicle?.driver_id) {
                this.form.driver_id = vehicle.driver_id
            }
            this.selectDialog = false
        },
        getVehicles(){
            httpService().post(this.api.vehicle.getVehicleSchedulesForDay, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.orderGroupItem.date_shipment
            }).then(({data}) => {
                if (data.success) {
                    this.schedules = data.schedules
                    this.vehicles = data.vehicles
                }
            })
        },
        openSelectDialog(type) {
            this.selectType = type

            if (type === 'mix') {
                this.selectDialogTitle = 'Выберете бетонный завод'
            } else if (type === 'vehicle') {
                this.selectDialogTitle = 'Выберите транспортное средство'
            } else if (type === 'driver') {
                this.selectDialogTitle = 'Выберите водителя'
            }

            this.selectDialog = true
        },
        async getMixes(accountModulesId) {
            await httpService().post(this.api.dispatch.listByAccount, {
                account_modules_id: accountModulesId,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                const {data} = response.data
                this.mixes = data

                if (this.mixes.length === 1) {
                    this.form.mix_id = this.mixes[0].id
                    this.form.mixer_id = this.mixes[0].mixers[0]?.id
                    this.getTimeInfo()
                }
            })
        },
        close(){
            this.$emit('close')
        },
        getLabel(type) {
            let label = null

            if (type === 'mix') {
                label = this.orderMixers.find(el => el.id === this.form.mixer_id)?.name ?? '-'
            } else if (type === 'vehicle') {
                if (this.form.vehicle) {
                    label = this.getFormattedVehicleNumber(this.form.vehicle?.number)
                } else {
                    if (this.form.vehicle_id) {
                        label = this.vehicles.find(el => el.id === this.form.vehicle_id)?.number
                    }
                }
            } else if (type === 'driver') {
                label = this.drivers.find(el => el.id === this.form.driver_id)?.name
                if (label) label = this.formatName(label)
            }

            return label ?? '-'
        },
        convertTimeToDateTime(time, type) {
            const now = moment(this.orderGroupItem.date_shipment);
            const timeMoment = moment(`${now.format('YYYY-MM-DD')}T${time}`);

            let addMinutes = 0

            if (type === 'load_at') {
                addMinutes = this.timeInfo.loading
            } else if (type === 'unload_at') {
                addMinutes = this.timeInfo.unloading
            } else if (type === 'return_at') {
                addMinutes = Math.abs(this.timeInfo.unloading) + Math.abs(this.timeInfo.return)
            } else if (type === 'arrive_at') {
                addMinutes = Math.abs(this.timeInfo.loading) + Math.abs(this.timeInfo.toObject)
            }

            return timeMoment.add(addMinutes, 'minutes').format();
        },
        setMixName(mixer, allMixers) {
            const amountOfMixersPerMix = allMixers.filter((item) => item.mix_id === mixer.mix_id)?.length;
            return amountOfMixersPerMix > 1 ? mixer.name : mixer.mix_name;
        },
    },
    computed:{
        ...mapState(useCommonStore, {
                drivers: 'getDrivers',
                modules: 'getModules'
            }),
        orderMixers() {
            const orderMixerIds = this.orderGroupItem.info.mixerIds;
            const mixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey)?.mixers;
            const selectedMixers = mixers.filter((mixer) => orderMixerIds.includes(mixer.id));
            return selectedMixers.map((mixer) => { return {
                id: mixer.id, mix_id: mixer.mix_id,
                name: this.setMixName(mixer, mixers)}
            })
        },
        order() {
            return {
                start_at: this.convertTimeToDateTime(this.form.start_at),
                load_at: this.form.start_at ? this.convertTimeToDateTime(this.form.start_at, 'load_at') : null,
                arrive_at: this.convertTimeToDateTime(this.form.arrive_at),
                unload_at: this.form.arrive_at ? this.convertTimeToDateTime(this.form.arrive_at, 'unload_at') : null,
                return_at: this.form.arrive_at ? this.convertTimeToDateTime(this.form.arrive_at, 'return_at') : null,
                status: this.mixOrder?.status ?? 'wait',
                type: this.orderGroupItem.delivery_type
            }
        },
        isAllMixersIsInOrder() {
            const orderMixerIds = this.orderGroupItem.info.mixerIds;
            const mixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey)?.mixers;
            return orderMixerIds.length === mixers.length
        }
    },
    watch: {
        'orderMixers': {
            handler: function(val) {
                if(val) {
                    if (val.length === 1) {
                        this.form.mix_id = val[0].mix_id;
                        this.form.mixer_id = val[0].id
                        this.getTimeInfo()
                    }
                }
            },
            immediate: true
        },
    }
}
</script>
<style scoped lang="scss">
.add-shipping-form{
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;

    &__header{
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        justify-content: space-between;

        &__status{
            display: flex;
            padding: 1px 8px;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            border: 1px solid #ECAF02;
            background: #F9EAC0;

            &__icon{
                width: 22px;
                display: flex;
            }

            &__text{
                color: #22252C;
                font-family: Ubuntu;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

        &__text{
            color: var(--Neutral-700, #393F4A);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
    }

    &__content{
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;

        align-self: stretch;
        border-radius: 12px;
        border: 1px solid #EEEEF0;

        margin-top: 24px;

        &__title{
            color: #393F4A;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }

        hr{
            height: 1px;
            align-self: stretch;
            background: #EEEEF0;
            width: 100%;
        }

        &__input-section{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 12px;
            width: 100%;
        }
    }

    &__footer{
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;

        .ci-btn_close{
            color: #393F4A;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            background-color: white;
            border-color: white;
            line-height: 20px;
        }
    }
}
</style>
