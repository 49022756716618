<template>
    <div class="widget-card">
        <div class="widget-card-title">Смены
            <el-button class="widget-reload" :loading="loadingButton" @click="onUpdate" icon="el-icon-refresh"
                       circle></el-button>
        </div>
        <div class="widget-card-body">
            <div class="widget-header-text" v-if="shift">
                <h2>{{ shift.name }}</h2>
                <p>Начало смены - {{ getDate(data.plan.date_start, 'DD.MM HH:mm') }}</p>
                <p>Конец смены - {{ getDate(data.plan.date_end, 'DD.MM HH:mm') }}</p>
                <p>План на смену - {{ theFormat(data.plan.total) }}</p>
                <p>Выполнено - {{ theFormat(data.plan.done) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { httpService } from '@/services/http.service'

export default {
    props: [
        'id',
    ],
    inject: [
        'api'
    ],
    created() {
        this.loadingButton = true
        this.widgetGet(this.id)
        window.setInterval(() => {
            this.loadingButton = false
        }, 800)
        this.counterInterval = setInterval(
            function () {
                this.updateWidget()
            }.bind(this), Math.floor(Math.random() * (10000 - 8000 + 1)) + 8000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    data() {
        return {
            data: [],
            loadingButton: true,
            shift: null,
        }
    },
    methods: {
        theFormat(number) {
            return number.toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ');
        },
        widgetGet(id) { // метод получения данных виджета с api
            // обращаемся в api, передаем id виджета
            httpService().post(this.api.widget.getOne, {
                id: id
            }).then((response) => {
                    // получаем массив с api
                    let data = response.data
                    if (data.success)
                        // кладем массив в виджет
                        this.data = data.data
                    this.shift = this.data.shift
                }
            )
        },
        onUpdate() {
            this.loadingButton = true
            this.updateWidget()
            window.setInterval(() => {
                this.loadingButton = false
            }, 800)
        },
        updateWidget() // метод обновления виджета
        {
            this.widgetGet(this.id)
        },
        getDate: (timestamp, format) => {
            return moment.unix(timestamp).format(format)
        },
    },
}
</script>

<style scoped>
</style>
