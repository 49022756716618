<template>
    <ComponentsHeadBlockMixer
        :loading="loading"
        :getDate="getDate"
        :filters="filters"
        :showDate="switcher"
        :editView="editView"
        @changeDate="changeDate"
    >
        <template #switchBlock>
            <div class="d-flex switcher textSM textMedium neutral500">
                <div :class="switcher ? 'active' : ''" @click="toggleSwitch(true)">На день</div>
                <div :class="switcher ? '' : 'active'" @click="toggleSwitch(false)">По умолчанию</div>
            </div>
        </template>

        <template #scheduleDefault v-if="!schedules.length && !editView">
            <img :src="require('@/assets/warning-2.svg')" class="order-groups__icon mr-5"/>
            <span class="warning300 textSM textRegular400">График по умолчанию</span>
        </template>
        <template #buttonsBlock>
            <button
                v-if="!editView"
                @click="setEditView()"
                class="ci-btn ci-btn_blue"
            >
                Редактировать
            </button>
        </template>
    </ComponentsHeadBlockMixer>

    <el-table
        v-loading="loading"
        :data="isSchedule() ? isEditSchedules() : defaultSchedules"
        v-if="!mobile"
        class="w-100 mt-15 mb-15">
        <el-table-column
            prop="active"
            label=""
            :width="editView ? 40 : 1"
        >
            <template v-slot="scope">
                <el-checkbox v-show="editView && switcher" v-model="scope.row.active"/>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Завод"
            width="200"
        >
            <template v-slot="scope">
                <span class="textSM textMedium neutral700">{{ scope.row.module_name }}</span>
            </template>
        </el-table-column>
        <el-table-column
            v-if="schedules.length && !editView ? showPostName : showPostNameDefault"
            prop="mixName"
            label="Пост"
            width="140"
        >
            <template v-slot="scope">
                <span class="textSM textRegular400 neutral900" v-if="scope.row.count > 1 ">{{ scope.row.name }}</span>
                <span class="textSM textRegular400 neutral400" v-else>-</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="startTime"
            label="График"
        >
            <template v-slot="scope">
                <template v-if="!editView">
                    <span class="textSM textRegular neutral400" v-if="scope.row.start_time">
                        {{ scope.row.start_time }}
                        -
                        {{ scope.row.end_time }}
                    </span>
                </template>
                <template v-else>
                    <div class="d-flex-full-stroke time-input-select"
                         :class="scope.row.start_time === scope.row.end_time ? 'error-border': ''">
                        <el-input
                            v-model="scope.row.start_time"
                            placeholder="00:00"
                            type="time"
                        />
                        <span class="ml-10 mr-10">-</span>
                        <el-input
                            v-model="scope.row.end_time"
                            placeholder="00:00"
                            type="time"
                        />
                    </div>
                    <div class="error textXS textRegular400" v-if="scope.row.start_time === scope.row.end_time">
                        Время не может быть одинаковым
                    </div>
                </template>
            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            width="120px">
            <template v-slot="scope">
                <el-button
                    size="small"
                    @click="openPostDetailsDialog(scope.row)"
                >
                    Подробнее
                </el-button>
            </template>
        </el-table-column>
    </el-table>


    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />

    <template v-if="editView">
        <div class="text-right">
            <el-button
                @click="setEditView">
                Вернуться назад
            </el-button>
            <el-button v-if="switcher"
                       type="primary"
                       @click="changeSchedules">
                Сохранить график
            </el-button>
            <el-button v-else
                       type="primary"
                       @click="updateDefaultSchedules">
                Сохранить график
            </el-button>

        </div>
    </template>

    <el-dialog
        title="Подробности поста"
        v-model="postDetailsDialog"
        direction="rtl"
        class="group-order-action-dialog"
        width="992"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <div class="info-container">
            <el-row class="info-container__body" :gutter="20">
                <el-col :sm="12">
                    <div class="info-container__body__item">
                        <div class="info-container__body__item__label">
                            Завод
                        </div>
                        <div class="info-container__body__item__content">
                            {{ postDetails.module_name }}
                        </div>
                    </div>
                    <div class="info-container__body__item">
                        <div class="info-container__body__item__label">
                            Имя
                        </div>
                        <div class="info-container__body__item__content">
                            {{ postDetails.name }}
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

    </el-dialog>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import moment from 'moment/moment'
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import ComponentsHeadBlockMixer from "@/views/dispatch/components/ComponentsHeadBlockMixer.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import {httpService} from "@/services/http.service";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";

export default {
    name: 'DispatchPostsTable',
    mixins: [dispatchTabs, tableMixin, functionsMixin, mobileCheckMixin],
    props: [],
    data() {
        return {
            loading: true,
            switcher: true,
            editView: false,
            timeError: false,
            defaultMixes: [],
            defaultSchedules: [],
            mixes: [],
            posts: [],
            schedules: [],
            schedulesEdit: [],
            dailyPosts: [],
            changeDriverDialog: false,
            changeMixesDialog: false,
            postDetails: [],
            showPostName: false,
            showPostNameDefault: false,
            postDetailsDialog: false
        }
    },
    components: {
        TablePagination,
        ComponentsHeadBlockMixer,
    },
    inject: [
        'api'
    ],
    computed: {
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            filters: 'getFilters',
        }),
        date() {
            return this.dateOrder ?? this.getDate
        }
    },
    async created() {
        await this.getDefaultSchedules()
    },
    methods: {
        updateDefaultSchedules() {
            if (this.checkTimeSE(this.defaultSchedules).length) {
                this.errorsMessage('errorSE')
                return;
            }

            if (this.checkTimeNo(this.defaultSchedules).length) {
                this.errorsMessage('errorNo')
                return;
            }

            this.updatePosts(this.defaultSchedules, null);
        },
        changeSchedules() {
            if (this.checkTimeSE(this.schedulesEdit).length) {
                this.errorsMessage('errorSE')
                return;
            }

            if (this.checkTimeNo(this.schedulesEdit).length) {
                this.errorsMessage('errorNo')
                return;
            }

            this.updatePosts(this.schedulesEdit ,this.filters['filter[date]']);
        },
        updatePosts(schedules, date) {
            this.loading = true
            httpService().post(this.api.dispatch.schedules.updatePostSchedules, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                schedules: schedules,
                date: date
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'График сохранен',
                        showClose: true,
                        type: 'success'
                    })
                    this.loading = false
                    this.setEditView()
                    if (date) {
                        this.getSchedules()
                    }
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },

        async getDefaultSchedules() {
            this.loading = true
            const response = await httpService().post(this.api.dispatch.schedules.getPostSchedules, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            })

            const data = response.data;
            if (data.success) {
                this.defaultSchedules = data.schedules
                this.showPostNameDefault = data.hasMultiple
            }
            await this.getSchedules()
        },
        async getSchedules() {
            this.loading = true
            const response = await httpService().post(this.api.dispatch.schedules.getPostSchedules, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]'] ?? moment().format('YYYY-MM-DD')
            })

            const data = response.data;
            if (data.success) {
                this.schedules = data.schedules.filter(item => item.active)
                // Если есть шедулесы на день, используем их, если нет, то берем по умолчанию, удаляя их id шедулесов
                // этот кусок eslinta думает, что schedule_id это отдельная переменная, а не просто удаление
                // если кто поправит, буду рад
                // eslint-disable-next-line
                this.schedulesEdit = data.schedules.length > 0 ? [...data.schedules] : this.defaultSchedules.map(({ schedule_id, ...rest }) => ({ ...rest }));
                this.showPostName = data.hasMultiple
            }
            this.loading = false
        },

        checkTimeSE(schedules){
            return schedules.filter(dS => dS.start_time === dS.end_time)
        },
        checkTimeNo(schedules){
            return schedules.filter(dS => !dS.start_time || !dS.end_time)
        },

        openPostDetailsDialog(data) {
            this.postDetails = data
            this.postDetailsDialog = true
        },
        changeDate(date) {
            this.filters['filter[date]'] = moment(date).format('YYYY-MM-DD')
            this.getSchedules()
        },
        toggleSwitch(value) {
            this.switcher = value;
        },
        isSchedule() {
            let count = this.schedules.length;
            if (this.editView) {
                return this.switcher
            }
            return count
        },
        isEditSchedules() {
            return this.editView ? this.schedulesEdit : this.schedules;
        },

        setEditView() {
            this.switcher = true
            this.editView = !this.editView
        },
        errorsMessage(type) {
            if (type === 'errorSE') {
                this.$message({
                    message: 'Время начала и конца не должны быть одинаковыми',
                    showClose: true,
                    type: 'error'
                })
            }

            if (type === 'errorNo') {
                this.$message({
                    message: 'Время должно быть указанно',
                    showClose: true,
                    type: 'error'
                })
            }
        }
    },
    watch: {
        $route() {
            this.editView = false
            this.mixerDetailsDialog = false
            this.mixers = []
            this.posts = []
        },

    },
}
</script>

<style scoped>

</style>
