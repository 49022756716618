export default {
    data() {
        return {

            oneCModes: [{
                id: 'by_one',
                name: 'По каждой отгрузке, без заказа'
            }, {
                id: 'by_order',
                name: 'С объединением в заказы'
            }],
            oneCMethods: [{
                id: 'companyCreate',
                name: 'Создание компаний'
            }, {
                id: 'carCreate',
                name: 'Создание ТС'
            }, {
                id: 'productCreate',
                name: 'Создание продукции'
            }, {
                id: 'contractCreate',
                name: 'Создание договоров'
            }, {
                id: 'invoiceCreate',
                name: 'Создание счетов'
            }, {
                id: 'driverCreate',
                name: 'Создание водителей'
            }],
            oneCStatuses: [
                {
                    id: 'new',
                    name: 'Создана'
                },
                {
                    id: 'work',
                    name: 'В работе'
                },
                {
                    id: 'done',
                    name: 'Выполнена'
                },
                {
                    id: 'failed',
                    name: 'Ошибка'
                }
            ],
            oneCTasks: [
                {
                    id: 'getCompanies',
                    name: 'Получение компаний'
                },
                {
                    id: 'getProducts',
                    name: 'Получение продукции'
                },
                {
                    id: 'getCars',
                    name: 'Получение ТС'
                },
                {
                    id: 'getDrivers',
                    name: 'Получение водителей'
                },
                {
                    id: 'getContracts',
                    name: 'Получение договоров'
                },
                {
                    id: 'getInvoices',
                    name: 'Получение счетов'
                },
                {
                    id: 'createApplication',
                    name: 'Создание реализации (без заказа)'
                },
                {
                    id: 'createCompany',
                    name: 'Создание контрагента'
                },
                {
                    id: 'createContract',
                    name: 'Создание договора'
                },
                {
                    id: 'createVehicle',
                    name: 'Создание ТС'
                }
            ],
            oneCActionTriggers: [
                {
                    id: 'orderCompletion',
                    name: 'По завершению заказа'
                },
                {
                    id: 'applicationCompletion',
                    name: 'По завершению каждой отгрузки'
                },
                {
                    id: 'anyChanges',
                    name: 'При любых изменениях'
                }
            ],
            oneCAttributes: [
                {
                    id: 'contract',
                    name: 'Договор'
                },
                {
                    id: 'invoice',
                    name: 'Счет'
                },
                {
                    id: 'seller',
                    name: 'Поставщик (организация)'
                },
                {
                    id: 'productPrice',
                    name: 'Стоимость продукции'
                },
                {
                    id: 'paymentMethod',
                    name: 'Метод оплаты'
                }
            ]
        }
    },
}
