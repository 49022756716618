<template>
    <div>
        <SelfGantt
            v-show="!firstLoading"
            :from="orders.dateFrom"
            :to="orders.dateTo"
            :gantt-data="orders.groups"
            :timezone="orders.timezone"
            :type="type"
            @open-order="openOrder"
            :first-loading="firstLoading"
        >
            <template #header-buttons>
                <slot name="header-buttons"></slot>
            </template>
        </SelfGantt>
        <TableDataLoader class="mt-30" v-show="isLoading && firstLoading" text="графика отгрузок"/>
    </div>
</template>
<script>
import SelfGantt from "@/views/components/SelfGantt";
import {httpService} from "@/services/http.service";
import {useOrderGroupsStore} from "@/store/orderGroups";
import {mapState} from "pinia";
import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import moment from "moment";

export default {
    name: 'MixOrderGroupBigTimelineOrdersNew',
    components: {
        SelfGantt, TableDataLoader
    },
    props: ['type', 'loading'],
    inject: ['api'],
    data() {
        return {
            orders: [],
            isLoading: true,
            firstLoading: true
        }
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            filters: 'getFilters',
        }),
        currentDate() {
            return this.filters['filter[date]']
        }
    },
    beforeUnmount() {
        // window.Echo.leave(`laravel_database_ChannelMixOrderCreateNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);
         window.Echo.leave(`laravel_database_ChannelDispatchOrderGroup`);
    },
    async created() {
        window.Echo.channel('laravel_database_ChannelDispatchOrderGroup')
            .listen(`.Algorithm.${this.$route.params.id}`, () => {
                this.getOrders()
            })
            .listen(`.Create.${this.$route.params.id}`, () => {
                this.getOrders()
            })
            .listen(`.Update.${this.$route.params.id}`, () => {
                this.getOrders()
            })
            .listen(`.Delete.${this.$route.params.id}`, () => {
                this.getOrders()
            });
        await this.getOrders()
    },
    methods: {
        async getOrders(date) {
            this.isLoading = true;

            // this.orders = []
            const {data} = await httpService().post(this.api.dispatch.orders.bigGant, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    date: date ? date : this.currentDate,
            })
            this.orders = data.orders

            this.isLoading = false
            if(this.firstLoading) this.firstLoading = false
        },
        openOrder(orderId, orderGroup, open) {
            this.$emit('openOrder', orderId, orderGroup, open)
        }
    },
    watch: {
        'currentDate': {
            handler: function (val, prevVal) {
                const formatted = moment(val).format('YYYY-MM-DD')
                const formattedPrevVal = moment(prevVal).format('YYYY-MM-DD')
               if(prevVal && formatted !== formattedPrevVal) {
                this.getOrders(formatted)
               }
            },
            deep: true,
            immediate: true
        },
        'isLoading': {
            handler(val) {
                if (val !== this.loading) {
                    this.$emit('update:loading', val);
                }
            },
            deep: true,
            immediate: true,
        },
        'loading': {
            handler(val) {
                if (val !== this.isLoading) {
                    this.isLoading = val;
                }
            },
            deep: true,
            immediate: true,
        },
    }
}
</script>
<style scoped>

</style>
