<template>
    <div id="navigation">
        <div class="mobile-factory-selector-wrapper" v-if="mobile">
            <NavigationFactorySelector :selectedFactory="selectedFactory" @selectedFactory="setSelectedFactory"/>
        </div>
        <router-link to="/dashboard" class="link" @click="$emit('closeMenu')">
            <div class="icon">
                <DashboardIcon/>
            </div>
            <span class="title">
                    Рабочий стол
                </span>
        </router-link>
        <template v-if="user.role_id !== 5">

            <template
                v-for="(link, index) in links"
                :key="index"
            >
                <router-link
                    v-if="(selectedFactory === null || (selectedFactory.id === link.factory_id || link.factory_id === null))
                    && (link.type !== 'godmode' || (user.role_id === 1 || user.role_id === 2))"
                    :to="link.route"
                    class="link"
                >
                    <div class="icon">
                        <CashboxIcon v-if="link.type === 'cashbox'"/>
                        <ContractIcon v-if="link.type === 'contract'"/>
                        <WeighingIcon v-if="link.type === 'weighing'"/>
                        <StorehouseIcon v-if="link.type === 'storehouse'"/>
                        <ProductionIcon v-if="link.type === 'production'"/>
                        <OrderIcon v-if="link.type === 'order'"/>
                        <TechIcon v-if="link.type === 'tech'"/>
                        <AdminIcon v-if="link.type === 'godmode'"/>
                        <TTSIcon v-if="link.type === 'tts'"/>
                        <MixIcon v-if="link.type === 'mix'"/>
                        <CustomModuleIcon v-if="link.custom"/>
                        <LogisticsIcon v-if="link.type === 'logistics'"/>
                        <FuelIcon v-if="link.type === 'fuel'"/>
                        <DispatchIcon v-if="link.type === 'dispatch'"/>
                        <CRMIcon v-if="link.type === 'crm'"/>
                        <LabIcon v-if="link.type === 'lab'"/>
                    </div>
                    <el-tooltip :content="link.name"
                                placement="right" :disabled="link.name.length < 20">
                    <span class="title cutOverflow mw-70">
                        {{ link.name }}
                    </span>
                    </el-tooltip>
                </router-link>
            </template>
            <router-link :to="{name: 'AnalyticsIndex'}" class="link" v-if="analyticsAvailable">
                <div class="icon">
                    <AnalyticsIcon/>
                </div>
                <span class="title">
                    Аналитика
                </span>
            </router-link>
            <router-link v-if="(user.role_id === 1 || user.role_id === 2) || reportsAvailable" :to="{name: 'ReportIndex'}" class="link">
                <div class="icon">
                    <ReportIcon/>
                </div>
                <span class="title">
                    Отчеты
                </span>
            </router-link>
            <a v-if="user.role_id === 1 && links.length < 5" href="/settings/users/create" class="link">
                <div class="icon">
                    <AddRoundIcon/>
                </div>
                <span class="title">
                    Добавить сотрудника
                </span>
            </a>
            <a v-if="user.role_id === 1 && links.length < 5" href="/settings/modules/create" class="link">
                <div class="icon">
                    <AddRoundIcon/>
                </div>
                <span class="title">
                    Добавить модуль
                </span>
            </a>
        </template>
        <router-link to="/notifications" class="link" @click="$emit('closeMenu')">
            <div class="icon">
                <NotificationIcon/>
            </div>
            <span class="title">Уведомления</span>
        </router-link>
        <router-link v-if="user.role_id === 5" to="/settings" class="link" @click="$emit('closeMenu')">
            <div class="icon">
                <SettingsIcon/>
            </div>
            <span class="title">
                    Настройки
                </span>
        </router-link>
        <div @click="doLogout" class="link" v-if="mobile">
            <div class="icon">
                <LogoutIcon/>
            </div>
            <span class="title">
                    Выйти
                </span>
        </div>
    </div>
    <div id="navigationFooter" v-if="!mobile">

        <NavigationFactorySelector v-if="user.role_id !== 5"/>

        <div id="navigation-footer-buttons">
            <el-tooltip content="Настройки"
                        placement="top">
                <router-link to="/settings" class="navigation-footer-button escape-opacity">
                    <SettingsIcon/>
                </router-link>
            </el-tooltip>
            <el-tooltip content="Справка"
                        placement="top">
                <router-link :to="{name: 'FaqIndex'}" class="navigation-footer-button">
                    <FaqIcon2/>
                </router-link>
            </el-tooltip>

            <el-tooltip content="Выйти"
                        placement="top">
                <div @click="doLogout" class="navigation-footer-button">
                    <LogoutIcon/>
                </div>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import CashboxIcon from './Icons/CashboxIcon'
import ContractIcon from './Icons/ContractIcon'
import WeighingIcon from './Icons/WeighingIcon'
import StorehouseIcon from './Icons/StorehouseIcon'
import ProductionIcon from './Icons/ProductionIcon'
import OrderIcon from './Icons/OrderIcon'
import TechIcon from './Icons/TechIcon'
import SettingsIcon from './Icons/SettingsIcon'
import LogoutIcon from './Icons/LogoutIcon'
import DashboardIcon from './Icons/DashboardIcon'
import AdminIcon from './Icons/AdminIcon'
import TTSIcon from './Icons/TTSIcon'
import MixIcon from './Icons/MixIcon'
import NotificationIcon from './Icons/NotificationIcon'
import ReportIcon from '@/views/components/Icons/ReportIcon'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import AddRoundIcon from '@/views/components/Icons/AddRoundIcon'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import {authService} from '@/services/auth.service'
import FaqIcon2 from "@/views/components/Icons/FaqIcon2";
import CustomModuleIcon from "@/views/components/Icons/CustomModuleIcon";
import LogisticsIcon from "@/views/components/Icons/LogisticsIcon";
import NavigationFactorySelector from "@/views/components/NavigationFactorySelector.vue";
import AnalyticsIcon from "@/views/components/Icons/AnaIyticsIcon.vue";
import FuelIcon from "@/views/components/Icons/FuelIcon.vue";
import DispatchIcon from "@/views/components/Icons/DispatchIcon.vue";
import LabIcon from "@/views/components/Icons/LabIcon.vue";
import CRMIcon from "@/views/components/Icons/CRMIcon.vue";

export default {
    name: 'Navigation',
    components: {
        CRMIcon, LabIcon,
        DispatchIcon,
        FuelIcon,
        AnalyticsIcon,
        NavigationFactorySelector,
        LogisticsIcon,
        CustomModuleIcon,
        FaqIcon2,
        AddRoundIcon,
        ReportIcon,
        MixIcon,
        NotificationIcon,
        TTSIcon,
        AdminIcon,
        DashboardIcon,
        LogoutIcon,
        SettingsIcon,
        TechIcon, OrderIcon, ProductionIcon, StorehouseIcon, WeighingIcon, ContractIcon, CashboxIcon
    },
    props: [
        'reloadNav',
    ],
    mixins: [
        mobileCheckMixin
    ],
    data() {
        return {
            disable: true,
            selectedFactory: null,
        }
    },
    created() {

    },
    methods: {
        setSelectedFactory(factory) {
            this.selectedFactory = factory;
        },
        doLogout() {
            this.$confirm(
                "Вы действительно хотите выйти?",
                "Подтвердите выход из системы",
                {
                    confirmButtonText: "Да, выйти",
                    cancelButtonText: "Отмена",
                    type: "warning",
                }
            )
                .then(() => {
                    authService().doLogout()
                })
                .catch(() => {
                    this.$message({
                        message: "Отменено",
                        showClose: true,
                        type: "info",
                    });
                    this.loading = false;
                })
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            links: 'getNav',
            user: 'getUser',
            analyticsAvailable: 'getAnalyticsAvailable',
            reportsAvailable: 'getReportsAvailable',
        }),
    },
    watch: {
        'reloadNav': {
            handler: function () {
                authService().getMe()
            },
            deep: true
        }
    },
}
</script>

<style scoped>
#navigation-footer-buttons {
    overflow: hidden;
}
</style>
