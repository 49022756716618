<template>
    <div class="card">
        <div class="card-body">
            <div class="group-orders-summary">
                <div class="group-orders-summary-body">

                    <div class="d-flex-full w-100">

                        <div class="d-flex-full-stroke" :style="windowWidth > 1500 ? 'gap: 60px' : 'gap: 15px'">

                            <div class="textLG textMedium primary600">
                                {{ order.date ? getDateFormDB(order.date, 'date') : '-' }}
                            </div>
                            <div
                                :class="'group-orders-status-text ' + 'group-orders-status-text-' + order.status">
                                {{ order.status ? getInfo(order.status, mixOrderGroupStatuses) : 'Загрузка' }}
                            </div>
                            <div class="d-flex-full-stroke" style="gap: 40px">
                                <div class="order-groups d-flex no-wrap" style="gap: 10px;">
                                    <img :src="require('@/assets/open_box.svg')" class="order-groups__icon"/>
                                    <div>
                                        {{ ordersCount ?? 0 }}
                                        {{ windowWidth > 1500 ? declension(ordersCount ?? 0, 'отгрузка') : '' }}
                                    </div>
                                </div>
                                <div class="order-groups d-flex no-wrap" style="gap: 10px;">
                                    <img :src="require('@/assets/box.svg')" class="order-groups__icon"/>
                                    <div class="no-wrap">
                                        {{ order.total ? formatNumber(order.total, 2) : 0 }}
                                        м³
                                    </div>
                                </div>
                                <div class="order-groups d-flex" style="gap: 10px;">
                                    <img :src="require('@/assets/money.svg')" class="order-groups__icon"/>
                                    <div class="no-wrap">
                                        {{ price ? formatNumber(price, 2) : 0 }}
                                        ₽
                                    </div>
                                </div>
                                <div>
                                    <OrderQuantity :orders="orders" :total="order.total"/>
                                </div>
                            </div>
                        </div>

                        <div v-if="info.manager">
                            <div class="order-groups d-flex" style="gap: 10px;">
                                <img :src="require('@/assets/user.svg')" class="order-groups__icon"/>
                                <div class="neutral300">
                                    {{ info.manager?.name ?? '-' }}
                                </div>
                            </div>
                        </div>
                        <div class="crm-buttons" v-if="order.status">
                            <template v-if="order.status === 'unconfirmed'">
                                <el-button @click="changeStatus('confirmed')" type="success">
                                    Подтвердить{{ windowWidth > 1500 ? ' заказ' : '' }}
                                </el-button>
                            </template>
                            <template v-if="checkFailed()">
                                <el-button @click="changeStatus('failed')" type="danger">
                                    Отменить{{ windowWidth > 1500 ? ' заказ' : '' }}
                                </el-button>
                            </template>
                            <template v-else-if="order.status === 'work'">
                                <el-button @click="completeNotDone()" type="info">
                                    Завершить{{ windowWidth > 1500 ? ' заказ' : '' }}
                                </el-button>
                            </template>
                            <template v-else-if="order.status === 'done' || order.status === 'confirmed'">
                                <el-button @click="changeStatus('completed')" type="success">
                                    Завершить{{ windowWidth > 1500 ? ' заказ' : '' }}
                                </el-button>
                            </template>
                        </div>
                    </div>

                </div>
            </div>

            <div class="crm-details">
                <div class="crm-details-body">

                    <div class="crm-details-block">
                        <div class="crm-details-block-left">
                            <div>
                                <span class="textSM textRegular neutral400">Контрагент</span>
                                <p class="textLG textMedium neutral900 mt-5">
                                    {{ order.company?.name ?? '-' }}</p>

                                <CRMInfoText
                                    head="ИНН"
                                    :text="order.company?.inn ?? '-'"
                                />
                                <CRMInfoText
                                    head="Телефон"
                                    :text="order.company?.phone ?? '-'"
                                />
                                <CRMInfoText
                                    head="E-mail"
                                    :text="order.company?.email ?? '-'"
                                />

                                <el-button
                                    class="mb-15"
                                    size="small"
                                    :disabled="!order.companyId"
                                    @click="$router.push({name: 'CompanyEdit', params: { id: order.companyId }})"
                                >
                                    Перейти к контрагенту
                                </el-button>
                            </div>

                            <div class="crm-details-block-bottom">
                                <CompanyManagers :loading="loading"
                                                 @getOrderFull="getOrder('full')"
                                                 :company-id="order.companyId"
                                                 :managers="info?.companyManager ? [info?.companyManager] : []"/>
                            </div>
                            <div class="crm-details-block-bottom" v-if="order.deliveryType === 'delivery'">
                                <MixOrderGroupDetailsMap
                                    :latitude="order.latitude"
                                    :longitude="order.longitude"
                                />
                            </div>
                        </div>
                        <div class="crm-details-block-right">
                            <el-tabs v-model="activeTab">
                                <el-tab-pane label="Сводка" name="events" v-if="crm">
                                    <OrderEvents :loading="loading" @update="getOrder('full')"
                                                 :events="events ?? []"/>
                                </el-tab-pane>
                                <el-tab-pane label="Отгрузки" name="orders">
                                    <OrderMixOrders :order-group-item="order" @update="getOrder('full')"
                                                    :orders="orders ?? []"/>
                                </el-tab-pane>
                                <el-tab-pane label="Товары" name="goods">
                                    <OrderServices
                                        :goods-type="true"
                                        :services="order.services.filter(el => el.type === 'good')"
                                        :orders="orders ?? []"
                                        @update="getOrder('full')"
                                        :loading="loading"/>
                                </el-tab-pane>
                                <el-tab-pane label="Услуги" name="services">
                                    <OrderServices
                                        :services="order.services.filter(el => el.type !== 'good')"
                                        :orders="orders ?? []"
                                        @update="getOrder('full')"
                                        :loading="loading"/>
                                </el-tab-pane>
                                <el-tab-pane label="О заказе" name="info">
                                    <OrderInfo :order="order" :info="info" :mixes="mixes"/>
                                </el-tab-pane>
                                <el-tab-pane label="Документы" name="docs">
                                    <PrintDocs :order-group-item="order" :orders="orders" :accessDocuments="accessDocuments"/>
                                </el-tab-pane>
                                <el-tab-pane label="История" name="log">
                                    <MixOrderGroupLogs ref="mixOrderGroupLogs" :group-id="groupId" :company-id="order.companyId" :tab="activeTab" :tz="order.timezone" height="240px"/>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <el-dialog
        v-model="completedDialog"
        :width="992"
        :destroy-on-close="true"
    >
        <MixOrderGroupCompletedForm
            :group-id="groupId"
            @closeCompletedForm="closeCompletedForm"
        />
    </el-dialog>
</template>

<script>
import CRMTabs from "@/mixins/tabs/CRMTabs";
import functionsMixin from "@/mixins/functionsMixin";
import mixOrderGroupStatus from "@/mixins/mixOrderGroupStatus";
import mixOrderGroupStatusDeclension from "@/mixins/mixOrderGroupStatusDeclension";
import {httpService} from "@/services/http.service";
import OrderEvents from "@/views/components/MixOrderGroup/components/order/OrderEvents.vue";
import CompanyManagers from "@/views/components/MixOrderGroup/components/order/CompanyManagers.vue";
import CRMInfoText from "@/views/components/MixOrderGroup/components/order/CRMInfoText.vue";
import OrderMixOrders from "@/views/components/MixOrderGroup/components/order/OrderMixOrders.vue";
import OrderInfo from "@/views/components/MixOrderGroup/components/order/OrderInfo.vue";
import OrderServices from "@/views/components/MixOrderGroup/components/order/OrderServices.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import MixOrderGroupCompletedForm from "@/views/components/MixOrderGroup/MixOrderGroupCompletedForm.vue";
import iconsMixin from "@/mixins/iconsMixin";
import PrintDocs from "@/views/dispatch/components/PrintDocs.vue";
import OrderQuantity from "@/views/dispatch/components/OrderQuantity.vue";
import MixOrderGroupDetailsMap from "@/views/components/MixOrderGroup/components/MixOrderGroupDetailsMap.vue";
import MixOrderGroupLogs from "@/views/components/MixOrderGroup/components/mixOrderGroupLogs/MixOrderGroupLogs.vue";

export default {
    name: 'MixOrderGroupDetails',
    inject: ['api'],
    props: ['groupId', 'crm'],
    components: {
        MixOrderGroupDetailsMap,
        OrderQuantity,
        PrintDocs,
        MixOrderGroupCompletedForm,
        OrderServices, OrderInfo, OrderMixOrders, CRMInfoText, CompanyManagers, OrderEvents, MixOrderGroupLogs
    },
    mixins: [CRMTabs, functionsMixin, mixOrderGroupStatus, mixOrderGroupStatusDeclension, mobileCheckMixin, iconsMixin],
    data() {
        return {
            values: [],
            loading: true,
            firstRun: true,
            activeTab: (this.crm ? 'events' : 'orders'),
            order: {
                id: this.groupId,
                companyId: null,
                goodId: null,
                total: 0,
                done: 0,
                status: null,
                services: [],
                latitude: null,
                longitude: null
            },
            events: [],
            orders: [],
            info: {
                pump: false,
                deliveryAddressId: null,
                companyManager: null,
                mixIds: [],
                managerId: null,
                productPrice: 0,
                deliveryPrice: 0,
                deliveryType: null,
                prepayment: 0,
                gidrolotok: false,
                intervalType: null,
                timeFreeUnloading: 0,
                timeIntervalClient: 0,
                maxVolume: null,
                maxAxle: null,
                commentDriver: null
            },
            completedDialog: false,
            mixes: [],
            accessDocuments: []
        }
    },
    async created() {
        // await this.getOrder('simple')
        await this.getOrder('full')
        this.getMixes()

        window.Echo.channel('laravel_database_ChannelMixOrderGroupsLogsCreateNew')
            .listen(`.MixOrderGroupsLogsCreateNew.${this.groupId}`, () => {
                if(this.$refs.mixOrderGroupLogs) {
                    this.$refs.mixOrderGroupLogs.getLogs()
                }
            })
    },
    computed: {
        ordersCount() {
            return this.orders.length
        },
        volume() {
            let sum = 0
            this.orders.filter(el => el.status !== 'new' && el.status !== 'loading').forEach(el => sum = sum + parseFloat(el.total))
            return sum
        },
        price() {
            return (this.info.productPrice * this.order.total) + (this.info.deliveryPrice * (this.orders.length ?? 1))
        }
    },
    methods: {
        getMixes() {
            httpService().post(this.api.dispatch.listByAccount, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                let {data} = response.data
                this.mixes = data
            })
        },
        async getUsers() {
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.users = data.users
            })
        },
        async getOrder(type) {
            await httpService().post(this.api.mixOrderGroup.getOrder, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.groupId,
                type: type
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.order = data.order

                    if (this.firstRun && this.order.dispatch_id) {
                        this.firstRun = false
                        window.Echo.channel('laravel_database_ChannelDispatchOrderGroup')
                            .listen(`.Algorithm.${this.order.dispatch_id}`, () => {
                                this.getOrder()
                            })
                    }

                    if (this.order.deliveryType) {
                        this.order.delivery_type = this.order.deliveryType
                    }
                    if (type === 'full') {
                        this.events = data.order.events
                        this.orders = data.order.orders
                        this.info = data.order.info
                        this.loading = false
                    }

                    this.accessDocuments = data.accessDocuments
                } else {
                    this.$message({
                        message: 'Ошибка получения заказа',
                        showClose: true,
                        type: 'error'
                    })
                    this.$router.push({name: 'dashboard'})
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        checkFailed() {
            let check = false

            check = !(this.order.status === 'failed' || this.order.status === 'completed' || this.order.status === 'done');
            return !this.orders.filter(el => el.status !== 'new').length && check;
        },
        completeNotDone() {
            this.$confirm('Заказ незавершен и находится в работе. При завершении будут удалены отгрузки из очереди и пересчитан объем. Вы подтверждаете действие?', 'Подтвердите смену статуса', {
                confirmButtonText: 'Сменить статус',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                this.changeStatusAction('done')
            })
        },
        changeStatus(status) {
            this.$confirm('Вы действительно хотите сменить статус заказа на "' + this.getInfo(status, this.mixOrderGroupStatuses) + '"?', 'Подтвердите смену статуса', {
                confirmButtonText: 'Сменить статус',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {

                if (status !== 'completed') {
                    this.changeStatusAction(status)
                } else {
                    this.completedDialog = true
                }
            })
        },
        closeCompletedForm() {
            this.getOrder('full')
            this.completedDialog = false
        },
        changeStatusAction(status) {
            this.loading = true

            httpService().post(this.api.mixOrderGroup.changeStatus, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.groupId,
                status: status
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.getOrder('full')
                    this.loading = false
                    this.completedDialog = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelMixOrderGroupsLogsCreateNew`);
        window.Echo.leave(`laravel_database_ChannelDispatchOrderGroup`);
    },
}
</script>

<style scoped>

</style>
