<template>
    <Auth title="Диаграмма" :tabs="tabs" :settings="$route.params.id">
        <template v-slot:additionalButtons>
            <div class="d-flex justify-between flex-1">
                <DispatchOrderHeaderButton
                    :to="{ name: 'DispatchOrderGroupsCreate', params: { redirectedFrom: 'DispatchGantt' }}"
                    />
                <EventButtons
                    :account-events="accountEvents"
                    :dialog="eventDialogOpened"
                    v-model="selectedEventTab"
                    @clickOpenDialogButton="toggleEventDialogOpened"
                    @readAllEvents="readAllEvents"
                />
            </div>
        </template>
        <div class="card">
            <div class="card-body">
                <div class="mixPageGantt">
                    <div class="mixPageGantt__header">
                                <Summary
                                class="summary border-bottom"
                                :orders="displayData"
                                @changeDate="changeDate"
                            />
                    </div>

                    <MixOrderGroupBigTimelineOrdersNew class="mixPageGantt__content"
                        :type="activeTab"
                        :loading='loadingGantt'
                        @open-order="openOrder"
                        @update:loading="loadingGantt = $event"
                    >
                        <template #header-buttons>
                            <ChangeActiveTabButtons
                                v-if="isSwitchButtonRequired"
                                class="changeActiveTabButtons"
                                :tabs="chartTabs"
                                :active-tab="activeTab"
                                :loading="loadingGantt || loadingActiveTab"
                                size="small"
                                @change-active-tab="changeActiveTab($event)"
                            >
                            <template #icon-perMix>
                                <SmallLoader class="smallLoader" v-if="(loadingActiveTab || loadingGantt) && activeTab === 'perMix'" />
                                <MultipleViewIcon v-else :current-color="true"/>
                            </template>
                            <template #icon-allMixes>
                                <SmallLoader class="smallLoader" v-if="(loadingActiveTab || loadingGantt) && activeTab === 'allMixes'" />
                                <SingleViewIcon v-else :current-color="true"/>
                            </template>
                        </ChangeActiveTabButtons>
                        </template>
                    </MixOrderGroupBigTimelineOrdersNew>
                </div>
                <EventModal :account-events="accountEvents" :modelValue="eventDialogOpened"
                            :selectedEventTab="selectedEventTab" @update:modelValue="eventDialogOpened = $event"
                            @update-events="updateEvents" @readAllEvents="readAllEvents"
                            :mix-order-groups="orderGroupsForEvents" :loading="loadingEvents"/>
            </div>
        </div>

        <el-dialog
            class="group-order-action-dialog"
            v-model="orderDetailsDialog"
            title=""
            :width="dialogSize"
            :destroy-on-close="true"
            @close="unlockOrder"
        >
            <MixOrderDetailsGroup
                :mix-order-id="selectedMixOrderId"
                :order-group="selectedMixOrderGroup"
                @close="closeOrderDetailsDialog"
                @get="$emit('get')"
            />
        </el-dialog>

        <EventNotification ref="notification" @update-events="updateEvents" :mix-order-groups="orderGroupsForEvents"/>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue'
import tableMixin from '@/mixins/tableMixin'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import {httpService} from "@/services/http.service";
import {mapState, mapActions} from 'pinia'
import {useOrderGroupsStore} from '@/store/orderGroups'
import {useCommonStore} from '@/store/common'
import moment from 'moment/moment'
import MixOrderGroupBigTimelineOrdersNew from "@/views/components/MixPage/OrdersGroup/MixOrderGroupBigTimelineOrdersNew.vue";
import EventModal from "@/views/components/Events/EventModal.vue";
import EventButtons from '../components/Events/EventButtons.vue';
import EventNotification from '@/views/components/Events/EventNotification.vue'
import ChangeActiveTabButtons from '@/views/components/Buttons/ChangeActiveTabButtons.vue';
import Summary from "@/views/dispatch/components/Summary.vue";
import MixOrderDetailsGroup from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroup.vue";
import DispatchOrderHeaderButton from "@/views/dispatch/components/buttons/DispatchOrderHeaderButton.vue";
import SmallLoader from "@/views/components/Loaders/SmallLoader.vue";

// icons
import MultipleViewIcon from '@/views/components/Icons/MultipleViewIcon.vue';
import SingleViewIcon from '@/views/components/Icons/SingleViewIcon.vue';

export default {
    name: 'DispatchGantt',
    components: {
        MixOrderGroupBigTimelineOrdersNew,
        Auth,
        EventButtons,
        EventModal,
        EventNotification,
        ChangeActiveTabButtons,
        MultipleViewIcon,
        SingleViewIcon,
        Summary,
        DispatchOrderHeaderButton,
        MixOrderDetailsGroup,
        SmallLoader
    },
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, dispatchTabs],
    inject: ['api'],
    data() {
        return {
            loading: true,
            loadingGantt: true,
            loadingEvents: false,
            loadingData: false,
            loadingActiveTab: false,
            activeTab: 'perMix',
            chartTabs: ['perMix', 'allMixes'],
            eventDialogOpened: false,
            accountEvents: [],
            selectedEventTab: 'new',
            orderDetailsDialog: false,
            selectedMixOrderId: null,
            selectedMixOrderGroup: null,
        }
    },
    created() {
        this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, this.filters)
        this.getEvents();
        this.moduleGet(true)

        window.Echo.channel('laravel_database_ChannelAccountEvent')
            .listen(`.Create.${this.$route.params.id}`, (data) => {
                const newEvent = {...data.event, tz: data.tz ? data.tz : 'Europe/Moscow'};
                const isCountBehindByOne = this.accountEvents.length === data.eventsCount - 1;
                const isCountBehind = this.accountEvents.length < data.eventsCount;

                if (isCountBehindByOne) {
                    if (this.windowWidth < 1920 && !this.eventDialogOpened) {
                        this.showNotification(newEvent);
                    }
                    this.accountEvents.push(newEvent);
                } else if (isCountBehind) {
                    if (this.windowWidth < 1920 && !this.eventDialogOpened) {
                        this.showNotification(newEvent);
                    }
                    this.getEvents();
                }
            })
            .listen(`.Update.${this.$route.params.id}`, (data) => {
                let oldEvent = this.accountEvents.find((el) => el.id === data.event.id);

                if (oldEvent && oldEvent.status !== data.event.status) {
                    oldEvent.status = data.event.status
                }
            });


        this.activeTab = this.dispatchModule.settings._gant_default_tab  ?? 'perMix';
    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelAccountEvent`);
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            orderGroupsForEvents: 'getOrderGroupsForEvents',
            filters: 'getFilters',
        }),
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
            modules: 'getModules',
        }),
        isSwitchButtonRequired() {
            let id = this.$route.params.id
            const dispatchModule = this.modules.find((m) => m.accountModuleId == id)

            return dispatchModule?.mixers.length > 1
        },
        dispatchModule() {
            return this.modules?.find((module) => module.key === this.$route.meta.moduleKey)
        }
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['pullOrderGroups']),
        ...mapActions(useCommonStore, ['setModules']),
        toggleEventDialogOpened() {
            this.eventDialogOpened = !this.eventDialogOpened
        },
        changeDate(date) {
            this.filters['filter[date]'] = moment(date).format('YYYY-MM-DD')
            this.values = []
            this.moduleGet(true)
            this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, this.filters)
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.doc.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered
        },
        getEvents() {
            this.loadingEvents = true

            httpService().post(this.api.accountEvents.index, {}).then(({data}) => {
                if (data.success) {
                    this.accountEvents = data.accountEvents.map((el) => {
                        return {...el, tz: data.tz ? data.tz : 'Europe/Moscow'}
                    })
                    this.loadingEvents = false
                }
            }).catch((error) => {
                this.loadingEvents = false
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        showNotification(event) {
            this.$refs.notification.addNotification({
                event: event,
            });
        },
        readAllEvents() {
            httpService().post(this.api.accountEvents.readAll, {}).then(() => {
                this.getEvents()
            })
        },
        updateEvents(mixOrderGroup, mixOrderId) {
            this.openOrder(mixOrderId, mixOrderGroup)
            this.getEvents()
        },
        closeOrderDetailsDialog() {
            this.unlockOrder();
            this.orderDetailsDialog = false
            this.selectedMixOrderId = null
            this.selectedMixOrderGroup = null
        },
        moduleGet(loading) {
            if (this.$route.params.id === null) {
                return
            }

            if (!this.loadingData) {
                this.loadingData = true
                if (loading) this.loading = true;

                httpService().post(this.api.dispatch.orderGroups.getOrderGroups, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    params: this.filters
                }).then(({data}) => {
                    if (data.success) {
                        this.values = data.values.sort((a, b) => {
                            const statusGroups = {
                                confirmed: 1,
                                work: 1,
                                done: 2,
                                completed: 2,
                                pause: 3,
                                failed: 4,
                            };

                            if (statusGroups[a.status] !== statusGroups[b.status]) {
                                return statusGroups[a.status] - statusGroups[b.status];
                            }

                            return a.firstOrderTimeDelivery - b.firstOrderTimeDelivery;
                        })
                        this.loading = false
                    }
                    this.loadingData = false
                }).catch(() => {
                    // this.$message({
                    //     message: error.response.data.message,
                    //     showClose: true,
                    //     type: 'error'
                    // })
                    this.loading = false
                    this.loadingData = false
                }).finally(() => {
                    this.loading = false
                    this.loadingData = false
                })
            }
        },
        openOrder(orderId, orderGroup) {
            this.selectedMixOrderId = orderId;
            this.selectedMixOrderGroup = orderGroup;
            this.orderDetailsDialog = true
        },
        changeActiveTab(tab) {
            this.activeTab = tab;
            this.saveActiveTab(tab);
        },
        saveActiveTab(tab) {
            this.loadingActiveTab = true
            httpService().post(this.api.settings.updateAccountModuleSettings, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                settings: {
                    "dispatch_gant_default_tab": tab,
                }
            }).then(({data}) => {
                if (data.success) {
                    this.dispatchModule.settings._gant_default_tab = tab
                    this.setModules(this.modules)
                    this.loadingActiveTab = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingActiveTab = false
            })
        },
        unlockOrder() {
            if (this.selectedMixOrderId) {
                httpService().post(this.api.mixOrder.unlockOrder + '/' + this.selectedMixOrderId, {
                    account_modules_id: this.$route.params.id,
                    account_module_reserve_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.mixPageGantt {
    grid-area: mixPageGantt;

    &:deep(.dates) {
        background: variables.$neutral30;
        border-radius: 12px;
        border: none;

        .el-button {
            padding: 4px 16px;
            background: transparent;
            border-color: transparent;
            border-radius: 12px;

            &:not(.active):not(:last-child):after {
                content: '';
                height: 100%;
                position: absolute;
                right: 0;
                width: 1px;
                background: variables.$neutral50;
            }

            &:has(+ .active):after {
                display: none;
            }

            &.active {
                background: variables.$white !important;
                border-color: variables.$neutral50 !important;;
            }
        }
    }

    .changeActiveTabButtons {
        margin: 8px auto;
    }

    &__content {
        &:deep(.gantt-chart) {
            border-left: 1px solid variables.$light-border-color;
            border-top: 1px solid variables.$light-border-color;
        }
    }
}
</style>

<style scoped lang="scss">
.card-body {
    position: relative;

    &__filters {
        padding: 12px 0;
    }
}

.smallLoader {
    width: 16px;
    height: 16px;
}
</style>
