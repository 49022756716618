<template>
    <Auth title="Настройки модуля" :reloadNav="reloadNav">
        <div class="card">
            <div class="card-body">

                <el-tabs class="moduleInfoTabs mb-15">
                    <el-tab-pane label="Общие настройки">
                        <ModuleGeneralSettings
                            :module="module"
                            :factories="factories"
                            :module-type="moduleType"
                            @moduleGet="moduleGet"
                            @deleteModule="deleteModule"
                        />
                    </el-tab-pane>
                    <el-tab-pane label="Доступ">
                        <ModuleAccessSettings
                            :module="module"
                            :users="users"
                            @moduleGet="moduleGet"
                        />
                    </el-tab-pane>
                    <el-tab-pane label="Планы" v-if="moduleType === 'Бетонный завод'">
                        <ModulePlanEdit/>
                    </el-tab-pane>

                    <el-tab-pane label="Расходы" v-if="moduleType === 'Бетонный завод'">
                        <ModuleEconomyEdit/>
                    </el-tab-pane>

                    <el-tab-pane label="Особые настройки">
                        <ModuleSettingsRow>

                            <template v-slot:info>
                                <h3>Особые настройки</h3>
                                <p>Задайте особые настройки, присущие только модулю <span
                                    style="text-transform: lowercase">{{ moduleType }}</span></p>
                            </template>

                            <template v-slot:content>
                                <div v-if="module.details">

                                    <div v-if="moduleType === 'CRM'">
                                        <el-form class="mb-30" label-position="top">
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <FormSelect
                                                        v-model="module.details.moduleSettings.payment_type"
                                                        label="Тип оплаты"
                                                        placeholder="Не выбрано"
                                                        :items="payment_types"
                                                        :noClearable="true"
                                                    />
                                                </el-col>
                                            </el-row>
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <FormSelect
                                                        v-model.number="module.details.moduleSettings.default_seller"
                                                        label="Поставщик по умолчанию"
                                                        placeholder="Не выбрано"
                                                        :items="sellersCompany"
                                                        :noClearable="true"
                                                    />
                                                </el-col>
                                                <el-col :sm="8">
                                                    <FormSelect
                                                        v-model.number="module.details.moduleSettings.default_carrier"
                                                        label="Перевозчик по умолчанию"
                                                        placeholder="Не выбрано"
                                                        :items="carriersCompany"
                                                        :noClearable="true"
                                                    />
                                                </el-col>
                                            </el-row>
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <el-checkbox true-value="1" false-value="0" v-model="module.details.moduleSettings.contract_required" label="Обязателен договор у заказа" size="large" />
                                                </el-col>
                                                <el-col :sm="8">
                                                    <el-checkbox true-value="1" false-value="0" v-model="module.details.moduleSettings.lab_required" label="Обязателен этап лаборатории в заказе" size="large" />
                                                </el-col>
                                            </el-row>
                                        </el-form>
                                    </div>

                                    <div v-if="moduleType === 'Диспетчеризация'">
                                        <el-form class="mb-30" label-position="top">
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <el-form-item label="Час начала работы">
                                                        <el-input
                                                            v-model="module.details.defaultSchedule.start_at"
                                                            placeholder="00:00"
                                                            type="time"
                                                        />
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :sm="8">
                                                    <el-form-item label="Час окончания работы">
                                                        <el-input
                                                            v-model="module.details.defaultSchedule.return_at"
                                                            placeholder="00:00"
                                                            type="time"
                                                        />
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <FormSelect
                                                        v-model="module.details.moduleSettings.payment_type"
                                                        label="Тип оплаты"
                                                        placeholder="Не выбрано"
                                                        :items="payment_types"
                                                        :noClearable="true"
                                                    />
                                                </el-col>
                                            </el-row>
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <FormSelect
                                                        v-model.number="module.details.moduleSettings.default_seller"
                                                        label="Поставщик по умолчанию"
                                                        placeholder="Не выбрано"
                                                        :items="sellersCompany"
                                                        :noClearable="true"
                                                    />
                                                </el-col>
                                                <el-col :sm="8">
                                                    <FormSelect
                                                        v-model.number="module.details.moduleSettings.default_carrier"
                                                        label="Перевозчик по умолчанию"
                                                        placeholder="Не выбрано"
                                                        :items="carriersCompany"
                                                        :noClearable="true"
                                                    />
                                                </el-col>
                                            </el-row>
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <el-checkbox true-value="1" false-value="0" v-model="module.details.moduleSettings.contract_required" label="Обязателен договор у заказа" size="large" />
                                                </el-col>
                                                <el-col :sm="8">
                                                    <el-checkbox true-value="1" false-value="0" v-model="module.details.moduleSettings.lab_required" label="Обязателен этап лаборатории в заказе" size="large" />
                                                </el-col>
                                            </el-row>
                                        </el-form>
                                    </div>

                                    <div v-if="moduleType === 'Весовой терминал'" style="max-width: 500px">
                                        <el-form-item label="Режим работы терминала:">

                                            <FormSelect
                                                v-model="module.details.mode"
                                                :items="moduleModes"
                                            />

                                        </el-form-item>

                                        <p class="mt-0 color-grey" v-if="module.details.mode === 'normal'">
                                            Полноценный режим работы с
                                            отображением подробностей взвешиваний. Подходит для детального учета на
                                            вашем весовом терминале.</p>

                                        <p class="mt-0 color-grey" v-if="module.details.mode === 'simple'">
                                            Упрощенный режим отображает
                                            общее количество груза (нетто) по операциям "реализация" и "отгрузка
                                            (вывоз)". Подходит для терминалов без оператора, где нет возможности
                                            вести подробный учет.</p>
                                    </div>

                                    <div v-if="moduleType === 'Денежный учет'">
                                        <el-form-item label="Остаток счета по умолчанию:">
                                            <el-input v-model="module.details.cashbox.total" type="number"
                                                      placeholder="Укажите баланс"></el-input>
                                        </el-form-item>
                                    </div>

                                    <div v-if="moduleType === 'Складской учет'">
                                        <p>Внутренние склады модуля:</p>
                                        <el-row v-for="(unit, index) in module.details.units"
                                                :key="index" :gutter="20">
                                            <el-col :md="16" :sm="16">
                                                <el-form-item :label-width="0">
                                                    <el-input v-model="unit.name"
                                                              placeholder="Название"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :md="4" :sm="4">
                                                <el-form-item :label-width="0">
                                                    <el-select v-model="unit.defect">
                                                        <el-option
                                                            :key="false"
                                                            label="Обычный"
                                                            :value="false"
                                                        />
                                                        <el-option
                                                            :key="true"
                                                            label="Брак"
                                                            :value="true"
                                                        />
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :md="1" :sm="2">
                                                <el-form-item :label-width="0">
                                                    <el-button type="danger" :icon="icons.delete"
                                                               @click.prevent="deleteUnitsRow(index, unit, 'Вы действительно хотите удалить внутренний склад и все его значения?')"></el-button>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>

                                        <el-form-item label="" class="mb-0">
                                            <el-button :loading="loading" :icon="icons.plus" type="info"
                                                       @click.prevent="addNewUnitsRow" plain>Добавить
                                                элемент
                                            </el-button>
                                        </el-form-item>
                                    </div>

                                    <div v-if="moduleType === 'Бетонный завод'">
                                        <el-form class="mb-30" label-position="top">
                                            <p class="textSM textBold neutral900">График работы</p>

                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <el-form-item label="Час начала работы">
                                                        <el-input-number
                                                            v-model="module.details.workTimeStart"
                                                            placeholder="Выберите время"
                                                            min="0"
                                                            max="23"
                                                        />
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :sm="8">
                                                    <el-form-item label="Час окончания работы">
                                                        <el-input-number
                                                            v-model="module.details.workTimeEnd"
                                                            placeholder="Выберите время"
                                                            min="0"
                                                            max="23"
                                                        />
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>


                                            <p class="textSM textBold neutral900">Режим работы бетонного завода</p>
                                            <FormSelect
                                                v-model="module.details.mode"
                                                :items="mixModuleModes"
                                            />

                                            <p class="mt-0 color-grey" v-if="module.details.mode === 'production'">
                                                Базовый режим работы модуля. Выводит информацию с АСУ Бетонного завода
                                                (необходимо подключение интеграции с ПО Бетонного завода). Отображает
                                                данные
                                                о отгрузках и рецептах.</p>

                                            <p class="mt-0 color-grey" v-if="module.details.mode === 'dispatch'">
                                                Режим подходит для реализации товарного бетона. В данном режиме
                                                объединяются
                                                данные с АСУ Бетонного завода и GPS-трекеров, установленных на
                                                миксерах.</p>

                                        </el-form>
                                        <hr class="mt-30 mb-30"/>

                                        <p class="textSM textBold neutral900">Настройки интерфейса и доступов:</p>

                                        <el-checkbox v-model="module.details.operatorCreateOrder" label="Оператор может создавать отгрузки" size="large" />
                                        <el-checkbox v-model="module.details.operatorCheckTimeStart" label="Оператор не может начать загрузку, если до начала по расписанию еще более 120 минут" size="large" />
                                        <el-checkbox v-model="module.details.operatorPrint" label="Оператор может печатать документы" size="large" />
                                        <el-checkbox v-model="module.details.driverLoadingOrder" label="Водитель может отмечать статусы загрузки" size="large" />

                                        <hr class="mt-30 mb-30"/>
                                        <p class="textSM textBold neutral900">Данные для диспетчеризации</p>

                                        <el-form label-position="top">

                                            <el-row :gutter="20">

                                                <el-col :sm="8">
                                                    <FormNumber
                                                        label="Бесплатное время разгрузки миксера (по умолчанию)"
                                                        placeholder="Время в минутах"
                                                        v-model="module.details.timeMixerUnloading"
                                                    />
                                                </el-col>

                                                <el-col :sm="8">
                                                    <FormNumber
                                                        label="Стоимость часа простоя"
                                                        placeholder="В рублях"
                                                        v-model="module.details.downtimeCost"
                                                    />
                                                </el-col>

                                                <el-col :sm="8">
                                                    <FormNumber
                                                        label="Стоимость 1 км доставки"
                                                        placeholder="150"
                                                        v-model="module.details.costDelivery"
                                                    />
                                                </el-col>

                                                <el-col :sm="8">
                                                    <FormNumber
                                                        label="Минимальная стоимость доставки 1 миксера"
                                                        placeholder="150"
                                                        v-model="module.details.mixPriceOneMixer"
                                                    />
                                                </el-col>

                                                <el-col :sm="8">
                                                    <FormInput
                                                        label="Директор (для документов)"
                                                        placeholder="Иванов И.И."
                                                        v-model="module.details.director"
                                                    />
                                                </el-col>

                                                <el-col :sm="8">
                                                    <FormInput
                                                        label="Оператор (для документов)"
                                                        placeholder="Иванов И.И."
                                                        v-model="module.details.operator"
                                                    />
                                                </el-col>

                                            </el-row>

                                        </el-form>

                                        <hr class="mt-30 mb-30"/>

                                        <p class="textSM textBold neutral900">Множители сложностей дороги</p>
                                        <p class="textSM textRegular neutral300">По умолчанию 1. Задайте 1.02, если
                                            хотите добавлять 2% к стоимости доставки</p>

                                        <el-form label-position="top">

                                            <el-row :gutter="20">

                                                <el-col :sm="8">
                                                    <FormNumber
                                                        label="Непросто"
                                                        placeholder="1.02"
                                                        :precision="2"
                                                        v-model="module.details.mediumRoad"
                                                    />
                                                </el-col>

                                                <el-col :sm="8">
                                                    <FormNumber
                                                        label="Вызов"
                                                        placeholder="1.04"
                                                        :precision="2"
                                                        v-model="module.details.hardRoad"
                                                    />
                                                </el-col>

                                                <el-col :sm="8">
                                                    <FormNumber
                                                        label="Экстрим"
                                                        placeholder="1.06"
                                                        :precision="2"
                                                        v-model="module.details.extremeRoad"
                                                    />
                                                </el-col>
                                            </el-row>
                                        </el-form>

                                        <hr class="mt-30 mb-30"/>

                                        <p class="textSM textBold neutral900">Категории рецептов:</p>

                                        <el-row v-for="(unit, index) in module.details.units"
                                                :key="index" :gutter="20">
                                            <el-col :md="21" :sm="20">
                                                <el-form-item :label-width="0">
                                                    <el-input v-model="unit.name"
                                                              placeholder="Название"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :md="1" :sm="2">
                                                <el-form-item :label-width="0">
                                                    <el-button type="danger" :icon="icons.delete"
                                                               @click.prevent="deleteUnitsRow(index, unit, 'Вы действительно хотите удалить данную категорию рецептов?')"></el-button>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>

                                        <el-form-item label="" class="mb-0">
                                            <el-button :loading="loading" :icon="icons.plus" type="info"
                                                       @click.prevent="addNewUnitsRow" plain>Добавить
                                                элемент
                                            </el-button>
                                        </el-form-item>

                                        <hr class="mt-30 mb-30"/>

                                        <el-form label-position="top">

                                            <p class="textSM textBold neutral900">Перерасчет данных</p>
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <el-form-item label="Дата, от">
                                                        <el-date-picker v-model="module.recalcOrders.start_date"
                                                                        placeholder="01.01.2023"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :sm="8">
                                                    <el-form-item label="Дата, до">
                                                        <el-date-picker v-model="module.recalcOrders.end_date"
                                                                        placeholder="01.01.2023"/>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row class="mt-30"
                                                    v-if="module.recalcOrders.start_date && module.recalcOrders.end_date">
                                                <el-col :sm="8">
                                                    <el-button type="primary" @click="doRecalc">Обновить данные
                                                    </el-button>
                                                </el-col>
                                            </el-row>

                                        </el-form>
                                    </div>

                                    <div v-if="moduleType === 'Лаборатория'">
                                        <el-form class="mb-30" label-position="top">
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <el-checkbox v-model="module.details.moduleSettings.lab_show_all_orders" label="Выводить все заказы в лаборатории" size="large" />
                                                </el-col>
                                            </el-row>
                                        </el-form>
                                    </div>

                                    <el-form-item>
                                        <el-button @click="doEdit" :loading="loading" class="mt-30"
                                                   type="primary">Сохранить изменения
                                        </el-button>
                                    </el-form-item>

                                </div>
                                <div class="no-data-block" v-if="!module.details">
                                    <NoDataIcon/>
                                    <p>
                                        Для данного модуля нет<br>особых настроек
                                    </p>
                                </div>
                            </template>
                        </ModuleSettingsRow>
                    </el-tab-pane>

                    <el-tab-pane label="Настройки алгоритма" v-if="moduleType === 'Диспетчеризация'">
                        <ModuleSettingsRow>

                            <template v-slot:info>
                                <h3>Настройки алгоритма</h3>
                                <p>В данном разделе можно настроить различные параметры алгоритма</p>
                            </template>

                            <template v-slot:content>
                                <div v-if="module.details">

                                    <div>
                                        <el-form class="mb-30" label-position="top">
                                            <el-row :gutter="20">
                                                <el-col :sm="8">
                                                    <el-form-item label="Время на поиск подходящей машины от 0 до 60 минут">
                                                        <el-input
                                                            v-model="module.details.algorithmSettings.algorithm_time_suitable_vehicle"
                                                            type="number"
                                                            placeholder="10"
                                                            :min="0"
                                                            :max="60"
                                                            step="1"
                                                            @input="handleInput"
                                                        />
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </el-form>
                                    </div>

                                    <el-form-item>
                                        <el-button @click="doEdit" :loading="loading" class="mt-30"
                                                   type="primary">Сохранить изменения
                                        </el-button>
                                    </el-form-item>

                                </div>
                            </template>
                        </ModuleSettingsRow>
                    </el-tab-pane>

                    <el-tab-pane label="Интеграции">
                        <div class="InReUnit" v-for="integration in module.integrations"
                             :key="integration.id">
                            <h4>{{ integration.integration.name }}</h4>
                            <p>{{ integration.integration.description }}</p>
                            <p>
                                <el-button @click="$router.push({name: 'IntegrationsIndex'})" type="info"
                                           size="mini" plain>Подробнее
                                </el-button>
                            </p>
                        </div>
                        <div v-if="module.integrations.length === 0">
                            <div class="no-data-block">
                                <NoDataIcon/>
                                <p>
                                    Для данного модуля нет<br>активных интеграций
                                </p>
                                <router-link :to="{name: 'IntegrationCreate'}">
                                    <el-button type="info" plain>Добавить интеграцию</el-button>
                                </router-link>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Смесители" v-if="moduleType === 'Бетонный завод'">
                        <ModuleSettingsRow>
                            <template v-slot:info>
                                <h3>Смесители</h3>
                                <p>Задайте настройки смесителей, присущие только модулю <span
                                    style="text-transform: lowercase">{{ moduleType }}</span></p>
                            </template>
                            <template v-slot:content>
                                <el-form class="mb-30" label-position="top">
                                    <p class="textSM textBold neutral900">График работы</p>
                                    <template v-for="(mixers, index) in module.mixers" :key="index">
                                        <el-row
                                            :gutter="20"
                                        >
                                            <el-col :sm="8">
                                                <el-form-item label="Час начала работы">
                                                    <el-input
                                                        v-model="mixers.settings.workTimeStart"
                                                        placeholder="00:00"
                                                        type="time"
                                                    />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="8">
                                                <el-form-item label="Час окончания работы">
                                                    <el-input
                                                        v-model="mixers.settings.workTimeEnd"
                                                        placeholder="00:00"
                                                        type="time"
                                                    />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="20">
                                                <FormInput
                                                    label="Название"
                                                    placeholder="Укажите название"
                                                    v-model="mixers.name"
                                                />
                                            </el-col>
                                        </el-row>
                                    </template>
                                    <el-form-item>
                                        <el-button @click="doEdit" :loading="loading" class="mt-30"
                                                   type="primary">Сохранить изменения
                                        </el-button>
                                        <el-button @click="addMixer" :loading="loading" class="mt-30"
                                                   type="primary">Добавить смеситель
                                        </el-button>
                                    </el-form-item>
                                </el-form>
                            </template>
                        </ModuleSettingsRow>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import NoDataIcon from '../../components/NoDataIcon'
import FormSelect from '@/views/components/Form/FormSelect'
import {httpService} from '@/services/http.service'
import iconsMixin from '@/mixins/iconsMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import ModuleEconomyEdit from '@/views/components/SettingsPage/Modules/ModuleEconomyEdit'
import ModulePlanEdit from "@/views/components/SettingsPage/Modules/ModulePlanEdit.vue";
import ModuleGeneralSettings from "@/views/components/SettingsPage/Modules/ModuleGeneralSettings.vue";
import ModuleAccessSettings from "@/views/components/SettingsPage/Modules/ModuleAccessSettings.vue";
import ModuleSettingsRow from "@/views/components/SettingsPage/Modules/ModuleSettingsRow.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import FormInput from "@/views/components/Form/FormInput.vue";

export default {
    name: 'ModulesEdit',
    data() {
        return {
            loading: true,
            module: {
                name: null,
                users: [],
                integrations: [],
                relationsSender: [],
                relationsRecipient: [],
                mixers:[],
                module_id: null,
                details: null,
                recalcOrders: {
                    start_date: '',
                    end_date: ''
                }
            },
            users: [],
            factories: [],
            sellersCompany: [],
            carriersCompany: [],
            reloadNav: false,
            moduleType: null,
            moduleModes: [{
                id: 'normal',
                name: 'Нормальный'
            }, {
                id: 'simple',
                name: 'Упрощенный'
            }],
            payment_types: [{
                id: 'invoice',
                name: 'Оплата по счетам'
            }, {
                id: 'contract',
                name: 'Оплата по договорам'
            }],
            mixModuleModes: [{
                id: 'production',
                name: 'Производственный'
            }, {
                id: 'dispatch',
                name: 'Диспетчеризация'
            }]
        }
    },
    components: {
        FormInput,
        FormNumber,
        ModuleSettingsRow,
        ModuleAccessSettings,
        ModuleGeneralSettings,
        ModulePlanEdit,
        ModuleEconomyEdit,
        FormSelect,
        NoDataIcon,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        iconsMixin, mobileCheckMixin, iconsMixin
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        addMixer(){
            this.module.mixers.push(
                {
                    "id": null,
                    "mix_id": this.module.details.mix_id,
                    "number": 1,
                    "name": "",
                    "settings":
                        {
                            "timeMixerUnloading":40,
                            "costDelivery":500,
                            "mixPriceOneMixer":4000,
                            "director":null,
                            "operator":null,
                            "mediumRoad":null,
                            "hardRoad":null,
                            "extremeRoad":null,
                            "downtimeCost":4000,
                            "workTimeStart":7,
                            "workTimeEnd":20
                        },
                }
            )
        },
        doRecalc() {
            if (!this.module.recalcOrders.start_date || !this.module.recalcOrders.end_date) return;

            httpService().post(this.api.mix.recalcOrders, {
                account_module_reserve_id: this.module.moduleReserveId,
                module_key: 'mix',
                mix_id: this.module.details.mix_id,
                start_date: this.module.recalcOrders.start_date,
                end_date: this.module.recalcOrders.end_date
            }).then((res) => {
                const data = res.data;

                if (data.success) {
                    this.$message({
                        message: 'Перерасчет выполнен',
                        showClose: true,
                        type: 'success'
                    })

                    this.moduleGet()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            })
        },
        doEdit() {
            this.loading = true

            httpService().post(this.api.module.editOne, {
                moduleId: this.$route.params.id,
                module: this.module
            }).then((response) => {
                let data = response.data

                if (data.success)

                    this.$message({
                        message: 'Данные сохранены',
                        showClose: true,
                        type: 'success'
                    })
                this.moduleGet()

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        moduleGet() {
            this.loading = true

            httpService().post(this.api.module.getOne, {
                moduleId: this.$route.params.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.module.name = data.name
                    this.module.moduleReserveId = data.moduleReserveId
                    this.module.factory = data.factory
                    this.module.users = data.users
                    this.module.integrations = data.moduleIntegrations
                    this.module.relationsSender = data.moduleSenderRelations
                    this.module.relationsRecipient = data.moduleRecipientRelations
                    this.module.details = data.details
                    this.moduleType = data.type
                    this.reloadNav = !this.reloadNav
                    this.module.module_id = data.module_id
                    this.module.mixers = data.mixers
                }
            })


            httpService().post(this.api.settings.getFactories).then((response) => {
                let data = response.data

                if (data.success)
                    this.factories = data.factories
            })

            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.users = data.users

                this.loading = false
            })

            httpService().post(this.api.company.get, {
                simpleWithType: true,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    // Преобразование строку в число
                    const companiesData = data.companies.map(item => ({
                        ...item,
                        id: Number(item.id)
                    }));

                    this.sellersCompany = companiesData.filter(company => company.type === 'seller')
                    this.carriersCompany = companiesData.filter(company => company.type === 'carrier' || company.carrier === true)
                }
            })
        },
        deleteUnitsRow(index, unit, message) {
            this.$confirm(
                message,
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    var idx = this.module.details.units.indexOf(unit)
                    if (idx > -1) {
                        this.module.details.units.splice(idx, 1)
                    }
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        addNewUnitsRow() {
            this.module.details.units.push({
                id: null,
                name: null,
            })
        },
        deleteModule() {
            this.reloadNav = !this.reloadNav
            window.setTimeout(() => {
                this.$router.push({name: 'ModulesIndex'})
            }, 450)

        },
        handleInput(value) {
            const min = 0;
            const max = 60;
            const intValue = Math.floor(value); // Убираем дробные
            if (intValue < min) {
                this.module.details.algorithmSettings.algorithm_time_suitable_vehicle = min;
            } else if (intValue > max) {
                this.module.details.algorithmSettings.algorithm_time_suitable_vehicle = max;
            } else {
                this.module.details.algorithmSettings.algorithm_time_suitable_vehicle = intValue;
            }
        },
    }
}
</script>


<style scoped>

</style>
