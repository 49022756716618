<template>
    <div>
        <div v-if="orders.groups && orders.groups.length === 0">
            <BaseErrorComponent :title="'Нет доступных отгрузок на этот день'">
                <span></span>
            </BaseErrorComponent>
        </div>
        <div v-if="orders.groups" class="distribute">
            <div
                v-if="errorComponentsMap.has(error.code)"
                class="distribute__error-section"
            >
                <component
                    :is="errorComponentsMap.get(error.code)"
                />
            </div>

            <div
                v-if="metrics.undelivered_volume > 0"
                class="distribute__error-section"
            >
<!--                <NotEnoughVehicles-->
<!--                    :items="unallocated"-->
<!--                />-->
                <BaseErrorComponent :title="'Не распределена часть объема ' + metrics.undelivered_volume + ' м³'">
                    <div class="d-flex f-direction-column">
                        <template v-for="(volume, index) in metrics.orders_undelivered_volume" :key="index">
                            <div v-if="volume > 0 && index != 0">Заказ #{{index}} не распределено {{ volume }} м³</div>
                            <div v-if="volume > 0 && index == 0">Текущий заказ не распределено {{ volume }} м³</div>
                        </template>
                    </div>
                </BaseErrorComponent>
            </div>

            <SelfGantt
                :from="orders.dateFrom"
                :to="orders.dateTo"
                :gantt-data="orders.groups"
                :timezone="orders.timezone"
                type="perMix"
            />

            <div class="distribute__footer mt-30">
                <div
                    class="ci-btn ci-btn_default mr-10 distribute__footer"
                    style="display:inline-flex;"
                    type="button"
                    @click="$emit('update')"
                >
                    Изменить заказ
                </div>
                <button
                    class="ci-btn ci-btn_blue"
                    @click="$emit('create')"
                >
                    Создать заказ
                </button>
            </div>
        </div>
        <div v-else>
            <!--  TODO определить тотал  -->
            <DistributeLoader :total="100"/>
        </div>
    </div>
</template>
<script>
import NotEnoughVehicles from "@/views/dispatch/components/distributeErrors/NotEnoughVehicles.vue";
import NoVehiclesAvailable from "@/views/dispatch/components/distributeErrors/NoVehiclesAvailable.vue";
import NoMixesAvailable from "@/views/dispatch/components/distributeErrors/NoMixesAvailable.vue";
import EntireVolumeIsDistributed from "@/views/dispatch/components/distributeErrors/EntireVolumeIsDistributed.vue"
import DistributeLoader from "@/views/components/MixOrderGroup/DistributeLoader.vue";
import BaseErrorComponent from "@/views/dispatch/components/distributeErrors/BaseErrorComponent.vue"
import SelfGantt from "@/views/components/SelfGantt";

export default {
    name: "DistributeOrderGroup",
    props: ['orders', 'metrics'],
    components: {
        BaseErrorComponent,
        NotEnoughVehicles,
        DistributeLoader,
        SelfGantt
    },
    data() {
        return {
            error: {
                code: 0,
                message: '',
            },
            errorComponentsMap: new Map([
                [1, NoVehiclesAvailable],
                [2, NoMixesAvailable],
                [4, EntireVolumeIsDistributed],
            ]),
            unallocated: [],
        }
    },
    created() {

    },
    methods: {

    },
    computed: {

    },
}
</script>
<style scoped lang="scss">
.distribute {
    &__footer {
        display: flex;
        height: 47px;
    }

    &__error-section {
        gap: 16px;
        display: flex;
        padding: 12px 24px 24px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 12px;
        background: rgba(247, 69, 57, 0.05);

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
}
</style>
