<template>
    <div>
        <div class="add-shipping">
            <div class="add-shipping__header__title neutral900 w-100">
                <div class="d-flex-full align-center">
                    <div class="cutOverflow">
                        Подробности отгрузки
                        #{{ formatNumber(details.id) }} -
                        {{
                            orderGroup?.company?.name ?? orderGroup?.company
                        }}
                    </div>
                </div>
            </div>

            <div class="add-shipping__content">
                <h3 class="textMD textMedium neutral700 mt-0">Редактирование</h3>
                <hr class="add-shipping__content__hr"/>
                <div>
                    <el-row :gutter="16">
                        <!-- Объём -->
                        <el-col :sm="windowWidth  < 1900 ? 5 : 4">
                            <div class="addDeliveryFormItem addDeliveryFormTotal">
                                <div>
                                    <el-tooltip effect="dark" content="Объём" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <PackageGoodIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <el-input-number
                                    v-model="details.total"
                                    placeholder="0"
                                    :step="0.1"
                                    controls-position="right"
                                />
                            </div>
                        </el-col>

                        <!-- Завод -->
                        <el-col :sm="(windowWidth < 1900 || !isLab) ? 8 : 6" v-if="orderMixersAmount">
                            <div class="addDeliveryFormItem pointer" @click="openSelectDialog('mix')">
                                <div>
                                    <el-tooltip effect="dark" content="Завод" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <BuildingIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="addDeliveryFormItemInputText">
                                    <div v-if="!details.mixer_id" class="neutral200">Выберите завод</div>
                                    <div class="cutOverflow" v-else>
                                        {{mixerName(details.factory_name, details.mix.mix_name, details.mixer.mixer_name)}}
                                    </div>
                                </div>
                            </div>
                        </el-col>

                        <!-- Время загрузки -->
                        <el-col :sm="windowWidth < 1900 ? 5 : 4" :style="{ minWidth: '150px', maxWidth: mobile ? '100%' : '250px' }">
                            <el-row gutter="10">
                                <el-col :span="24">
                                    <div class="addDeliveryFormItem addDeliveryFormTotal">
                                        <div>
                                            <el-tooltip effect="dark" content="Время загрузки" placement="top">
                                                <div class="addDeliveryFormItemIcon">
                                                    <ArrowDownRightIcon class="neutral700" :currentColor="true" :width='20' :height='20' :strokeWidth='1' />
                                                </div>
                                            </el-tooltip>
                                        </div>
                                        <el-input
                                            v-model="details.start_at"
                                            placeholder="00:00"
                                            type="time"
                                            :disabled="!details.total"
                                        />
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>

                        <!-- Рецепт -->
                        <el-col v-if="!isLab" :sm="(!orderMixersAmount && windowWidth < 1900) || !isLab && (!isLab && orderMixersAmount) ? 8 : 6">
                            <div class="addDeliveryFormItem pointer" @click="openSelectDialog('good')">
                                <div>
                                    <el-tooltip effect="dark" content="Рецепт" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <RecipeIcon class="neutral700" width="20" height="20" :currentColor="true" :strokeWidth="2"/>
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="addDeliveryFormItemInputText">
                                    <div v-if="!details.good?.id" class="neutral200">
                                        Выберите рецепт
                                    </div>
                                    <div class="cutOverflow" v-else>
                                        {{ details?.good?.name ?? '-' }}
                                    </div>
                                </div>
                            </div>
                        </el-col>

                        <!-- Заполняет пустое пр-во для переноса строки, если рецепта нет -->
                        <el-col v-if="showHiddenBlock" :sm="!orderMixersAmount ? 5 : 4"/>

                        <!-- Авто -->
                        <el-col :sm="windowWidth < 1900 ? 5 : 4">
                            <div class="addDeliveryFormItem pointer" @click="openSelectDialog('vehicle')">
                                <div>
                                    <el-tooltip effect="dark" content="ТС" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <SolidMixerIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="addDeliveryFormItemInputText">
                                    <div v-if="!details.vehicle_id" class="neutral200">
                                        <span v-if="details.type !== 'take-away'">Выберите авто</span>
                                        <span v-else>Авто</span>
                                    </div>
                                    <div class="cutOverflow" v-else>
                                        {{ getFormattedVehicleNumber(details.number) ?? '-' }}
                                    </div>
                                </div>
                            </div>
                        </el-col>

                        <!-- Водитель -->
                        <el-col :sm="windowWidth < 1900 ? 8 : 6">
                            <div class="addDeliveryFormItem pointer" @click="openSelectDialog('driver')">
                                <div>
                                    <el-tooltip effect="dark" content="Водитель" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <SolidUserProfileIcon class="neutral700" :currentColor="true" :width='20' :height='20'  :strokeWidth="2"/>
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="addDeliveryFormItemInputText">
                                    <div v-if="!details.driver?.id" class="neutral200">
                                        <span v-if="details.type !== 'take-away'">Выберите водителя</span>
                                        <span v-else>Водитель</span>
                                    </div>
                                    <div class="cutOverflow" v-else>
                                        {{ getDriverShorten() }}
                                    </div>
                                </div>
                            </div>
                        </el-col>

                    </el-row>
                </div>
                <div class="add-shipping__comments">
                    <hr/>

                    <template v-if="!details.mix_order_groups_comment_driver && !details?.mix_order_comment && !isEditCommentFormOpened">
                        <el-button
                            style="border: none; padding: 0; background-color: transparent; color: #409eff;"
                            :icon="icons.plus"
                            type="primary"
                            @click="openEditCommentForm"
                        >
                            Добавить комментарий для этой отгрузки
                        </el-button>
                    </template>
                    <template v-else>
                        <div class="d-flex f-direction-column gap-5">
                            <div v-if="!isEditCommentFormOpened" class="d-flex align-center mb-5">
                                <el-tooltip :disabled='!details.mix_order_comment' content="Индивидуальный комментарий"
                                            position="top">
                                    <ChatCircleDotsIcon
                                        :class="[details.mix_order_comment ? 'warning300 pointer' : 'neutral300']"
                                        :currentColor="true"/>
                                </el-tooltip>
                                <p class="textSM textMedium neutral700 m-0 ml-5 mr-10">Комментарий для водителя:</p>
                                <EditPen class="primary600 pointer" width="16" height="16"
                                         @click="openEditCommentForm"/>
                            </div>
                        </div>

                        <div
                            v-if="!isEditCommentFormOpened"
                            v-html="getFormattedComment(details.mix_order_comment && details.mix_order_comment.length ? details.mix_order_comment : details.mix_order_groups_comment_driver)" />
                    </template>

                    <div v-if="isEditCommentFormOpened" class="addDeliveryFormItem editDriverComment">
                        <el-tooltip effect="dark" content="Индивидуальный комментарий" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <ChatCircleDotsIcon :currentColor="true" width="20" height="20"/>
                            </div>
                        </el-tooltip>
                        <div class="addDeliveryFormItemInputText">
                            <el-input v-model="details.mix_order_comment" placeholder="Комментарий для водителя"
                                      type="textarea" :autosize="{ minRows: 1, maxRows: 4 }"/>
                        </div>
                    </div>

                </div>
            </div>

            <div class="add-shipping__footer">
                <button
                    class="ci-btn back-g-none mr-10"
                    @click="() => $emit('edit')"
                >
                    Назад
                </button>
                <el-button
                    class="ci-btn ci-btn_blue"
                    :disabled="loadingButton"
                    @click="update(true)"
                >
                    Сохранить
                </el-button>
            </div>
        </div>
    </div>

    <el-dialog
        class="group-order-action-dialog"
        v-model="selectDialog"
        :title="selectDialogTitle"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <template v-if="selectType === 'mix'">
            <MixOrderDetailsGroupEditSelectMixes
                @updateItem="updateMixes"
                :modelValue="details.mixer_id"
                :items="orderMixers"
                :is-all-mixers-is-in-order="isAllMixersIsInOrder"
                :order-mixers="orderMixers"
            />
        </template>
        <template v-else-if="selectType === 'vehicle'">
            <MixOrderDetailsGroupEditSelectVehicles
                :order="details"
                :drivers="drivers"
                :mixers="orderMixers"
                @updateItem="updateVehicle"
            />
        </template>
        <template v-else-if="selectType === 'driver'">
            <MixOrderDetailsGroupEditSelectDrivers
                @updateItem="updateDriver"
                :items="drivers"
            />
        </template>
        <template v-else-if="selectType === 'good'">
            <MixOrderDetailsGroupEditSelectGoods
                @updateItem="updateGood"
                :integration-unit-id="integrationUnitId"
            />
        </template>
    </el-dialog>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import iconsMixin from "@/mixins/iconsMixin";
import moment from "moment/moment";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common';
import MixOrderDetailsGroupEditSelectMixes
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectMixes.vue";
import {httpService} from "@/services/http.service";
import MixOrderDetailsGroupEditSelectDrivers
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectDrivers.vue";
import MixOrderDetailsGroupEditSelectGoods
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectGoods.vue";
import MixOrderDetailsGroupEditSelectVehicles
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectVehicles.vue";

// icons
import ChatCircleDotsIcon from "@/views/components/Icons/ChatCircleDotsIcon.vue";
import PackageGoodIcon from "@/views/components/Icons/PackageGoodIcon.vue";
import BuildingIcon from "@/views/components/Icons/BuildingIcon.vue";
import ArrowDownRightIcon from '@/views/components/Icons/ArrowDownRightIcon.vue';
import SolidMixerIcon from "@/views/components/Icons/SolidMixerIcon.vue";
import SolidUserProfileIcon from "@/views/components/Icons/SolidUserProfileIcon.vue";
import RecipeIcon from "@/views/components/Icons/RecipeIcon.vue";
import {EditPen} from "@element-plus/icons-vue";

export default {
    name: "MixOrderDetailsGroupEdit",
    components: {
        MixOrderDetailsGroupEditSelectVehicles,
        MixOrderDetailsGroupEditSelectGoods,
        MixOrderDetailsGroupEditSelectDrivers,
        MixOrderDetailsGroupEditSelectMixes,
        ChatCircleDotsIcon,
        EditPen,
        PackageGoodIcon,
        BuildingIcon,
        ArrowDownRightIcon,
        SolidMixerIcon,
        SolidUserProfileIcon,
        RecipeIcon
    },
    props: ['orderGroup', 'mixOrder', 'loading'],
    mixins: [functionsMixin, mixOrderInfo, iconsMixin, mobileCheckMixin],
    inject: ['api'],
    data() {
        return {
            details: null,
            selectDialogTitle: null,
            selectType: null,
            selectDialog: false,
            loadingButton: false,
            mixes: [],
            isEditCommentFormOpened: false,
            integrationUnitId: null
        }
    },
    computed: {
        ...mapState(useCommonStore, {
                drivers: 'getDrivers',
                modules: 'getModules',
                integrations: 'getIntegrations',
            }),
        orderMixers() {
            const orderMixerIds = this.orderGroup.info.mixerIds;
            const mixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey)?.mixers;
            const selectedMixers = mixers.filter((mixer) => orderMixerIds.includes(mixer.id));
            return selectedMixers.map((mixer) => { return {
                id: mixer.id, mix_id: mixer.mix_id,
                name: this.setMixName(mixer, mixers)}
            })
        },
        isLab() {
            return this.modules.find((module) => module.key === this.$route.meta.moduleKey).lab.length ?? 0;
        },
        orderMixersAmount() {
            return this.orderMixers.length > 1
        },
        isAllMixersIsInOrder() {
            const mixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey)?.mixers;
            const orderMixerIds = this.orderGroup.info.mixerIds;
            return mixers.every((mixer) => orderMixerIds.includes(mixer.id));
        },
        showHiddenBlock() {
            if(this.isLab && !this.orderMixersAmount) return false
            if(!this.isLab && this.orderMixersAmount) return false

            return this.windowWidth < 1900 ? true : false
        },
    },
    created() {
        this.details = {...this.mixOrder} // create a deep copy
        this.details.start_at = moment(this.details.start_at).format('HH:mm')
    },
    methods: {
        update(confirm) {
            this.loadingButton = true
            const now = moment(this.details.date);

            let order = {
                mix_id: this.details.mix_id,
                mixer_id: this.details.mixer_id,
                total: this.details.total,
                vehicle_id: this.details.vehicle_id,
                driver_id: this.details.driver?.id,
                start_at: moment.tz(`${now.format('YYYY-MM-DD')}T${this.details.start_at}`, 'YYYY-MM-DDTHH:mm', this.moduleTimezone).utc(),
                arrive_at: moment.tz(`${now.format('YYYY-MM-DD')}T${this.details.arrive_at}`, 'YYYY-MM-DDTHH:mm', this.moduleTimezone).utc(),
                good_id: this.details.good?.id,
                mix_order_comment: this.details.mix_order_comment,
            }

            httpService().post(this.api.dispatch.orders.update + '/' + this.mixOrder.id, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order: order,
                confirm: confirm
            }).then(() => {
                this.$message({
                    message: 'Отгрузка успешно обновлена',
                    showClose: true,
                    type: 'success'
                })
                if (confirm !== null) this.confirm = confirm
                this.editOrder = false
                this.loadingButton = false
                this.$emit('get')
                this.$emit('edit')
            }).catch((error) => {
                const {errors} = error.response.data

                if (errors) {
                    for (let key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            errors[key].forEach(errorMessage => {
                                this.$message({
                                    message: errorMessage,
                                    showClose: true,
                                    type: 'error'
                                });
                            });
                        }
                    }
                } else {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.$emit('get')
                }
                this.loadingButton = false

            })
        },
        openSelectDialog(type) {
            this.selectType = type

            if (type === 'mix') {
                this.selectDialogTitle = 'Выберете бетонный завод'
            } else if (type === 'vehicle') {
                this.selectDialogTitle = 'Выберите транспортное средство'
            } else if (type === 'driver') {
                this.selectDialogTitle = 'Выберите водителя'
            } else if (type === 'good') {
                if (this.orderGroup.lab === true) {
                    this.$message({
                        message: 'Для выбора рецепта по отгрузке обратитесь к лаборатории',
                        showClose: true,
                        type: 'error'
                    })
                    return
                }
                this.selectDialogTitle = 'Выберите рецепт'
            }

            this.selectDialog = true
        },
        updateMixes(mixer) {
            const parts = mixer.name.split(" - ");

            this.details.mix_id = mixer.mix_id
            this.details.mixer_id = mixer.id
            this.details.mix.mix_name = parts[0];
            this.details.mixer.mixer_name = parts[1] || null; // Если второй части нет, тогда null

            this.selectDialog = false
        },
        updateDriver(driver) {
            this.details.driver.id = driver.id
            this.details.driver.name = driver.name

            this.selectDialog = false
        },
        updateGood(good) {
            this.details.good.id = good.id
            this.details.good.name = good.name

            this.selectDialog = false
        },
        updateVehicle(schedule) {
            let vehicle = schedule.vehicle
            let driver = this.drivers.find((f) => f.id === schedule.driver_id)
            driver = driver ?? this.drivers.find((f) => f.id === vehicle.driver_id)

            this.details.vehicle_id = vehicle.id
            this.details.number = vehicle.number

            if (driver) {
                this.details.driver = driver
            }

            this.selectDialog = false
        },
        openEditCommentForm() {
            this.isEditCommentFormOpened = true
            if (!this.details.mix_order_comment) {
                this.details.mix_order_comment = this.details.mix_order_groups_comment_driver
            }
        },
        setMixName(mixer, allMixers) {
            const amountOfMixersPerMix = allMixers.filter((item) => item.mix_id === mixer.mix_id)?.length;
            return amountOfMixersPerMix > 1 ? mixer.name : mixer.mix_name;
        },
        setIntegrationUnitId() {
            const mix_id = this.mixers.find((mixer) => mixer.id == this.details.mixer_id)?.mix_id; // находим id завода
            if(mix_id) {
                const integration = this.integrations.find((integration) => integration.account_module?.module_reserve_id === mix_id); // находим нужную интеграцию
                    if (integration) {
                    this.integrationUnitId = integration.id;
                }
            }
        },
        getDriverShorten() {
            let label = this.drivers.find(el => el.id === this.details.driver?.id)?.name
            if (label) label = this.formatName(label)
            return label ?? '-'
        },
    },
    watch: {
        'details.mixer_id': {
            handler: function () {
                if (this.mixes.length) {
                    this.setIntegrationUnitId();
                    this.details.good = {
                        id: null,
                        name: null
                    };
                }
            },
            deep: true,
            immediate: true
        },
        'mixers': {
            handler: function () {
                if (this.details?.mixer_id) {
                    this.setIntegrationUnitId()
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
