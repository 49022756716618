<template>
    <el-dropdown
        :visible="true"
        ref="dropdown"
        class="badge-filter"
        :hide-on-click="false"
        popper-class="badge-filter"
        @visible-change="handleVisibleChange"
        trigger="click"
    >
        <el-badge class="badge-filter__badge" :value="badgeAmount" type="primary" :show-zero="false">
            <el-button
              class="badge-filter__button"
              :class="{ 'badge-filter__button--active': isDropdownVisible || badgeAmount > 0 }"
              :icon="FilterSettingsIcon"
            >
            Фильтры
          </el-button>
        </el-badge>
        <template #dropdown>
            <el-dropdown-menu>
                <slot name="filter-dropdown"></slot>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
  </template>

<script>
import FilterSettingsIcon from "@/views/components/Icons/FilterSettingsIcon.vue";
import mobileCheckMixin from "../../mixins/mobileCheckMixin";

export default {
    name: 'BadgeFilterButton',
    props: ['badgeAmount'],
    mixins: [mobileCheckMixin],
    methods: {
        handleVisibleChange(e) {
            this.isDropdownVisible = e;
            this.$emit('visibleChangeDropdown', e)
        }
    },
    data() {
        return {
            FilterSettingsIcon: FilterSettingsIcon,
            isDropdownVisible: false
        }
    },
}
</script>

<style scoped lang="scss">
@use "@/sass/_variables.scss";

.badge-filter {
    &__button {
        padding: 12px 24px;
        background: variables.$white;
        color: variables.$neutral500;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid variables.$neutral100;
        height: 46px !important;

        @media screen and (max-width: 1300px) {
            width: 62px;

            &:deep(span) {
                display: none;
            }
        }

        &:hover, &--active {
            background: variables.$primary50;
            border: 1px solid variables.$primary600;
            color: variables.$primary400;
        }

        &:deep([class*=el-icon]+span) {
            margin-left: 0 !important;
            font-family: Ubuntu;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }

        &:deep(.el-icon) {
            width: 24px;
            height: 24px;

            svg {
                width: inherit;
                height: inherit;

                path {
                    stroke: currentColor;
                }
            }
        }
    }

    &:deep(.el-badge__content) {
        height: 20px;
        border: none;
        background: variables.$primary600;
        font-family: Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}
</style>


<style lang="scss">
.badge-filter {
    border-radius: 8px !important;
    border: none !important;

    .el-dropdown-menu {
        padding: 16px;
        width: 328px;
    }

    .el-popper__arrow::before {
        border: none !important;
    }

    .el-scrollbar {
        overflow: visible;

        .el-scrollbar__wrap {
            overflow: visible;
        }
    }
}
</style>
