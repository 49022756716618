<template>
    <div class="tabs">
        <div class="line"/>
        <div class="top">
            <div id="tabs-main" class="tabs-main" :style="`--x: ${blockOffsetLeft}px; --width: ${blockWidth}px`">
                <div v-for="{ name, key, disabled } in items" :key="key" class="tab"
                     :class="{ disabled, active: activeTab === key }"
                     @click="!disabled ? setActiveTab(key) : undefined">
                    <span>{{ name }}</span>
                </div>
                <div id="block" class="block"/>
            </div>
            <div v-if="filters && filters.length > 0" id="tabs-additional" class="tabs-additional"
                 :style="`--x: ${filtersBlockOffsetLeft}px; --width: ${filtersBlockWidth}px`">
                <div v-for="{ name, key } in filters" :key="key" class="tab" :class="{ active: activeFilter === key }"
                     @click="setActiveFilter(key)">
                    <span>{{ name }}</span>
                </div>
                <div id="filters-block" class="block"/>
            </div>
        </div>
        <div class="line"/>
        <div class="content">
            <template v-for="item in items" :key="item.key">
                <div v-show="item.key === activeTab" class="content__inner">
                    <slot :name="item.key"/>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, onUnmounted, onUpdated, ref} from 'vue';

const props = defineProps(['items', 'filters', 'tab']);
const emits = defineEmits(['changeFilter', 'activeTabChange']);

const activeTab = ref(props.items[props.tab].key);
let activeFilter = null;
if (props.filters.length > 0) activeFilter = ref(props.filters[0].key);

const blockOffsetLeft = ref(0);
const blockWidth = ref(0);

const filtersBlockOffsetLeft = ref(0);
const filtersBlockWidth = ref(0);

const setActiveTab = (v) => {
    activeTab.value = v;
    emits('activeTabChange', activeTab.value);
}
const setActiveFilter = (v) => {
    activeFilter.value = v;
    emits('changeFilter', activeFilter.value);
};

const updateBlockPosition = () => {
    const el = document.querySelector('#tabs-main > div.active');
    const filtersEl = document.querySelector('#tabs-additional > div.active');

    if (!el && !filtersEl) return;

    blockOffsetLeft.value = el.offsetLeft;
    blockWidth.value = el.clientWidth + 1;

    if (props.filters.length > 0) {
        filtersBlockOffsetLeft.value = filtersEl.offsetLeft;
        filtersBlockWidth.value = filtersEl.clientWidth + 2;
    }
};

onUpdated(() => updateBlockPosition());

onMounted(() => {
    updateBlockPosition();

    window.addEventListener('resize', updateBlockPosition);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateBlockPosition);
});
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.tabs {
    .line {
        width: 100%;
        height: 1px;
        background-color: variables.$neutral50;
        box-shadow: 0 0 0 100vmax variables.$neutral50;
        clip-path: inset(0 -100vmax)
    }

    &.grey {
        .top {
            background-color: variables.$neutral30;
            padding: 24px 25px;
        }
    }

    .top {
        padding: 24px 0;
        display: flex;
        gap: 24px;
        box-sizing: border-box;
    }

    &-additional,
    &-main {
        box-sizing: border-box;
        width: fit-content;
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid #EEE;
        background-color: variables.$neutral30;
        display: flex;

        position: relative;

        .block {
            height: 100%;
            width: var(--width);
            background-color: variables.$white;
            border: 1px solid variables.$neutral50;
            border-radius: 11px;
            position: absolute;
            left: var(--x);
            will-change: left;
            transition: left ease-in-out 0.4s, width ease-in-out 0.4s;
        }
    }

    .tab {
        padding: 12px 24px;
        box-sizing: border-box;

        color: variables.$neutral500;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        cursor: pointer;
        transition: color .2s, border-radius .2s, border .2s;

        & > span {
            position: relative;
            z-index: 1;
        }

        &.active,
        &:not(.disabled):hover {
            color: variables.$primary600;
        }

        &.active {
            outline: 1px solid variables.$neutral30;
            border-color: variables.$neutral30 !important;
        }

        &.disabled {
            color: variables.$neutral200;
            cursor: not-allowed;
        }

        &:not(:last-child) {
            border-right: 1px solid variables.$neutral50;
        }
    }
}
</style>
