<template>
    <div v-if="resolution ? resolution : smallMobile" class="mobileTable">
        <slot name="body"></slot>
    </div>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "MobileTableFramework",
    props: ['resolution'],
    mixins: [
        mobileCheckMixin
    ]
}
</script>

<style scoped>

</style>
