<template>
    <ComponentsHeadBlockMixer
        class="dispatchMixersTableHead"
        :loading="loading"
        :getDate="getDate"
        :filters="filters"
        :showDate="switcher"
        :editView="editView"
        @changeDate="changeDate"
        @moduleGet="getVehicleSchedules()"
    >
        <template #switchBlock>
            <div class="d-flex switcher textSM textMedium neutral500">
                <div :class="switcher ? 'active' : ''" @click="toggleSwitch(true)">На день</div>
                <div :class="switcher ? '' : 'active'" @click="toggleSwitch(false)">По умолчанию</div>
            </div>
        </template>
        <template #scheduleDefault v-if="countSchedule === 0 && !editView">
            <img :src="require('@/assets/warning-2.svg')" class="order-groups__icon mr-5"/>
            <span class="warning300 textSM textRegular400">График по умолчанию</span>
        </template>
        <template #buttonsBlock>
            <button
                v-if="!editView"
                @click="openMapViewDialog()"
                class="ci-btn ci-btn_light br-8"
            >
                На карте
            </button>
            <button
                v-if="!editView"
                @click="setEditView()"
                class="ci-btn ci-btn_blue br-8"
                :disabled="disableEdit"
            >
                Редактировать
            </button>
            <button
                v-if="editView"
                @click="createMixerDrawer = !createMixerDrawer"
                class="ci-btn ci-btn_light br-8"
            >
                <PlusIcon/>
                Добавить авто
            </button>
        </template>
    </ComponentsHeadBlockMixer>

    <EmptyState
        :values="getTableData()"
        :filter="filter"
        :search="search"
        :loading="loading"
        :list-types="type === 'mix' ? 'миксеров' : 'насосов'"
        action-text="Добавьте ТС в график работы"
    />

    <el-table
        v-loading="loading"
        :data="getTableData()"
        v-if="!mobile && getTableData().length"
        class="w-100 mt-15 mb-15 dispatchMixersTable">

        <el-table-column
            prop="active"
            label=""
            :width="editView ? 40 : 1"
        >
            <template v-slot="scope">
                <el-checkbox v-show="editView && switcher" v-model="scope.row.active"/>
            </template>
        </el-table-column>

        <el-table-column
            prop="rent"
            prop2="gidrolotok"
            label=""
            :width="type === 'mix' ? '64' : '48'"
        >
            <template v-slot="scope">
                <div class="d-flex gap-8">
                    <el-tooltip :content="`${scope.row.rent ? 'Арендованное' : 'Собственное'} ТС`" placement="top" :show-after="200" :hide-after="100">
                        <RentIcon :class="scope.row.rent ? 'neutral500' : 'neutral100'" :current-color="true"/>
                    </el-tooltip>
                    <el-tooltip v-if="type === 'mix'" :content="`${scope.row.gidrolotok ? 'Есть гидролоток' : 'Нет гидролотка'} ТС`" placement="top" :show-after="200" :hide-after="100">
                        <GidrolotokLetterIcon :class="scope.row.gidrolotok ? 'neutral500' : 'neutral100'" :current-color="true"/>
                    </el-tooltip>
                </div>
            </template>
        </el-table-column>

        <el-table-column
            prop="gps_vehicle_id"
            :label="!editView ? 'GPS' : ''"
            :width="!editView ? 50 : 1"
        >
            <template v-slot="scope">
                <div v-show="!editView">
                    <template
                        v-if="!scope.row.vehicle_id || !scope.row.gps_vehicle_id">
                        <div class="text-center pt-0">
                            <el-tooltip effect="dark" placement="right" content="Авто без GPS">
                                <SignalIcon :disabled="true"/>
                            </el-tooltip>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center">
                            <el-tooltip effect="dark" placement="left">
                                <template #content>
                                    <template v-if="scope.row.gps_last_date">
                                        <template v-if="!scope.row.gps_online">GPS сигнал потерян<br/></template>
                                        Время последнего обновления:<br/>
                                        {{
                                            getDateMoment(scope.row.gps_last_date, 'DD.MM HH:mm')
                                        }}
                                    </template>
                                    <template v-else>
                                        GPS сигнал не обнаружен
                                    </template>
                                </template>
                                <div class="ordersTableItemGPSIcon pt-0">
                                    <SignalIcon
                                        :class="{ordersTableItemGPSIconOnline: scope.row.gps_online}"/>
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                </div>
            </template>
        </el-table-column>

        <el-table-column
            prop="number"
            label="Номер"
            width="140"
        >
            <template v-slot="scope">
                <span class="textSM textMedium neutral700">{{ getVehicleNumber(scope.row.number) }}</span>
            </template>
        </el-table-column>

        <el-table-column
            prop="driver_id"
            :width="driverColumnWidth"
            label="Водитель"
        >
            <template v-slot="scope">
                <template v-if="!editView">
                    <span class="textSM textRegular400 neutral900">
                        {{ scope.row.driver_id ? ( getDriverShortName(scope.row.driver_id) ?? '-' ) : '-' }}
                    </span>
                </template>
                <template v-if="editView">
                    <el-button :disabled="!scope.row.active" @click="openChangeDriverDialog(scope.row)"
                               class="p-0" style="height: auto !important;" type="text">
                        <EditIcon class="f-shrink-0"/>
                        <span class="textSM textRegular400 primary600 w-space-normal text-left" style="line-height: 18px;"
                              v-if="!scope.row.driver_id">Укажите водителя</span>
                        <span class="textSM textRegular400 deep-blue900 text-left w-space-normal" v-else>{{
                            scope.row.driver_id
                                ? (windowWidth <= 1560 ? getDriverShortName(scope.row.driver_id) : getDriverName(scope.row.driver_id))
                                : '-'
                        }}
                        </span>
                    </el-button>
                </template>
            </template>
        </el-table-column>

        <el-table-column
            prop="mixers"
            label="Заводы"
        >
            <template v-slot="scope">
                <template v-if="!editView && scope.row.mixers">
                    <span class="textSM textRegular400 neutral900 cutOverflow"
                          v-for="(mixId, index) in scope.row.mixers" :key="index"
                    >
                        {{ currentMixers(mixId) }}
                        <span v-if="index < scope.row.mixers.length - 1">, </span>
                    </span>
                </template>
                <template v-if="editView">
                    <el-button :disabled="!scope.row.active" @click="openChangeMixesDialog(scope.row)"
                               class="p-0" style="height: auto !important;" type="text">
                        <EditIcon/>
                        <span class="textSM textRegular400 primary600"
                              v-if="scope.row.mixers && scope.row.mixers.length === 0">Укажите заводы</span>
                        <template v-else>
                            <span class="textSM textRegular400 deep-blue900 cutOverflow"
                                  v-for="(mixId, index) in scope.row.mixers" :key="index"
                            >
                                {{ currentMixers(mixId) }}
                                <span v-if="index < scope.row.mixers.length - 1">, </span>
                            </span>
                        </template>
                    </el-button>
                </template>
            </template>
        </el-table-column>

        <el-table-column
            prop="volume"
            label="Объем"
            width="110"
            v-if="type === 'mix'"
        >
            <template v-slot="scope">
                            <span class="textSM textRegular400 primary600"
                                  v-if="scope.row.volume && scope.row.volume > 0">{{ scope.row.volume }} <good-unit
                                unit="м3."/></span>
                <span class="textSM textMedium neutral700" v-else>-</span>
            </template>
        </el-table-column>

        <el-table-column
            prop="objectsStart"
            label="Объект начала"
            width="200"
            v-slot="scope"
            v-if="factories && factories.length > 1"
        >
            <template v-if="!editView">
                <span class="textSM textRegular400 neutral800">
                    {{ scope.row.factory_start ? factories.find(f => f.id == scope.row.factory_start)?.name : '-' }}
                </span>
            </template>
            <template v-else>
                <el-button :disabled="!scope.row.active" @click="openChangeFactoryDialog(scope.row, 'factory_start')"
                           class="p-0" style="height: auto !important;" type="text">
                    <EditIcon class="f-shrink-0"/>
                    <span class="textSM textRegular400 primary600 w-space-normal text-left" style="line-height: 18px;"
                          v-if="!scope.row.factory_start">Укажите объект</span>
                    <span class="textSM textRegular400 deep-blue900 text-left w-space-normal" v-else>
                        {{ scope.row.factory_start ? factories.find(f => f.id == scope.row.factory_start)?.name : '-' }}
                    </span>
                </el-button>
            </template>
        </el-table-column>

        <el-table-column
            prop="objectsEnd"
            label="Объект завершения"
            width="200"
            v-slot="scope"
            v-if="factories && factories.length > 1"
        >
        <template v-if="!editView">
            <span class="textSM textRegular400 neutral800">
                {{ scope.row.factory_end ? factories.find(f => f.id == scope.row.factory_end)?.name : '-' }}
            </span>
        </template>
        <template v-else>
            <el-button :disabled="!scope.row.active" @click="openChangeFactoryDialog(scope.row, 'factory_end')"
                       class="p-0" style="height: auto !important;" type="text">
                <EditIcon class="f-shrink-0"/>
                <span class="textSM textRegular400 primary600 w-space-normal text-left" style="line-height: 18px;"
                      v-if="!scope.row.factory_end">Укажите объект</span>
                <span class="textSM textRegular400 deep-blue900 text-left w-space-normal" v-else>
                    {{ scope.row.factory_end ? factories.find(f => f.id == scope.row.factory_end)?.name : '-' }}
                </span>
            </el-button>
        </template>
        </el-table-column>


<!--        <el-table-column-->
<!--            prop="axle"-->
<!--            label="Оси"-->
<!--            width="70"-->
<!--        >-->
<!--            <template v-slot="scope">-->
<!--                <span class="textSM textMedium"-->
<!--                      :class="scope.row.axle ? 'warning300' : 'neutral700'">{{ scope.row.axle ?? '-' }}</span>-->
<!--            </template>-->
<!--        </el-table-column>-->

        <el-table-column
            prop="pump_length"
            label="Длина"
            width="80"
            v-if="type === 'pump'"
        >
            <template v-slot="scope">
                <span class="textSM textMedium neutral700">{{ scope.row.pump_length ?? '-' }}</span>
            </template>
        </el-table-column>

        <el-table-column
            class=""
            prop="start_time"
            label="График"
            :width="!editView ? 160 : 225"
        >
            <template v-slot="scope">
                <template v-if="!editView">
                    <template v-if="scope.row.start_time">
                        <span class="textSM textRegular400 neutral800">
                            {{ scope.row.start_time }} - {{ scope.row.end_time }}
                        </span>
                    </template>
                </template>
                <template v-else>
                    <div class="d-flex-full-stroke time-input-select gap-8"
                         :class="scope.row.start_time === scope.row.end_time ? 'error-border': ''">
                        <el-input
                            :disabled="!scope.row.active"
                            v-model="scope.row.start_time"
                            placeholder="00:00"
                            type="time"
                        />
                        <span>-</span>
                        <el-input
                            :disabled="!scope.row.active"
                            v-model="scope.row.end_time"
                            placeholder="00:00"
                            type="time"
                        />
                    </div>
                    <div class="error textXS textRegular400" v-if="scope.row.start_time === scope.row.end_time">
                        Время не может быть одинаковым
                    </div>
                </template>
            </template>
        </el-table-column>

        <!--        <el-table-column-->
        <!--            prop="load"-->
        <!--            label="Загрузка"-->
        <!--            width="80"-->
        <!--            v-if="!editView"-->
        <!--        >-->
        <!--            <template v-slot="scope">-->
        <!--                <span class="textSM textMedium"-->
        <!--                      :class="[-->
        <!--                                {neutral700: !scope.row.load},-->
        <!--                                {warning300: scope.row.load >= 80},-->
        <!--                                {success400: scope.row.load < 80}-->
        <!--                            ]"-->
        <!--                >{{ scope.row.load ?? '-' }}</span>-->
        <!--            </template>-->
        <!--        </el-table-column>-->

        <el-table-column
            fixed="right"
            label=""
            width="156px">
            <template v-slot="scope">
                <div class="text-right d-flex gap-8">
                    <a class="el-button el-button--small el-button text-none" size="small" target="_blank"
                       :href="'/settings/vehicles/' + scope.row.vehicle_id"
                      >
                        Подробнее
                    </a>
                    <el-tooltip v-if="editView && !switcher" effect="dark" placement="top">
                        <template #content>
                            <div class="text-center">
                                <div class="textMedium mb-5">Убрать из графика</div>
                                <div class="textXS">Убрать машину из <br/> графика по умолчанию</div>
                            </div>
                        </template>
                        <el-button
                            class="ml-0"
                            size="small"
                            @click="removeDefaultMixer(scope.row)"
                        >
                            Х
                        </el-button>
                    </el-tooltip>
                </div>
            </template>
        </el-table-column>
    </el-table>


    <TablePagination
        class="pb-20"
        v-model="pageSize"
        :total="tableDataLength"
        @action="setPage"
        @reset-page="resetPage"
        ref="tablePagination"
    />

    <template v-if="editView">
        <div class="text-right button-LG">
            <el-button class="br-8" @click="setEditView">
                Вернуться назад
            </el-button>
            <el-button v-if="editView && !switcher"
                       class="br-8" type="primary" @click="updateShiftSchedules(true)">
                Сохранить график
            </el-button>
            <el-button v-else
                       class="br-8" type="primary" @click="updateShiftSchedules(false)">
                Сохранить график
            </el-button>
        </div>
    </template>


    <el-drawer
        title=""
        v-model="createMixerDrawer"
        direction="rtl"
        class="mixer-create"
        :size="600"
        :destroy-on-close="true"
    >

        <MixerCreate
            :displayDataFromList="getAllMixersId"
            :mixers="moduleMixers"
            :drivers="drivers"
            :type="type"
            @closeDrawer="closeCreateMixerDrawer"
            @getVehicleSchedulesForDay="getVehicleSchedules()"
        />

    </el-drawer>

    <el-dialog
        title="Выбор водителя"
        v-model="changeDriverDialog"
        direction="rtl"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <SelectDialogDrivers
            :items="drivers"
            :type="type"
            @changeDriver="changeDriver"
        />

    </el-dialog>

    <el-dialog
        title="Выбор заводов"
        v-model="changeMixesDialog"
        direction="rtl"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <SelectDialogMixes
            :items="mixes"
            :selected="driverMixer"
            @changeMixes="changeMixes"
        />

    </el-dialog>

    <el-dialog
        title="Выбор объектов"
        v-model="changeFactoryDialog"
        direction="rtl"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <SelectDialogFactory
            :items="factories"
            @change-factory="changeFactory"
        />

    </el-dialog>

    <el-dialog
        :title="'Подробности ' + (type === 'mix' ? 'миксера' : 'насоса')"
        v-model="mixerDetailsDialog"
        direction="rtl"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <div class="info-container">
            <el-row class="info-container__body" :gutter="20">
                <el-col :sm="12">
                    <div class="info-container__body__item">
                        <div class="info-container__body__item__label">
                            Номер
                        </div>
                        <div class="info-container__body__item__content">
                            {{ driverMixer.number }}
                        </div>
                    </div>
                    <div class="info-container__body__item" v-if="type === 'mix'">
                        <div class="info-container__body__item__label">
                            Вместимость
                        </div>
                        <div class="info-container__body__item__content">
                            {{ driverMixer.volume }}
                            <good-unit
                                unit="м3."/>
                        </div>
                    </div>
                </el-col>
                <el-col :sm="12">
                    <div class="info-container__body__item">
                        <div class="info-container__body__item__label">
                            Водитель
                        </div>
                        <div class="info-container__body__item__content">
                            {{ getDriverName(driverMixer.driver_id) }}
                        </div>
                    </div>
                    <div class="info-container__body__item">
                        <div class="info-container__body__item__label">
                            Телефон водителя
                        </div>
                        <div class="info-container__body__item__content">
                            {{ getInfo(driverMixer.driver_id, users, 'phone') }}
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

    </el-dialog>

    <el-dialog
        title="ТС на карте"
        v-model="mapViewDialog"
        direction="rtl"
        :size="!mobile ? '60%' : '90%'"
        :destroy-on-close="true">
        <div style="height: 60vh; display: flex; justify-content: center; align-items: center" v-if="mapLoading">
            <ReportLoader/>
        </div>
        <div style="height: 60vh" v-else>
            <MixersMap
                :vehicles="vehicles"
                :factories="factories"
                :coords="coords"
            />
        </div>
    </el-dialog>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import moment from 'moment/moment'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import ComponentsHeadBlockMixer from "@/views/dispatch/components/ComponentsHeadBlockMixer.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import {httpService} from "@/services/http.service";
import MixerCreate from "@/views/dispatch/components/MixerCreate.vue";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import GoodUnit from "@/views/components/goodUnit.vue";
import SelectDialogDrivers from "@/views/dispatch/components/SelectDialogDrivers.vue";
import SelectDialogMixes from "@/views/dispatch/components/SelectDialogMixes.vue";
import SelectDialogFactory from "@/views/dispatch/components/SelectDialogFactory.vue";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import {useCommonStore} from '@/store/common';

// icons
import EditIcon from "@/views/components/Icons/EditIcon.vue";
import PlusIcon from '@/views/components/Icons/PlusIcon.vue';
import SignalIcon from "@/views/components/Icons/SignalIcon.vue";
import MixersMap from "@/views/dispatch/components/mixers/MixersMap.vue";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import RentIcon from "@/views/components/Icons/RentIcon.vue";
import GidrolotokLetterIcon from "@/views/components/Icons/GidrolotokLetterIcon.vue";

export default {
    name: 'DispatchMixersTable',
    mixins: [dispatchTabs, tableMixin, iconsMixin, functionsMixin, mobileCheckMixin],
    props: ['type'],
    data() {
        return {
            loading: false,
            switcher: true,
            createMixerDrawer: false,
            mapViewDialog: false,
            editView: false,
            removedDefaultMixersIds: [],
            mixers: [],
            mixes: [],
            users: [],
            moduleMixers: [],
            driverMixer: null,
            changeDriverDialog: false,
            changeMixesDialog: false,
            changeFactoryDialog: false,
            isDefaultMixer: false,
            disableEdit: true,
            drivers: [],
            mixerDetailsDialog: false,
            tableDataLength: 0,
            vehicles: [],
            schedule: [],
            countSchedule: 0,
            defaultSchedule: [],
            coords: [55.811511, 37.312518],
            mapLoading: true,
            factory: null,
        }
    },
    components: {
        ReportLoader,
        MixersMap,
        SignalIcon,
        SelectDialogMixes,
        SelectDialogDrivers,
        SelectDialogFactory,
        GoodUnit,
        MixerCreate,
        TablePagination, EmptyState,
        ComponentsHeadBlockMixer,
        EditIcon, PlusIcon, RentIcon, GidrolotokLetterIcon
    },
    inject: ['api'],
    created() {
        this.getVehicleSchedules()
        this.getCurrentModule()
        this.getMixes()
        this.getUsers()
    },
    computed: {
        ...mapState(useCommonStore, {
            factories: 'getFactories',
            modules: 'modules',
        }),
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            filters: 'getFilters',
        }),
        getAllMixersId() {
            return this.defaultSchedule.map(i => i.vehicle_id);
        },
        driverColumnWidth() {
            const shortenDrivers = [...new Set(this.getAllTableData().map(item => this.getDriverShortName(item.driver_id)))]

            if(!this.editView) {

                if(this.windowWidth <= 1560) {
                    return this.calculateColumnWidth(shortenDrivers, 120)
                }
                return this.calculateColumnWidth(shortenDrivers, 160, 200)
            }

            const drivers = [...new Set(this.getAllTableData().map(item => this.getDriverName(item.driver_id)))]

            if(this.windowWidth <= 1560) {
                return this.calculateColumnWidth(shortenDrivers, 120, 180)
            }

            return this.calculateColumnWidth(drivers, 160, 300)
        },
    },
    methods: {
        getCurrentModule() {
            this.moduleMixers = this.modules.find(module => +module.accountModuleId === +this.$route.params.id).mixers;
        },
        currentMixers(mixerId) {
            const findMixer = this.moduleMixers.find(mixer => +mixer.id === +mixerId);
            return findMixer.mix_count > 1 ? findMixer.name : findMixer.mix_name
        },
        getVehicleSchedules() {
            this.loading = true

            httpService().post(this.api.dispatch.mixers.getVehicleSchedulesForDay, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.getDate.format('YYYY-MM-DD'),
                type: this.type
            }).then(({data}) => {
                if (data.success) {
                    this.schedule = data.countSchedule ? data.schedule : data.schedule.map(el => ({ ...el, active: !el.rent }))
                    this.countSchedule = data.countSchedule
                    this.defaultSchedule = data.defaultSchedule
                }
                this.loading = false
                this.disableEdit = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.disableEdit = false
            })
        },
        removeDefaultMixer(currentMixer) {
            const mixerId = this.defaultSchedule.find(mixer => mixer.id === currentMixer.id)?.id;
            if (mixerId !== undefined) {
                this.removedDefaultMixersIds.push(mixerId);
            }
            this.defaultSchedule = this.defaultSchedule.filter(mixer => mixer.id !== currentMixer.id)
        },
        openChangeDriverDialog(mixer) {
            this.driverMixer = mixer
            this.isDefaultMixer = this.editView && !this.switcher
            this.changeDriverDialog = true
        },
        openChangeMixesDialog(mixer) {
            this.driverMixer = mixer
            this.isDefaultMixer = this.editView && !this.switcher
            this.changeMixesDialog = true
        },
        openChangeFactoryDialog(row, type) {
            this.factory = {
                factoryType: type,
                ...row
            }
            this.isDefaultMixer = this.editView && !this.switcher
            this.changeFactoryDialog = true
        },
        changeMixes(arr) {
            let mixes = []

            arr.forEach(el => mixes.push(el.id))

            if (this.isDefaultMixer) {
                this.defaultSchedule.forEach(mixer => mixer.vehicle_id === this.driverMixer.vehicle_id ? mixer.mixers = mixes : '')
            } else {
                this.schedule.forEach(mixer => mixer.vehicle_id === this.driverMixer.vehicle_id ? mixer.mixers = mixes : '')
            }

            this.changeMixesDialog = false

            this.$message({
                message: arr.length > 0 ? 'Заводы добавлены' : 'Выбор отменен',
                showClose: true,
                type: 'success'
            })

            this.driverMixer = null
        },
        changeDriver(id) {
            if (this.isDefaultMixer) {
                this.defaultSchedule.forEach(mixer => mixer.vehicle_id === this.driverMixer.vehicle_id ? mixer.driver_id = id : '')
            } else {
                this.schedule.forEach(mixer => mixer.vehicle_id === this.driverMixer.vehicle_id ? mixer.driver_id = id : '')
            }

            this.changeDriverDialog = false

            this.$message({
                message: id ? 'Водитель добавлен' : 'Выбор отменен',
                showClose: true,
                type: 'success'
            })

            this.driverMixer = null
            this.getUsers()
        },
        changeFactory(id) {
            if (this.isDefaultMixer) {
                this.defaultSchedule.forEach(mixer => mixer.vehicle_id === this.factory.vehicle_id ? mixer[this.factory.factoryType] = id : '')
            } else {
                this.schedule.forEach(mixer => mixer.vehicle_id === this.factory.vehicle_id ? mixer[this.factory.factoryType] = id : '')
            }

            this.changeFactoryDialog = false
            this.factory = null
        },
        getDriverName(id) {
            let driver = null
            if (id) {
                driver = this.getInfo(id, this.users, 'name')
                if (!driver && this.users.length > 0) {
                    window.setTimeout(() => {
                        this.getUsers()
                        driver = this.getInfo(id, this.users, 'name')
                    }, 150)
                }
            }
            return driver
        },
        updateShiftSchedules(isDefault) {
            const shifts = this.getAllTableData();

            const errorSE = shifts.filter(dS => dS.start_time === dS.end_time)
            const errorNo = shifts.filter(dS => !dS.start_time || !dS.end_time)
            if (errorSE.length) {
                this.errorsMessage('errorSE')
                return;
            }
            if (errorNo.length) {
                this.errorsMessage('errorNo')
                return;
            }

            this.loading = true

            httpService().post(this.api.dispatch.mixers.updateShiftSchedules, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.getDate.format('YYYY-MM-DD'),
                shifts: shifts,
                removedIds: this.removedDefaultMixersIds,
                isDefault: isDefault
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'График сохранен',
                        showClose: true,
                        type: 'success'
                    })
                    this.loading = false
                    this.removedDefaultMixersIds = []
                    this.getVehicleSchedules()
                    this.setEditView()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })

            this.resetPageInTable()
        },
        getDriverShortName(id) {
            if (id) {
                let name = this.getDriverName(id)
                if (name) {
                    name = this.formatName(name)
                }
                return name
            } else {
                return '-'
            }
        },
        openMapViewDialog() {
            this.mapViewDialog = true
            this.getMapInfo()
        },
        getMapInfo() {
            let vehicles = this.getAllTableData()
            httpService().post(this.api.dispatch.mixers.getMapInfo, {
                account_modules_id: this.$route.params.id,
                module_key: 'dispatch',
                vehicle_ids: vehicles.map(el => el.vehicle_id),
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.vehicles = data.vehicles
                    this.coords = [parseFloat(data.latitude), parseFloat(data.longitude)]
                    this.mapLoading = false
                }
            })
        },
        getUsers() {
            //TODO можно сделать на беке
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success) {
                    this.users = data.users
                    this.drivers = []
                    let roleKey = this.type === 'mix' ? 'mixDriver' : 'pumpDriver'
                    this.users.filter(el => el.roleKey === roleKey).forEach(user => {
                        let mixer = this.defaultSchedule.find(m => m.driver_id === user.id)

                        this.drivers.push({
                            id: user.id,
                            name: user.name,
                            free: !mixer
                        })
                    })
                }
            })
        },
        setEditView() {
            this.editView = !this.editView
            this.switcher = true
        },
        getMixes() {
            httpService().post(this.api.dispatch.listByAccount, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                const {data} = response.data
                this.mixes = data
            })
        },
        closeCreateMixerDrawer(mixer) {
            this.createMixerDrawer = false
            this.values.push(mixer)
        },
        changeDate(date) {
            this.filters['filter[date]'] = moment(date).format('YYYY-MM-DD')
            this.getVehicleSchedules()
        },
        toggleSwitch(value) {
            this.switcher = value;
            this.resetPageInTable()
        },
        errorsMessage(type) {
            if (type === 'errorSE') {
                this.$message({
                    message: 'Время начала и конца не должны быть одинаковыми',
                    showClose: true,
                    type: 'error'
                })
            }

            if (type === 'errorNo') {
                this.$message({
                    message: 'Время должно быть указанно',
                    showClose: true,
                    type: 'error'
                })
            }
        },
        getAllTableData() {
            let tableData;

            if (this.switcher) {
                tableData = this.editView
                    ? this.schedule
                    : (this.countSchedule
                        ? this.schedule.filter(el => el.active)
                        : this.defaultSchedule.filter(el => !el.rent));
            } else {
                tableData = this.editView
                    ? this.defaultSchedule
                    : this.defaultSchedule.filter(el => !el.rent);
            }

            this.tableDataLength = tableData.length;
            return tableData;
        },
        getTableData() {
            return this.getAllTableData().slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        resetPageInTable() {
            this.$refs.tablePagination.resetPage();
            this.page = 1;
        },
        calculateColumnWidth(names, minWidth = 0, maxWidth) {
            const padding = 24 + (this.editView ? 21 : 0); // 21 для иконки редактирования
            const stringWidth = this.findLongestStringWidth(names) + padding;

            if(maxWidth) {
                return (stringWidth < minWidth) ? minWidth : (stringWidth > maxWidth) ? maxWidth : stringWidth;
            }
            return (stringWidth < minWidth) ? minWidth : stringWidth;
        },
    },
    watch: {
        $route() {
            this.editView = false
            this.createMixerDrawer = false
            this.changeDriverDialog = false
            this.changeMixesDialog = false
            this.disableEdit = true
            this.mixers = []
            this.mixes = []
            this.users = []
            this.driverMixer = []
            this.drivers = []
            this.driverMixer = null
            this.getUsers()
            this.getVehicleSchedules()
            if (!this.mixes.length) this.getMixes()
        },
    },
}
</script>

<style scoped lang="scss">
@use "@/sass/_variables.scss";

.button-LG button {
    height: 44px !important;
    padding: 12px 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.dispatchMixersTableHead {
    &:deep(.switcherBlock) {
        border: none;
    }
}

.switcher {
    background: #F9F9F9;
    border-radius: 12px;

    div {
        padding: 12px 24px;
        border: 1.5px solid transparent;
        cursor: pointer;

        &.active {
            color: variables.$primary600;
            border-color: variables.$neutral50;
            background-color: white;
            border-radius: 12px;
        }
    }
}

.el-form-item {
    margin-bottom: 0 !important;
}
</style>
