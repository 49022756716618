<template>
    <Guest>
        <div class="card register">
            <el-row>
                <el-col :md="12">

                    <div id="logo">
                        <router-link to="/">
                            <LogoLight/>
                        </router-link>
                    </div>
                    <div class="auth-block">
                        <h1>Заявка на регистрацию</h1>
                        <p class="reg">Для регистрации аккаунта Вам необходимо заполнить данные о Вашей компании.</p>
                        <p class="reg">После чего с вами свяжется персональный менеджер от сервиса и поможет всё настроить.</p>
                        <el-link type="primary" href="/login">У меня уже есть аккаунт</el-link>
                    </div>
                </el-col>
                <el-col :md="12">
                    <div class="reg-steps">
                        <el-steps :active="active" class="mb-10">
                            <el-step title="Шаг 1" description="Пользователь"></el-step>
                            <el-step title="Шаг 2" description="Компания"></el-step>
                            <el-step title="Шаг 3" description="Пароль"></el-step>
                        </el-steps>
                        <el-form :model="regForm" method="post" id="login-form"
                                 label-position="top"
                                 v-loading="loading">

                            <div v-if="active===1">
                                <h2>Укажите свои контактные данные</h2>

                                <FormInput
                                    v-model="regForm.name"
                                    label="Фамилия Имя Отчество"
                                    placeholder="Например: Иванов Иван Иванович"
                                />

                                <FormInput
                                    v-model="regForm.email"
                                    label="Ваш E-mail"
                                    placeholder="Например: ivanov@company.ru"
                                />

                                <FormInput
                                    v-model="regForm.phone"
                                    label="Контактный телефон"
                                    placeholder="Например: +7 999 999-99-99"
                                    inputMask="+7 ### ###-##-##"
                                />

                            </div>


                            <div v-if="active===2">
                                <h2>Укажите данные своей компании</h2>
                                <FormSelect
                                    v-model="regForm.inn"
                                    label="ИНН"
                                    placeholder="ИНН вашей компании"
                                    :items="innItems"
                                    :label-key="'name'"
                                    :value-key="'key'"
                                    ref="innSelector"
                                    :is-remote="true"
                                    :remote-method="findInn"
                                    :popper-class="{'register-inn-selector-popper': true, 'inn-selector-popper__hide': Object.entries(innItems).length === 0}"
                                />


                                <FormInput
                                    v-model="regForm.company"
                                    label="Название"
                                    placeholder="Название компании"
                                />

                            </div>

                            <div v-if="active===3">
                                <h2>Задайте пароль</h2>

                                <FormInput
                                    v-model="regForm.password"
                                    label="Пароль"
                                    type="password"
                                    placeholder="Введите пароль"
                                />

                                <FormInput
                                    v-model="regForm.password_confirmation"
                                    label="Подтвердите пароль"
                                    type="password"
                                    placeholder="Введите пароль еще раз"
                                />

                            </div>


                        </el-form>

                        <el-button v-if="active!==3" class="w-100 guest-btn" @click="doRegister">Продолжить</el-button>
                        <el-button v-if="active===3" :disabled="loading" class="w-100 guest-btn" @click="doRegister">
                            Зарегистрироваться
                        </el-button>
                    </div>
                </el-col>
            </el-row>


        </div>
    </Guest>
</template>

<script>
import router from '../router'
import Guest from './layouts/Guest'
import LogoLight from './components/Logo/DGLightLogo'
import FormInput from '@/views/components/Form/FormInput'
import {httpService} from '@/services/http.service'
import FormSelect from "@/views/components/Form/FormSelect.vue";
import functionsMixin from "@/mixins/functionsMixin";
import {useCommonStore} from "@/store/common";
import {authService} from "@/services/auth.service";

export default {
    name: 'Register',
    components: {
        FormSelect,
        FormInput,
        Guest,
        LogoLight
    },
    data() {
        return {
            active: 1,
            loading: false,
            innItems: [],
            root: document.querySelector(":root"),
            regForm: {
                inn: null,
                name: null,
                phone: null,
                email: null,
                password: null,
                password_confirmation: null,
                company: null,
            }
        }
    },
    mixins: [functionsMixin],
    inject: [
        'api'
    ],
    methods: {
        doRegister() {
            this.$root.loadingState()
            this.loading = true

            httpService().post(this.api.account.register, {
                regForm: this.regForm,
                step: this.active
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    if (this.active === 3) {
                        this.$message({
                            message: data.message,
                            type: 'success'
                        });

                        const commonStore = useCommonStore();
                        commonStore.setToken(response.data.accessToken);
                        commonStore.setUser(response.data.user);
                        authService().getMe();
                        commonStore.pullGoods();
                        commonStore.pullCompanies();
                        router.push('/setup');
                    }
                    if (this.active++ > 2) this.active = 0
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error'
                })
            }).finally(() => {
                this.$root.loadingState(false)
                this.loading = false
            })
        },
        on_click(e) {
            this.active = e
        },
    },
    watch: {
        'regForm.inn': {
            handler: function (newValue) {
                if (!this.innCompanyNamesPerInnValue) {
                    return null;
                }
                this.regForm.company = this.innCompanyNamesPerInnValue[newValue];
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style>
.inn-selector-popper__hide {
    display: none !important;
}
</style>
