<template>
    <Auth title="Добавление автоматизации" :reloadNav="reloadNav">
        <div class="card create-module">
            <div class="card-body">
                <el-form ref="form" :model="selectRelation" size="small" label-position="top">
                    <el-form-item class="modulesList">

                        <h3>1. Выберите тип автоматизации:</h3>
                        <el-radio class="relationRadio" v-model="selectRelation.Relation" @change="clearSelectRelation"
                                  v-for="(relation, index) in relations" :key="index"
                                  :label="relation" border>
                            <div class="icon" v-if="relation.name === 'Весовой терминал - Контракты'">
                                <WeighingIcon/>
                                <DArrowRight/>
                                <ContractIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'Весовой терминал - Склад'">
                                <WeighingIcon/>
                                <DArrowRight/>
                                <StorehouseIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'Бетонный завод - Контракты'">
                                <MixIcon/>
                                <DArrowRight/>
                                <ContractIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'Бетонный завод - Складской учёт'">
                                <MixIcon/>
                                <DArrowRight/>
                                <StorehouseIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'Диспетчеризация - Денежный учет'">
                                <DispatchIcon/>
                                <DArrowRight/>
                                <CashboxIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'Диспетчеризация - Бетонный завод'">
                                <DispatchIcon/>
                                <DArrowRight/>
                                <MixIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'Диспетчеризация - CRM'">
                                <DispatchIcon/>
                                <DArrowRight/>
                                <CRMIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'Диспетчеризация - Лаборатория'">
                                <DispatchIcon/>
                                <DArrowRight/>
                                <LabIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'CRM - Бетонный завод'">
                                <CRMIcon/>
                                <DArrowRight/>
                                <MixIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'CRM - Лаборатория'">
                                <CRMIcon/>
                                <DArrowRight/>
                                <LabIcon/>
                            </div>
                            <div class="icon" v-if="relation.name === 'Лаборатория - Бетонный завод'">
                                <LabIcon/>
                                <DArrowRight/>
                                <MixIcon/>
                            </div>
                            <span class="title">{{ relation.name }}</span>
                            <span class="description">{{ relation.description }}</span>
                        </el-radio>
                    </el-form-item>

                    <el-form-item v-if="selectRelation.Relation">
                        <h3 class="w-100">2. Выберите модуль, который будет отправлять данные данные:</h3>
                        <el-radio-group class="inlineRadio" v-model="selectRelation.senderModule"
                                        @change="clearSelectModule" size="medium">
                            <el-radio v-for="(module, index) in selectRelation.Relation.sender_modules" :key="index"
                                      :label="module.id" border>{{ module.name }}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item v-if="selectRelation.Relation && selectRelation.senderModule">
                        <h3 class="w-100">3. Выберите модуль, который будет получать данные данные:</h3>
                        <el-radio-group class="inlineRadio" v-model="selectRelation.recipientModule"
                                        @change="clearSelectModule" size="medium">
                            <el-radio v-for="(module, index) in selectRelation.Relation.recipient_modules" :key="index"
                                      :label="module.id" border>{{ module.name }}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>

                </el-form>
                <div class="footer">
                    <el-button :loading="loadingButton"
                               :disabled="!selectRelation.senderModule && !selectRelation.recipientModule"
                               type="primary" :icon="icons.plus"
                               @click.prevent="doCreateRelation">Создать автоматизацию
                    </el-button>
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import WeighingIcon from '@/views/components/Icons/WeighingIcon'
import ContractIcon from '@/views/components/Icons/ContractIcon'
import { DArrowRight } from '@element-plus/icons-vue'
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import StorehouseIcon from "@/views/components/Icons/StorehouseIcon";
import MixIcon from "@/views/components/Icons/MixIcon.vue";
import DispatchIcon from "@/views/components/Icons/DispatchIcon.vue";
import CashboxIcon from "@/views/components/Icons/CashboxIcon.vue";
import CRMIcon from "@/views/components/Icons/CRMIcon.vue";
import LabIcon from "@/views/components/Icons/LabIcon.vue";

export default {
    name: 'RelationCreate',
    data() {
        return {
            loading: true,
            loadingButton: true,
            relations: [],
            selectRelation: {
                Relation: null,
                senderModule: null,
                recipientModule: null,
                settings: {
                    objectId: null
                },
            }
        }
    },
    components: {
        LabIcon,
        CRMIcon,
        CashboxIcon,
        DispatchIcon,
        MixIcon,
        StorehouseIcon,
        DArrowRight,
        ContractIcon,
        WeighingIcon,
        Auth
    },
    inject: ['api'],
    mixins: [iconsMixin],
    created() {
        httpService().post(this.api.settings.getAllRelations).then((response) => {
            let data = response.data

            if (data.success)
                this.relations = data.relations
            this.loadingButton = false

        })
    },
    methods: {
        doCreateRelation() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.settings.createRelation, {
                relation: this.selectRelation,
            }).then((response) => {
                let data = response.data

                if (data.success) {

                    this.$message({
                        message: 'Автоматизация создана',
                        showClose: true,
                        type: 'success'
                    })
                    this.selectRelation = {
                        Relation: null,
                        senderModule: null,
                        recipientModule: null,
                        settings: {
                            objectId: null
                        },
                    }
                    this.$router.push({name: 'RelationEdit', params: { id: data.id }})
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loading = false
                this.loadingButton = false
            })
        },
        clearSelectRelation() {
            this.selectRelation.senderModule = null
            this.selectRelation.recipientModule = null
            this.selectRelation.settings = {
                objectId: null
            }
        },
        clearSelectModule() {
            this.selectRelation.settings = {
                objectId: null
            }
        },
        checkRelationsForModule(Relations) {
            let check = 0
            Relations.forEach(element => {
                if (element.Relation_id === this.selectRelation.Relation.id) {
                    check = 1
                }
            })

            return check === 1
        }
    }
}
</script>


<style scoped>

</style>
