<template>
    <el-form-item class="multipleChoiceFormSelect" append-to-body :label="label" :required="required">
      <div class="w-100"
        @mousedown.stop>
        <el-select-v2 ref="multipleChoiceFormSelect"
          v-model="inputValue"
          :options="options"
          :props="{ label: 'name', value: 'id' }"
          :placeholder="placeholder"
          :teleported="false"
          @change="handle"
          filterable
          multiple
          clearable
        >
        </el-select-v2>
      </div>
      <div class="formInput__validation-message" v-if="validationMessage">
        {{ validationMessage }}
      </div>
    </el-form-item>
  </template>

<script>
export default {
    name: 'MultipleChoiceFormSelect',
    props: {
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
        },
        validationMessage: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
        },
        modelValue: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            inputValue: this.modelValue,
        }
    },
    watch: {
        inputValue: {
            handler: function (value) {
                this.$emit('update:modelValue', value)
            },
            immediate: true

        },
        modelValue: {
            handler: function (value) {
                this.inputValue = value
            },
            immediate: true
        }
    },
    methods: {
        handle() {
            this.$refs.multipleChoiceFormSelect.dropdownMenuVisible = false
        }
    }
}

</script>


<style scoped lang="scss">
@use "@/sass/_variables.scss";

.multipleChoiceFormSelect {
    flex-direction: column;
    width: 100%;
    margin-bottom: 0 !important;

    &:deep(.el-form-item__label) {
        align-self: flex-start;
        line-height: 12px !important;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    &:deep(.el-select__wrapper) {
        padding: 10px;
        height: fit-content !important;
        background: variables.$neutral30;
        border: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        box-shadow: none !important;
        border-radius: 8px;

        .el-tag {
            background: variables.$neutral50;
            color: variables.$neutral400;
        }
    }

    &:deep(.el-select__selection) {
        margin-left: 0 !important;
    }
}
</style>

