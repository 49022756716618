<template>
    <div class="gantt-chart" ref="gantt">
        <!-- Основное тело диаграммы -->
         <div class="current-time-container" ref="currentTime">
            <div class="current-time-indicator" :style="currentTimeStyle"></div>
         </div>
        <div class="gantt-body" @click="isPage ? clickBody($event) : null">
            <div class="gantt-body__time-header" ref="timeHeader">
                <div class="gantt-body__time-header-content">
                    <slot name="header-buttons"></slot>
                </div>
                <div class="stickyElement" ref="stickyElement" :class="{ sticky: isSticky }">
                    <SelfGanttTimeHeader :timeSlots="timeSlots" />
                </div>
            </div>
            <div v-for="(group) in ganttData" :key="group.id" class="gantt-group">
                <div class="group-header">
                    <div class="group-header__name">
                        <MixIcon :current-color="true" width="16" height="16" />
                        <p>{{ group.mix_name }} - {{group.mixer_name}}</p>
                    </div>
                    <div class="group-header__mix-tasks">
                        <div class="group-header__mix-tasks-ticks">
                            <div v-for="time in timeSlots" :key="time" class="time-slot">
                                <div class="tick" v-for="i in 5" :key="i"></div>
                            </div>
                        </div>
                        <el-tooltip :content="`Загрузка завода - ${mixTask.total} м³`" :show-after="200" :hide-after="100"
                                    v-for="mixTask in group.mixTasks" :key="mixTask.id">
                            <div
                                class="gantt-task-top"
                                :style="getTaskStyle(mixTask, false)"
                            >
                                {{ mixTask.total }}
                            </div>
                        </el-tooltip>
                    </div>
                </div>

                <div class="group-rows">
                    <div class="group-rows__labels">
                        <SelfGanttLabel :type="type" v-for="row in this.type === 'perMix' ? group.rows : group.gantTypeTwo" :key="row.id" :row="row"/>
                    </div>
                    <div class="group-rows__rows">
                        <!-- Отображение строк для каждой группы -->
                        <div class="d-flex f-direction-column">
                                <div v-for="row in this.type === 'perMix' ? group.rows : group.gantTypeTwo" :key="row.id" :class="['gantt-row', {'gantt-row--second': isAllMixes}]">
                                    <div class="row-tasks">
                                        <!-- Добавляем временные слоты для каждой строки -->
                                        <div
                                            v-for="time in timeSlots"
                                            :key="time"
                                            class="time-cell">
                                        </div>
                                        <!-- Отображение задач для каждой строки -->
                                         <SelfGanttTask
                                            v-for="task in row.tasks" :key="task.id"
                                            @click.stop="isPage ? choseTask(task) : null"
                                            :current-task="currentTask ?? null"
                                            :task="task" :from="from" :to="to" :tz="timezone"/>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment-timezone";
import functionsMixin from "./../../mixins/functionsMixin";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import SelfGanttTask from "./SelfGanttTask.vue";
import SelfGanttLabel from "./SelfGanttLabel.vue";
import SelfGanttTimeHeader from "./SelfGanttTimeHeader.vue";
import MixIcon from '@/views/components/Icons/MixIcon.vue';

export default {
    name: "SelfGantt",
    // TODO оптимизировать from/to/timezone, объединить и сократить кол-во пропсов
    mixins: [functionsMixin],
    components: {SelfGanttTask, SelfGanttLabel, SelfGanttTimeHeader, MixIcon},
    props: {
        from: String,
        to: String,
        ganttData: Array,
        timezone: {
            type: String,
            default: 'UTC' // Часовой пояс по умолчанию
        },
        type: String,
    },
    data: () => ({
        currentTask: null,
        isSticky: false,
        originalOffsetTop: 0,
        isPage: false,
        now: null,
        timer: null
    }),
    created() {
        this.$nextTick(() => {
            this.isPage = this.$route.name === 'DispatchGantt'

            if(this.isPage) {
                window.addEventListener('scroll', this.handleScroll);
                window.addEventListener('resize', this.handleResize);
            }
        })
    },
    mounted() {
        this.now = moment.tz(this.timezone);
        this.timer = setInterval(() => {
          this.now = moment.tz(this.timezone);
        }, 60000);
    },
    beforeUnmount() {
        if(this.isPage) {
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('resize', this.handleResize);
        }

        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            orderGroups: 'getOrderGroups',
        }),
        timeSlots() {
            // Рассчитаем временные интервалы между «от» и «до»
            const slots = [];
            const start = moment.tz(this.from, 'YYYY-MM-DD HH:mm', this.timezone);
            const end = moment.tz(this.to, 'YYYY-MM-DD HH:mm', this.timezone);
            while (start.isBefore(end)) {
                slots.push(start.format('HH:mm'));
                start.add(1, 'hour');
            }
            return slots;
        },
        currentTimeStyle() {
            // Рассчитать положение индикатора текущего времени
            const now = this.now ?? moment.tz(this.timezone);
            const start = moment.tz(this.from, 'YYYY-MM-DD HH:mm', this.timezone);
            const end = moment.tz(this.to, 'YYYY-MM-DD HH:mm', this.timezone);

            const totalMinutes = end.diff(start, 'minutes');
            const currentMinutes = now.diff(start, 'minutes');

            return {left: `${(currentMinutes / totalMinutes) * 100}%`};
        },
        isAllMixes() {
            return this.type === 'allMixes'
        },
    },
    methods: {
        getTaskStyle(task) {
            const start = moment.tz(this.from, 'YYYY-MM-DD HH:mm', this.timezone);
            const end = moment.tz(this.to, 'YYYY-MM-DD HH:mm', this.timezone);
            // Время начала и окончания задачи
            const taskStart = moment.tz(task.start, 'YYYY-MM-DD HH:mm', this.timezone);
            const taskEnd = moment.tz(task.end, 'YYYY-MM-DD HH:mm', this.timezone);
            // Всего минут в диапазоне времени на диаграмме
            const totalMinutes = end.diff(start, 'minutes');
            // Длительность задачи в минутах
            const taskDurationMinutes = taskEnd.diff(taskStart, 'minutes');
            // Смещение задачи от начала временного диапазона
            const offsetMinutes = taskStart.diff(start, 'minutes');
            // Вычисляем стиль для правильного отображения задачи

            return {
                left: `${(offsetMinutes / totalMinutes) * 100}%`,
                width: `${(taskDurationMinutes / totalMinutes) * 100}%`,
            };
        },
        getRows(group) {
            return this.type === 'perMix' ? group.rows : group.gantTypeTwo
        },
        clickBody(e) {
            e.stopPropagation();
            this.currentTask = null
        },
        choseTask(task) {
            if(this.currentTask?.order_group_id == task.order_group_id) {
                const orderGroup = this.orderGroups.find(orderGroup => orderGroup.id == task.order_group_id)
                this.$emit('openOrder', task.order_id, orderGroup)
            }
            this.currentTask = task
        },
        handleScroll() {
            if(this.originalOffsetTop === 0) {
                const stickyElement = this.$refs.stickyElement;
                const timeHeader = this.$refs.timeHeader;

                this.originalOffsetTop = stickyElement?.getBoundingClientRect().top;
                stickyElement.style.height = `${stickyElement.getBoundingClientRect().height}px`
                timeHeader.style.height = `${stickyElement.getBoundingClientRect().height}px`

                this.handleResize()
            }

            // Проверяем, касается ли элемент верхней части окна
            this.isSticky = window.scrollY - 2 > this.originalOffsetTop;
        },
        handleResize() {
            // обновляем ширину скролла при смене ширины экрана при ресайзе
            const stickyElement = this.$refs.stickyElement;
            const currentTime = this.$refs.currentTime;
            stickyElement.style.width = `${currentTime.getBoundingClientRect().width + 1}px`
        }
    },
    watch: {
        'orderGroups': {
            handler(val, prevVal) {
                if (val !== prevVal) {
                    this.currentTask = null
                }
            }
        }
    }
}
</script>
<style scoped lang="scss">
@use "@/sass/_variables.scss";

  .sticky {
    position: fixed;
    top: 0;
    right: 25px;
    z-index: 7;

    @media screen and (max-width: 1024px) {
        right: 12px;
    }
  }


.gantt-chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}


.time-slot {
    position: relative;
    flex: 1;
    text-align: center;
    border-right: 1px solid #efefef;
    font-size: 12px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    height: 32px;

    &:hover {
        background-color: variables.$neutral30;
    }

    &__value {
        color: variables.$neutral100;
        font-size: 10px;
        margin: 0;
        width: fit-content;
        rotate: 300deg;
        cursor: context-menu;
    }
}

/* Добавляем засечки каждые 10 минут под каждым часовым интервалом */
.time-slot .tick {
    position: absolute;
    bottom: 0;
    width: 1px; /* Ширина засечки */
    height: 0; /* Высота засечки */
    background-color: variables.$light-border-color;
}

/* Распределяем засечки по всей ширине временной ячейки */
.time-slot .tick:nth-child(1) {
    left: 10%;
}

.time-slot .tick:nth-child(2) {
    left: 30%;
}

.time-slot .tick:nth-child(3) {
    left: 50%;
    height: 6px;
}

.time-slot .tick:nth-child(4) {
    left: 70%;
}

.time-slot .tick:nth-child(5) {
    left: 90%;
}

.gantt-body {
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid variables.$light-border-color;

    &__time-header {
        display: grid;
        grid-template-columns: 225px 1fr;
        gap: 4px;
    }
}

.gantt-group {
    display: flex;
    flex-direction: column;
}

.group-header {
    background-color: variables.$neutral30;
    color: variables.$neutral900;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    position: relative;
    height: 32px;
    display: grid;
    gap: 5px;
    grid-template-columns: 225px 1fr;
    border-bottom: 1px solid variables.$light-border-color;

    &__name {
        margin-block: auto;
        padding-left: 12px;
        display: flex;
        gap: 7px;
        align-items: center;

        p {
            color: variables.$neutral700;
            margin: 0;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
        }
    }

    &__mix-tasks {
        position: relative;
        overflow-x: clip;
    }

    &__mix-tasks-ticks {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        display: flex;
    }
}

.group-rows {
    display: grid;
    gap: 5px;
    grid-template-columns: 225px 1fr;


    &__labels {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        justify-items: center;
        background: #fcfcfc;
    }

    &__rows {
        position: relative;
        display: flex;
        flex-direction: column;

        .gantt-row:last-child {
            border-bottom: none;
        }
    }
}

.gantt-row {
    display: flex;
    align-items: center;
    //height: 50px;
    height: 42px;
    border-bottom: 1px solid variables.$light-border-color;
    position: relative;
    overflow: hidden;

    &--second {
        height: 42px;
    }
}

.row-tasks {
    position: relative;
    flex: 1;
    display: flex;
}

.gantt-task-top {
    position: absolute;
    height: 31px;
    color: variables.$neutral700;
    font-size: 12px;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: variables.$neutral100;
    transform: translate(0, -50%);
    top: 50%;
    border-radius: 2px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &:after {
        content: '';
        width: 1px;
        height: 100%;
        background: variables.$neutral50;
        position: absolute;
        right: -1px;
        top: 0;
    }

    &:hover {
        transition: all 0.3s ease;
        z-index: 3;
        background-color:  variables.$neutral200;
    }
}

.current-time-container {
    width: calc(100% - 230px);
    position: absolute;
    right: 0;
    height: 100%;
    overflow: hidden;
}

.current-time-indicator {
    position: absolute;
    height: 100%;
    width: 1.5px;
    background-color: variables.$primary600;
    top: 0;
    z-index: 8;
}

.time-cell {
    flex: 1;
    border-right: 1px solid variables.$light-border-color; /* Линии между временными интервалами */
    //height: 50px; /* Высота клетки */
    height: 42px; /* Высота клетки */
    position: relative;

    &--second {
        height: 42px;
    }

    &:after {
        content: '';
        position: absolute;
        width: 1px;
        background: variables.$light-border-color;
        top: 0;
        left: 50%;
        height: 100%;
    }
}
</style>
