<template>
    <div class="mt-10" style="min-height: 42px">
        <el-row class="tablePagination" gutter="20">
            <el-col :sm="4">
                <el-select class="tablePagination__select" v-model="itemSelect" v-if="!disableSize">
                    <el-option
                        v-for="(size, index) in pageSizes"
                        :key="index"
                        :label="size"
                        :value="size">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :sm="20">
                <el-pagination
                    class="tablePagination__pagination"
                    layout="prev, pager, next"
                    :page-size="itemSelect"
                    :disabled="loading"
                    :total="totalValues"
                    :current-page="page"
                    :hide-on-single-page="true"
                    @current-change="setPage">
                </el-pagination>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import tableMixin from '../../../mixins/tableMixin'

export default {
    name: "TablePagination",
    props: [
        'modelValue',
        'total',
        'loading',
        'disableSize',
        'currentPage'
    ],
    mixins: [
        tableMixin
    ],
    data() {
        return {
            itemSelect: this.modelValue,
            totalValues: this.total,
        }
    },
    created() {
        if(this.currentPage) {
            this.page = this.currentPage
        }
    },
    methods: {
        resetPage() {
            this.page = 1;
            this.$emit('action', this.page);
        },
    },
    watch: {
        'itemSelect': {
            handler: function () {
                this.$emit('update:modelValue', this.itemSelect)
            },
            deep: true,
            immediate: true
        },
        'total': {
            handler: function () {
                this.totalValues = this.total
            },
            deep: true,
            immediate: true
        },
        'page': {
            handler: function () {
                this.$emit('action', this.page)
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
