<template>
    <div v-if="user.id === 1">
        <div class="p-3" style="display: flex;color: #0B0D17;background-color: #f1e6df;align-items: center;">
            <div class="mr-10 p-3 br-10" style="cursor: pointer;border: 1px solid black;background-color: #1984e6;color:white" @click="getSchedules()">Получить</div>
            <div class="mr-5">Из Json</div>
            <el-checkbox style="margin-right: 10px" v-model="fromJson"/>
            <div v-if="!fromJson" class="mr-10 ml-5">
                <el-input v-model="day" type="date"/>
            </div>
            <div v-else style="width: 100px" class="mr-10 ml-5">
                <el-select
                    v-model="jsonName"
                    placeholder="Тип компании"
                >
                    <el-option
                        v-for="(name, index) in jsonFiles"
                        :key="index"
                        :label="name"
                        :value="name"
                    />
                </el-select>
            </div>
            <div style="width: 30px">Фон</div>
            <el-checkbox @change="changeBackgroundColor" class="mr-10" v-model="changeColor"/>
            <div v-if="data.length !== 0" class="mr-20">Отгрузок: {{data.countOrders}}</div>
            <div v-if="data.length !== 0" class="mr-20">Не довезли: {{data.metrics.undelivered_volume }}</div>
            <div v-if="data.length !== 0" class="mr-20">Время идеальной машины: {{data.data.accountSettings.algorithm_time_suitable_vehicle}}</div>
        </div>
        <div v-if="loader" class="w-100 text-center pt-30 pb-30" style="min-height: 700px">
            <ReportLoader/>

            <h3>Загрузка данных о заказе</h3>
            <p class="textXS textRegular neutral300">Пожалуйста, подождите...</p>
        </div>
        <div v-else>
            <SelfGantt
                v-if="loading"
                :from="data.orders.dateFrom"
                :to="data.orders.dateTo"
                :gantt-data="data.orders.groups"
                :timezone="data.orders.timezone"
                type="perMix"
            />
        </div>
    </div>
</template>
<script>
import SelfGantt from "@/views/components/SelfGantt";
import {httpService} from "@/services/http.service";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import moment from "moment/moment";
import {mapState} from 'pinia';
import {useCommonStore} from "@/store/common";

//import moment from "moment";
export default {
    name: "TestPage",
    components: {
        SelfGantt,
        ReportLoader,
    },
    inject: ['api'],
    mounted() {
        console.log('TestPage');
    },

    computed: {
        ...mapState(useCommonStore, {
            user: 'getUser',
        }),
    },
    created() {
        this.getFiles()
    },
    data() {
        return {
            fromJson: false,
            jsonName: '1.json',
            loading: false,
            day: moment(),
            loader: false,
            changeColor: false,
            data: [],
            jsonFiles: [],
        };
    },
    methods: {
        getSchedules() {
            this.loading = false
            this.loader = true
            httpService().post(this.api.test.getTestData, {
                fromJson: this.fromJson,
                fileName: this.jsonName,
                day: this.day,
            }).then(({data}) => {
                if (data.success) {
                    this.data = data
                }
                this.loading = true
                this.loader = false
            })
        },
        getFiles() {
            httpService().post(this.api.test.getJsonFiles)
                .then(({data}) => {
                    if (data.success) {
                        this.jsonFiles = data.data
                    }
                })
        },
        changeBackgroundColor() {
            if (this.changeColor) {
                document.body.style.backgroundColor = '#0d0d0d';
            } else {
                document.body.style.backgroundColor = 'white';
            }
        },
    }
}
</script>

<style scoped>

</style>
