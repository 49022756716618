<template>
  <el-button
    :icon="icons.circlePlus"
    type="text"
    plain
    class="crm-contracts__add-row-btn textMedium primary600 w-fit"
    @click.prevent="addNewRow"
  >
    {{ addRowButtonText }}
  </el-button>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin';

export default {
  name: 'CRMContractsAddRowButton',
  mixins: [iconsMixin],
  props: {
    tab: {
      type: String,
      required: true
    },
  },
  computed: {
    addRowButtonText() {
      let text = 'Прикрепить ';
      switch (this.tab) {
        case 'document_goods':
          text += 'продукцию';
          break;
        case 'addresses':
          text += 'адрес';
          break;
        default:
          text += 'тариф';
      }
      return text;
    }
  },
  methods: {
    addNewRow() {
      this.$emit('add-new-row');
    }
  }
};
</script>

<style lang="scss">
@use "@/sass/_variables.scss";

.crm-contracts {
  &__add-row-btn {
    padding: 12px 24px !important;
    height: 44px !important;
    box-shadow: none;
    border-radius: 8px !important;
    width: fit-content;
    font-family: 'Ubuntu';

    background: variables.$primary50 !important;

    span {
      margin-left: 12px !important;
    }

    .el-icon, svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
}
</style>
