<template>
    <template v-if="step === 1">
        <hr class="mt-0 mb-10">
        <p class="textSM textMedium neutral900 mt-0 mb-12">Выберите документы для печати:</p>
        <div class="d-flex documentPrints">
            <el-checkbox-group v-model="selectedDocs">
                <el-checkbox :disabled="!orders.filter(el => el.confirm).length" label="passport" value="passport">
                    <template #default>
                        <div class="document text-center">
                            <img src="/images/docs/passport.jpg"/>
                            <p class="textMedium textSM">Паспорт качества</p>
                        </div>
                    </template>
                </el-checkbox>
                <el-checkbox :disabled="!orders.filter(el => el.confirm).length" label="invoice" value="invoice">
                    <template #default>
                        <div class="document text-center">
                            <img src="/images/docs/invoice.jpg"/>
                            <p class="textMedium textSM">ТН</p>
                        </div>
                    </template>
                </el-checkbox>
                <el-checkbox :disabled="!orders.filter(el => el.confirm).length" label="ttn" value="ttn">
                    <template #default>
                        <div class="document text-center">
                            <img src="/images/docs/TTN.png"/>
                            <p class="textMedium textSM">ТТН</p>
                        </div>
                    </template>
                </el-checkbox>
                <el-checkbox :disabled="!orders.filter(el => el.confirm).length" :label="doc['id']" :value="doc['id']"
                            v-for="(doc, index) in accessDocuments" :key="index">
                            <template #default>
                                <div class="document text-center">
                                    <img :src="doc['image_path']" alt="Документ"/>
                                    <p class="textMedium textSM">{{doc['name']}}</p>
                                </div>
                            </template>
                        </el-checkbox>
            </el-checkbox-group>
        </div>
    </template>
    <template v-else-if="step === 2">
        <hr class="mt-0 mb-10">
        <p class="textSM textMedium neutral900 mt-0 mb-12">Выберите отгрузки для печати:</p>

        <template v-if="orders.filter(el => el.confirm).length">

            <el-checkbox class="w-100" :indeterminate="isIndeterminate" v-model="checkAll"
                         @change="handleCheckAllChange">
                Выбрать все
            </el-checkbox>
            <el-scrollbar height="300px">
                <div style="width: 90%">
                    <el-checkbox-group v-model="selectedOrders" @change="handleCheckedCitiesChange">
                        <el-checkbox
                            class="w-100" :class="{'checkboxOrder--active': order.status === 'loading'}"
                            v-for="order in orders.filter(el => el.confirm)"
                            :label="order.id" :key="order.id">
                            #{{ order.id }} /
                            <el-tooltip content="Номер с АСУ" v-if="order.doc">#{{ order.doc }} / </el-tooltip>
                            {{ getDateFromUnix(order.date, 'HH:mm', tz) }} - <span class="cutOverflow"
                                style="max-width: 200px">{{
                                order.good
                            }}</span> - {{ order.vehicle }} - {{ order.total }} м³ {{ order.status === 'loading' ? '(активная отгрузка)' : '' }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-scrollbar>
        </template>
        <template v-else>
            <p class="mt-30 textSM neutral400 text-center w-100">
                Нет подтвержденных заявок по заказу<br/>Нет возможности распечатать ТТН или Паспорта качества для
                отгрузок
            </p>
        </template>
    </template>

    <div class="add-shipping__footer">
        <el-button
            v-if="step !== 1 && !currentOrder"
            @click="() => step = 1"
            class="ci-btn ci-btn_default add-shipping__footer__cancel">К выбору документов
        </el-button>

        <el-button
            v-if="step === 1 && !currentOrder"
            @click="nextStep()"
            :disabled="!selectedDocs.length"
            class="ci-btn ci-btn_blue"
            type="primary">Далее
        </el-button>

        <template v-if="step === 2 || currentOrder">
            <el-button
                @click="printDocs('download')"
                :disabled="isDownloadButtonDisabled"
                :icon="icons.download"
                class="ci-btn"
                type="default">Скачать
            </el-button>
            <el-button
                @click="printDocs('print')"
                :disabled="isPrintButtonDisabled"
                :icon="icons.print"
                class="ci-btn ci-btn_blue"
                type="primary">Распечатать
            </el-button>
        </template>
    </div>
</template>

<script>
import moment from "moment/moment";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "OperatorPrintDocs",
    computed: {
        moment() {
            return moment
        },
        isPrintButtonDisabled() {
            const {selectedOrders, currentOrder, selectedDocs, loadingButton} = this;
            const isDisabled = selectedDocs.length > 1 || !selectedDocs.length || loadingButton
            if (!currentOrder) {
                return isDisabled && !selectedOrders.length
            }
            return isDisabled
        },
        isDownloadButtonDisabled() {
            const {selectedOrders, currentOrder, loadingButton} = this;
            if (!currentOrder) {
                return loadingButton && !selectedOrders.length
            }
            return loadingButton
        },
    },
    props: ['orders', 'mixId', 'date', 'tz', 'currentOrder', 'accessDocuments'],
    inject: ['api'],
    mixins: [functionsMixin, iconsMixin],
    data() {
        return {
            selectedDocs: [],
            selectedOrders: [],
            step: 1,
            checkAll: false,
            isIndeterminate: false,
            loadingButton: false,
        }
    },
    methods: {
        handleCheckAllChange(val) {
            this.selectedOrders = val ? this.orders.filter(el => el.confirm).map(item => item.id) : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.orders.filter(el => el.confirm).length;
            // this.isIndeterminate = checkedCount > 0 && checkedCount < this.orders.length;
            this.isIndeterminate = false;
        },
        printDocs(type) {
            this.selectedDocs.forEach(template => {
                this.print(template, type)
            })
            this.$emit('close')
        },
        nextStep() {
            this.step = 2;
        },
        print(template, type) {
            this.loadingButton = true
            if (type === 'download') {
                if (template === 'invoicePump' || template === 'passportOrder') {
                    this.download(template)
                    return;
                }
                this.selectedOrders.forEach((item) => {
                    this.download(template, item)
                })
            } else if (type === 'print') {
                httpService().post(this.api.print.printDoc, {
                    order: this.selectedOrders[0],
                    template: template,
                    orderGroupId: this.orderGroupItem?.id
                }, {responseType: 'blob'}).then((response) => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], {type: 'application/pdf'});
                        const url = URL.createObjectURL(blob);

                        const printWindow = window.open(url);

                        printWindow.addEventListener('load', function () {
                            printWindow.print();
                            URL.revokeObjectURL(url);
                        }, {once: true});
                    }
                }).catch(() => {
                    this.$message({
                        message: 'Ошибка ответа от сервера',
                        showClose: true,
                        type: 'error'
                    });
                    this.loadingButton = false;
                });
            }
            this.loadingButton = false
        },
        download(template, item = null) {
            httpService().post(this.api.print.downloadDoc, {
                account_module_reserve_id: this.$route.params.id,
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                dateSelect: moment(),
                order: item,
                template: template
            }, {
                responseType: 'blob'
            }).then((response) => {
                let data = response.data
                const url = window.URL.createObjectURL(response.data)

                const link = document.createElement('a')
                let filename = '№' + this.selectedOrders[item]
                if (template === 'invoice') {
                    filename = 'Транспортная накладная по заявке №' + this.selectedOrders[item]
                } else if (template === 'daily') {
                    filename = 'График заявок'
                } else if (template === 'passport') {
                    filename = 'Паспорт качества по заявке №' + this.selectedOrders[item]
                } else if (template === 'invoicePump') {
                    filename = 'Транспортная накладная по заявке №' + this.selectedOrders[item]
                } else if (template === 'ttn') {
                    filename = 'Товарно-транспортная по заявке №' + this.selectedOrders[item]
                }
                link.href = url

                link.setAttribute('download', filename + '.docx')
                document.body.appendChild(link)
                link.click()
                this.loadingButton = false

                if (data.success) {
                    this.loadingButton = false
                    this.step = 1
                    this.selectedDocs = []
                }
            }).catch((error) => {
                console.log(error)
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        generateWord() {
            for (let i = 0; i < this.selectedOrders.length; i++) {
                    httpService().post(this.api.exportDocument.generateTTN, {
                        orderId: this.selectedOrders[i]
                    }, {
                        responseType: 'blob'
                    }).then((response) => {
                        let fileName = 'Товарно-транспортная накладная для заявки №' + this.selectedOrders[i] + '.docx';
                        const url = window.URL.createObjectURL(response.data)
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', fileName)
                        document.body.appendChild(link)
                        link.click()
                    })
            }
        },
    },
    watch: {
        'currentOrder': {
            handler: function (val) {
                if (val) {
                    this.selectedOrders = [val.id]
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.checkboxOrder--active {
    color: variables.$primary600 !important;
}
</style>
