<template>
    <div class="p-relative" style="margin-bottom: 50px">
        <div class="w-100" style=" position: relative; z-index: 2; top: -11px; ">

            <div style="position: absolute; left: 1px">
                <el-tooltip effect="dark" placement="top" content="Старт загрузки на заводе"
                            :show-after="150">
                    <div>
                        <div
                            class="progressSliderStatusText" :class="{'progressSliderStatusTextActive': !checkApproximateTime('loading')}">
                            З
                        </div>
                        <div class="approximateBlock neutral400 textXS mt-5 p-relative">
                            <div class="approximateTime" v-if="!order.confirm">≈</div>
                            {{ getTime('loading') }}
                        </div>
                    </div>
                </el-tooltip>
            </div>

            <div v-if="order.type === 'delivery'" style="position: absolute" :style="{left: getLeft('delivery') + '%'}">
                <el-tooltip effect="dark" placement="top" content="Старт доставки"
                            :show-after="150">
                    <div>
                        <div
                            class="progressSliderStatusText" :class="{'progressSliderStatusTextActive': !checkApproximateTime('delivery')}">
                            Д
                        </div>
                        <div class="approximateBlock neutral400 textXS mt-5 p-relative">
                            <div class="approximateTime" v-if="checkApproximateTime('delivery')">≈</div>
                            {{ getTime('delivery') }}
                        </div>
                    </div>
                </el-tooltip>
            </div>

            <div v-if="order.type === 'delivery'" style="position: absolute;" :style="{left: getLeft('object') + '%'}">
                <el-tooltip effect="dark" placement="top" content="Разгрузка на объекте"
                            :show-after="150">
                    <div>
                        <div
                            class="progressSliderStatusText" :class="{'progressSliderStatusTextActive': !checkApproximateTime('object')}">
                            О
                        </div>
                        <div class="approximateBlock neutral400 textXS mt-5 p-relative">
                            <div class="approximateTime" v-if="checkApproximateTime('object')">≈</div>
                            {{ getTime('object') }}
                        </div>
                    </div>
                </el-tooltip>
            </div>

            <div v-if="order.type === 'delivery'" style="position: absolute;" :style="{left: getLeft('return') + '%'}">
                <el-tooltip effect="dark" placement="top" content="Возвращение на завод"
                            :show-after="150">
                    <div>
                        <div
                            class="progressSliderStatusText" :class="{'progressSliderStatusTextActive': !checkApproximateTime('return')}">
                            В
                        </div>
                        <div class="approximateBlock neutral400 textXS mt-5 p-relative">
                            <div class="approximateTime" v-if="checkApproximateTime('return')">≈</div>
                            {{ getTime('return') }}
                        </div>
                    </div>
                </el-tooltip>
            </div>

            <div style="position: absolute; right: 1px">
                <el-tooltip effect="dark" placement="top" content="Завершение"
                            :show-after="150">
                    <div>
                        <div
                            class="progressSliderStatusText" :class="{'progressSliderStatusTextActive': !checkApproximateTime('done')}">
                            З
                        </div>
                        <div class="approximateBlock neutral400 textXS mt-5 p-relative">
                            <div class="approximateTime" v-if="checkApproximateTime('done')">≈</div>
                            {{ getTime('done') }}
                        </div>
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="w-100 progressSlider" style="position: absolute; top: 50px;z-index: 3; width: 96%;left: 2%;"
             v-if="pointsData && pointsData.length">
            <div :style="{width: percentage + '%'}">
                <el-slider
                    v-model="checkpointValue"
                    :max="pointsData.length ? pointsData.length - 1 : 100"
                    :show-tooltip="false"
                />
            </div>
        </div>
        <div class="w-100" style="z-index: 1; position: relative; width: 96%;left: 2%;">
            <div>
                <el-progress color="#1b91fd" :show-text="false" :percentage="percentage"/>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";

export default {
    // Таймлайн с управление картой
    name: 'MixPageOrderProgressTimeline',
    props: ['order', 'checkpoint', 'points'],
    components: {},
    mixins: [functionsMixin],
    data() {
        return {
            checkpointValue: 0,
            pointsData: [],
            statuses: [
                {
                    id: 0,
                    key: 'loading',
                    name: 'Загрузка'
                },
                {
                    id: 10,
                    key: 'delivery',
                    name: 'Доставка'
                },
                {
                    id: 20,
                    key: 'object',
                    name: 'На объекте'
                },
                {
                    id: 40,
                    key: 'return',
                    name: 'Возвращается'
                },
                {
                    id: 50,
                    key: 'done',
                    name: 'Конец'
                }
            ],
            filteredStatuses: this.statuses,
            currentId: null,
            currentOrder: this.order,
        }
    },
    computed: {
        percentage() {
            let percent = 0

            if (this.order) {
                let allDurationInMinutes = moment.duration(moment(this.order.return_at).diff(moment(this.order.start_at))).asMinutes();

                // сейчас с учетом таймзоны из паременной moduleTimezone
                let now = moment().tz(this.moduleTimezone);

                if (this.order.status === 'new') {
                    percent = 0;
                } else if (this.order.status === 'loading') {
                    let durationInMinutes = moment.duration(now.diff(moment(this.order.start_at))).asMinutes();
                    percent = (durationInMinutes / allDurationInMinutes) * 100;
                } else if (this.order.status === 'delivery') {
                    let durationInMinutes = moment.duration(now.diff(moment(this.order.start_at))).asMinutes();
                    percent = (durationInMinutes / allDurationInMinutes) * 100;
                } else if (this.order.status === 'object') {
                    let durationInMinutes = moment.duration(now.diff(moment(this.order.start_at))).asMinutes();
                    percent = (durationInMinutes / allDurationInMinutes) * 100;
                } else if (this.order.status === 'return') {
                    let durationInMinutes = moment.duration(now.diff(moment(this.order.start_at))).asMinutes();
                    percent = (durationInMinutes / allDurationInMinutes) * 100;
                } else if (this.order.status === 'done') {
                    percent = 100;
                }
            }

            percent = percent < 100 ? percent : 100

            return percent
        }
    },
    methods: {
        getTime(status) {
            if (status === 'loading') {
                return moment.parseZone(this.order.start_at).format('HH:mm')
            } else if (status === 'delivery') {
                return moment.parseZone(this.order.load_at).format('HH:mm')
            } else if (status === 'object') {
                return moment.parseZone(this.order.arrive_at).format('HH:mm')
            } else if (status === 'return') {
                return moment.parseZone(this.order.unload_at).format('HH:mm')
            } else if (status === 'done') {
                return moment.parseZone(this.order.return_at).format('HH:mm')
            }
        },
        checkApproximateTime(status) {
            if (status === 'loading') {
                if (this.order.status === 'new') {
                    return true
                }
            } else if (status === 'delivery') {
                if (this.order.status === 'new' || this.order.status === 'loading') {
                    return true
                }
            } else if (status === 'object') {
                if (this.order.status === 'new' || this.order.status === 'loading' || this.order.status === 'delivery') {
                    return true
                }
            } else if (status === 'return') {
                if (this.order.status === 'new' || this.order.status === 'loading' || this.order.status === 'delivery' || this.order.status === 'object' || this.order.status === 'unloading') {
                    return true
                }
            } else if (status === 'done') {
                if (this.order.status === 'new' || this.order.status === 'loading' || this.order.status === 'delivery' || this.order.status === 'object' || this.order.status === 'unloading' || this.order.status === 'return') {
                    return true
                }
            }
        },
        getLeft(status) {
            let allDurationInMinutes = moment.duration(moment(this.order.return_at).diff(moment(this.order.start_at))).asMinutes();
            let percent = 0
            if (status === 'delivery') {
                let durationInMinutes = moment.duration(moment(this.order.load_at).diff(moment(this.order.start_at))).asMinutes();
                percent = (durationInMinutes / allDurationInMinutes) * 100;
            } else if (status === 'object') {
                let durationInMinutes = moment.duration(moment(this.order.arrive_at).diff(moment(this.order.start_at))).asMinutes();
                percent = (durationInMinutes / allDurationInMinutes) * 100;
            } else if (status === 'return') {
                let durationInMinutes = moment.duration(moment(this.order.unload_at).diff(moment(this.order.start_at))).asMinutes();
                percent = (durationInMinutes / allDurationInMinutes) * 100;
            }

            percent = percent > 100 ? 100 : percent

            return percent
        }
    },
    watch: {
        'order': {
            handler: function () {
                this.currentOrder = this.order
            },
            deep: true,
            immediate: true
        },
        'checkpoint': {
            handler: function () {
                this.checkpointValue = this.checkpoint
            },
            deep: true,
            immediate: true
        },
        'points': {
            handler: function () {
                this.pointsData = this.points
            },
            deep: true,
            immediate: true
        },
        'checkpointValue': {
            handler: function () {
                this.$emit('update:checkpoint', this.checkpointValue)
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
</style>
