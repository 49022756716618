<template>
    <el-button-group :class="['changeActiveTabButtons',
        {'changeActiveTabButtons--horizontal': direction === 'horizontal', 'changeActiveTabButtons--small': size === 'small'}]">
        <el-button
          v-for="tab in tabs" :key="tab"
            :class="['group-btn', {'group-btn--active': activeTab === tab}]"
            :disabled="loading"
            @click="changeActiveTab(tab)"
        >
          <slot :name="`icon-${tab}`"></slot>
        </el-button>
      </el-button-group>
</template>

<script>
export default {
    name: 'ChangeActiveTabButtons',
    props: ['tabs', 'activeTab', 'loading', 'direction', 'size'],
    methods: {
        changeActiveTab(tab) {
            this.$emit("change-active-tab", tab);
        },
    },
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.changeActiveTabButtons {
    display: flex;
    border-radius: 12px;
    background: variables.$neutral30;
    width: fit-content;
    height: fit-content;

    &--horizontal {
        flex-direction: column;
    }

    &--small {
        border-radius: 8px;

        .group-btn {
            padding: 8px !important;
            height: 32px !important;
            border-radius: 8px !important;

            &:deep(svg) {
                width: 16px !important;;
                height: 16px !important;
            }
        }
    }

    .group-btn {
        padding: 12px;
        border-radius: 12px;
        background: transparent;
        border: 1px solid transparent;
        color: variables.$neutral600;
        transition: all .3 ease;
        box-shadow: none;

        &:active, &:focused {
            border-color: inherit;
        }

        &--active {
            border: 1px solid variables.$neutral50;
            background: variables.$white;
            color: variables.$primary600;
        }
    }

}
</style>
