<template>
    <Auth title="Товары и грузы" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    create-button-label="Добавить товар"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="setCreateDrawerOpen"
                >
                    <template v-slot:afterSearchAndFilter>
                        <el-select
                            v-model="filters.goodType"
                            placeholder="Тип товара"
                            size="large"
                            class="filterSelect"
                            style="width: 180px"
                            @change="moduleGet"
                            clearable
                        >
                            <el-option
                                v-for="item in goodTypes"
                                :key="item.key"
                                :label="item.name"
                                :value="item.key"
                            />
                        </el-select>
                        <TableIntegrationFilter
                            v-model="filters.integrationUnitId"
                            @moduleGet="moduleGet"
                            :table="true"
                        />
                        <el-button
                            v-if="multipleSelection.length > 0"
                            @click="remove(multipleSelection, true)"
                            class="ci-btn ci-btn_white"
                            :icon="icons.delete"
                        />
                    </template>
                    <template v-if="integrations.length" v-slot:buttonsBlock>
                        <el-button class="ci-btn" @click="generateTaskForGetRecipes()"
                            >Запросить с АСУ
                        </el-button>
                    </template>
                </TableHeadBlock>

                <MobileTableFramework
                    v-for="(item, index) in displayData"
                    :key="index"
                >
                    <template v-slot:body>
                        <MobileTableHR />

                        <MobileTableInfo
                            title="Название"
                            :body="item.name ?? '-'"
                        />
                    <MobileTableInfo title="Название">
                        <template v-slot:body>
                            <div class='d-flex gap-8'>
                                <span
                                    :class="
                                        'textMedium textSM ' +
                                        (item?.actual
                                            ? 'neutral900'
                                            : 'neutral400')
                                    "
                                    >{{ item.name ?? "-" }}
                                </span>
                                <Label v-if="!item?.actual" text="В архиве" :bold="true"/>
                            </div>
                        </template>
                    </MobileTableInfo>

                        <MobileTableButton
                            title="Подробнее"
                            @action="
                                $router.push({
                                    name: 'GoodDetails',
                                    params: { id: item.id },
                                })
                            "
                        />
                    </template>
                </MobileTableFramework>

                <el-table
                    empty-text="Информация не найдена"
                    v-loading="loading"
                    v-if="!mobile"
                    :data="values"
                    @selection-change="handleSelectionChange"
                    class="w-100 mt-15"
                >
                    <el-table-column type="selection" width="40" />
                    <el-table-column prop="id" label="#" width="100" sortable>
                        <template v-slot="scope">
                            <template v-if="scope.row.relation_group_id">
                                <el-tooltip
                                    effect="dark"
                                    content="Находится в группе товаров"
                                    placement="top"
                                >
                                    <span class="textSM textMedium neutral300"
                                        >#{{ formatNumber(scope.row.id) }}</span
                                    >
                                </el-tooltip>
                            </template>
                            <template v-else>
                                <span class="textSM textMedium neutral200"
                                    >#{{ formatNumber(scope.row.id) }}</span
                                >
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="Название" sortable>
                        <template v-slot="scope">
                            <div class='d-flex gap-8'>
                                <span
                                    :class="
                                        'textMedium textSM ' +
                                        (scope.row.actual
                                            ? 'neutral900'
                                            : 'neutral400')
                                    "
                                    >{{ scope.row.name ?? "-" }}
                                </span>
                                <Label v-if="!scope.row?.actual" text="В архиве" :bold="true"/>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="Тип товара"
                        width="220px"
                    >
                        <template v-slot="scope">
                            <span class="textRegular textSM neutral200">{{
                                getTypeName(scope.row.type)
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration"
                        label="Интеграция"
                        width="220px"
                    >
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <IntegrationLogo
                                    :integration-unit-id="
                                        scope.row.integration_unit_id
                                    "
                                />
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200"
                                    >-</span
                                >
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="" width="150">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                @click="
                                    $router.push({
                                        name: 'GoodDetails',
                                        params: { id: scope.row.id },
                                    })
                                "
                                >Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />

                <el-drawer
                    v-model="drawerCreate"
                    title=""
                    direction="rtl"
                    :size="drawerSize"
                    :destroy-on-close="true"
                >
                    <GoodsCreateTemplate
                        @moduleGet="moduleGet"
                        @updateGoods="updateGoods"
                        @closeDrawer="closeDrawer"
                        :goods="values"
                        :units="units"
                    />
                </el-drawer>

                <el-dialog
                title="Создание отгрузки"
                :width="mobile ? '100%' : '60%'"
                direction="rtl"
                :destroy-on-close="true">
                <p>Готово</p>

    </el-dialog>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from "../../layouts/Auth";
import GoodsCreateTemplate from "../../components/SettingsPage/GoodsPage/GoodsCreateTemplate";
import mobileCheckMixin from "../../../mixins/mobileCheckMixin";
import tableMixin from "../../../mixins/tableMixin";
import basicMixin from "../../../mixins/basicMixin";
import functionsMixin from "../../../mixins/functionsMixin";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import MobileTableFramework from "@/views/components/Mobile/Table/MobileTableFramework";
import MobileTableHR from "@/views/components/Mobile/Table/MobileTableHR";
import MobileTableInfo from "@/views/components/Mobile/Table/MobileTableInfo";
import MobileTableButton from "@/views/components/Mobile/Table/MobileTableButton";
import TablePagination from "@/views/components/Table/TablePagination";
import { mapActions, mapState } from "pinia";
import { useCommonStore } from "@/store/common";
import { httpService } from "@/services/http.service";
import goodTypes from "@/mixins/goodTypes";
import iconsMixin from "@/mixins/iconsMixin";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";
import Label from "@/views/components/Label/Label.vue";

export default {
    name: "GoodsIndex",
    data() {
        return {
            drawerCreate: false,
            filters: {
                goodType: null,
                integrationUnitId: null,
            },
            tabs: [{
                route: 'goods',
                name: 'Товары'
            }, {
                route: 'GoodsGroupsIndex',
                name: 'Группы'
            }]

        };
    },
    components: {
        TableIntegrationFilter,
        IntegrationLogo,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        GoodsCreateTemplate,
        Auth,
        Label
    },
    inject: ["api"],
    mixins: [
        mobileCheckMixin,
        tableMixin,
        basicMixin,
        functionsMixin,
        goodTypes,
        iconsMixin,
    ],
    created() {
        this.moduleGet();
    },
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
        }),
    },
    methods: {
        ...mapActions(useCommonStore, ['pullGoods']),
        moduleGet() {
            this.loading = true;
            httpService()
                .post(this.api.good.get, {
                    paginate: true,
                    perPage: this.pageSize,
                    page: this.page,
                    search: this.search,
                    filter: this.filters,
                    allData: true,
                })
                .then((response) => {
                    let data = response.data;

                    if (data.success) this.loading = false;
                    this.values = data.goods.data.sort((a, b) =>
                        a.name > b.name ? 1 : -1
                    );
                    this.valueLength = data.goods.total;
                    this.units = data.units;
                })
                .catch(() => {
                    this.$message({
                        message: "Ошибка ответа от сервера",
                        showClose: true,
                        type: "error",
                    });
                    this.$router.push({ name: "logout" });
                });
        },
        generateTaskForGetRecipes() {
            httpService()
                .post(this.api.good.generateTaskForGetRecipes, {})
                .then((response) => {
                    let data = response.data;

                    if (data.success) {
                        this.$message({
                        message: 'Запрос отправлен. Ожидайте',
                        showClose: true,
                        type: 'success',
                    });
                    }
                })
                .catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        type: 'error',
                        showClose: true,
                    })
                });
        },
        remove(ids, mass) {
            this.$confirm(
                "Вы действительно хотите произвести удаление?",
                "Подтвердите удаление",
                {
                    confirmButtonText: "Удалить",
                    cancelButtonText: "Отмена",
                    type: "warning",
                }
            )
                .then(() => {
                    let idsDelete = [];
                    ids.forEach((el) => idsDelete.push(el.id));

                    httpService()
                        .post(this.api.good.removeGoods, {
                            ids: mass ? idsDelete : [ids],
                        })
                        .then(() => {
                            this.$message({
                                message: "Удалено",
                                showClose: true,
                                type: "success",
                            });
                            this.moduleGet();
                            this.multipleSelection = [];
                        })
                        .catch(() => {
                            this.$message({
                                message: "Ошибка удаления",
                                type: "error",
                                showClose: true,
                            });
                        });
                })
                .catch(() => {
                    this.$message({
                        message: "Удаление отменено",
                        showClose: true,
                        type: "info",
                    });
                });
        },
        updateGoods() {
            this.pullGoods();
        },
        setPage(val) {
            this.page = val;
        },
        setCreateDrawerOpen() {
            this.drawerCreate = !this.drawerCreate;
        },
        closeDrawer() {
            this.drawerCreate = false;
        },
        filterValues() {},
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    },
    watch: {
        pageSize() {
            this.moduleGet();
        },
        page() {
            this.moduleGet();
        },
        search() {
            this.moduleGet();
        },
    },
};
</script>

<style scoped></style>
