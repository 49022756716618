<template>
    <div v-if="order">

        <el-row :gutter="15" class="mb-10">
            <el-col :sm="4">
                <span class="textSM textRegular neutral300">Контрагент:</span>
            </el-col>
            <el-col :sm="8">
                <span class="textSM textRegular neutral900">{{ order.buyer_company?.name ?? '-' }}</span>
            </el-col>
            <el-col :sm="4">
                <span class="textSM textRegular neutral300">Адрес доставки:</span>
            </el-col>
            <el-col :sm="8">
                <span class="textSM textRegular neutral900">{{
                        order.delivery_type === 'delivery' ? (order.delivery_address?.name ?? '-') : 'Самовывоз'
                    }}</span>
            </el-col>
        </el-row>

        <el-row :gutter="15" class="mb-10">
            <el-col :sm="4">
                <span class="textSM textRegular neutral300">Объем:</span>
            </el-col>
            <el-col :sm="8">
                        <span class="textSM textRegular neutral900">{{
                                order.total ? formatNumber(order.total) : 'Не указан'
                            }}</span>
                <good-unit unit="м3."/>
            </el-col>
            <el-col :sm="4">
                <span class="textSM textRegular neutral300">Конструктив:</span>
            </el-col>
            <el-col :sm="8">
                <span class="textSM textRegular neutral900">{{ order.constructive ?? 'Не указан' }}</span>
            </el-col>
        </el-row>


        <el-row :gutter="15" class="mb-10">
            <el-col :sm="4">
                <span class="textSM textRegular neutral300">Заводы:</span>
            </el-col>
            <el-col :sm="8">
                <span class="textSM textRegular neutral900">{{ mixNames }}</span>
            </el-col>

            <el-col :sm="4">
                <span class="textSM textRegular neutral300">Комментарий:</span>
            </el-col>
            <el-col :sm="8">
                <span class="textSM textRegular neutral900">{{ order.comment ?? 'Не указан' }}</span>
            </el-col>
        </el-row>



        <hr/>

        <el-switch
            class="mt-4 mb-8"
            v-model="specGoodSelectionType"
            active-text="Все заводы"
            inactive-text="Для каждого завода"
        />

        <template v-if="!specGoodSelectionType">
            <div v-for="(mix, index) in mixesData" :key="index">
                <div class="d-flex-full lab-spec-item mb-5" v-for="(mixer, index) in mix.mixers" :key="index">
                    <div>
                        <el-checkbox v-model="mixer.checked"/>
                    </div>
                    <div>
                    <span class="cutOverflow">{{
                            mix.name ?? '-'
                        }} - <span :class="mixer.checked ? 'primary600' : 'neutral200'">{{ mixer.name }}</span></span>
                    </div>
                    <div class="w-100">
                        <FormSelectSpecGoodByProduct
                            v-model="mixer.good_id"
                            :disabled="!mixer.checked"
                            :good-id="details.good_id"
                            :good-name="mixer.good_name"
                            :active="true"
                        />
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="d-flex f-direction-column gap-12 lab-spec-create__common-spec">
                <div class="d-flex align-center gap-16">
                    <p class="neutral700 m-0">Спецификация</p>
                <FormSelectSpecGoodByProduct
                    v-model="commonSpecGoodId"
                    :good-id="details.good_id"
                    @get-spec-goods="getSpecGoods"
                    :error-message="errorMessage ? errorMessage : null"
                    :active="true"
                />
                </div>
                <div class="d-flex align-center f-wrap gap-16">
                    <p class="m-0 neutral700" style="min-width: 100px">Заводы:</p>
                            <div class="d-flex align-center gap-16 f-wrap">
                                <div class="d-flex align-center gap-12" v-for="(mix, index) in mixesData" :key="index">
                                    <div class="d-flex align-center lab-spec-item gap-4" v-for="(mixer, index) in mix.mixers" :key="index">
                                        <div>
                                            <el-checkbox v-model="mixer.checked"/>
                                        </div>
                                        <div>
                                        <span class="cutOverflow">{{
                                                mix.name ?? '-'
                                            }} - <span :class="mixer.checked ? 'primary600' : 'neutral200'">{{ mixer.name }}</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                </div>

            </div>
        </template>


        <hr class="mt-15 mb-15"/>

        <button
            @click="saveSpec"
            class="ci-btn ci-btn_blue"
            :disabled="hasEmptyGoodId || loading || errorMessage"
        >
            Подтвердить выбор спецификации
        </button>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import GoodUnit from "@/views/components/goodUnit.vue";
import iconsMixin from "@/mixins/iconsMixin";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import FormSelectSpecGoodByProduct from "@/views/components/Form/Select/FormSelectSpecGoodByProduct.vue";

export default {
    name: "LabSpecCreate",
    components: {FormSelectSpecGoodByProduct, GoodUnit},
    mixins: [functionsMixin, iconsMixin],
    props: ['order', 'mixes'],
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
        }),
        hasEmptyGoodId() {
            if (this.specGoodSelectionType) {
                return !this.commonSpecGoodId
            } else {
                return this.mixesData.some(mix => mix.mixers.some(mixer => mixer.checked && !mixer.good_id))
            }
        },
        mixNames() {
            return this.order.mixes.map((el) => el.name).join(', ')
        }
    },
    inject: ['api'],
    data() {
        return {
            loading: false,
            goodId: null,
            details: {
                id: null,
                name: null,
                mixers: []
            },
            mixesData: [],
            specGoodSelectionType: false,
            commonSpecGoodId: null,
            errorMessage: null
        }
    },
    created() {
        this.details = this.order
        this.mixesData = this.mixes.map(mix => {
            return {
                ...mix,
                mixers: mix.mixers.map(mixer => {
                    const matchingDetailMixer = this.details.mixersData.find(detailMixer => detailMixer.mixer_id === mixer.id)
                    return {
                        ...mixer,
                        checked: !!matchingDetailMixer,
                        good_id: matchingDetailMixer ? matchingDetailMixer.spec_good_id : null,
                        good_name: matchingDetailMixer ? matchingDetailMixer.spec_good_name : null
                    }
                })
            }
        })
        this.setSpecGoodSelectionType();
    },
    methods: {
        saveSpec() {
            this.loading = true

            if (this.specGoodSelectionType) {
                this.updateMixersGoodIds(this.commonSpecGoodId)
            }

            httpService().post(this.api.lab.saveSpec, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'lab',
                orderId: this.order.id,
                mixers: this.mixesData.reduce((acc, mix) => {
                    mix.mixers.forEach(mixer => {
                        if (mixer.checked) {
                            acc.push(mixer);
                        }
                    });
                    return acc;
                }, [])
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Спецификация добавлена',
                        showClose: true,
                        type: 'success'
                    })
                    window.setTimeout(() => {
                        this.$emit('moduleGet')
                    }, 50)
                    window.setTimeout(() => {
                        this.$emit('closeDialog')
                    }, 150)
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getMixesWithCheckedMixers() {
            // получаем массив с заводами, у которых есть отмеченные лаборантом посты
            return this.mixesData.map(item => {
                        const checkedMixers = item.mixers?.filter(mixer => mixer.checked) || [];
                        return checkedMixers.length > 0 ? { ...item, mixers: checkedMixers } : null;
                    })
                    .filter(item => item !== null);
        },
        getSpecGoods(specGoods) {
            this.specGoods = specGoods
        },
        validateSpecGood(specGoodId) {
            if (specGoodId && this.specGoods) {
                    const specGood = this.specGoods?.find((good) => good.id === specGoodId);
                    const moduleIds = specGood.mixGoods.map((mix) => {
                        return this.integrations.find((integration) => integration.id === mix.integration_unit_id)?.account_module.id
                    })

                    // получаем массив с заводами, у которых есть отмеченные лаборантом посты
                    const mixesWithCheckedMixers = this.getMixesWithCheckedMixers();

                    const allMixIdsExist = mixesWithCheckedMixers.every(mix => moduleIds.some(id => id === mix.module_id));

                    // если не для всех заводов есть спецификация, выводим сообщение об ошибке
                    if (!allMixIdsExist) {
                        const missingMixNames = mixesWithCheckedMixers.filter(mix => !moduleIds.includes(mix.module_id)).map(mix => mix.name);
                        this.errorMessage = `Нет рецепта для выбранных заводов: ${missingMixNames.join(', ')}`
                    } else if (this.errorMessage) this.errorMessage = null
                } else if (!specGoodId && this.errorMessage) this.errorMessage = null
        },
        setSpecGoodSelectionType() {
            const mixesWithCheckedMixers = this.getMixesWithCheckedMixers();
            const referenceGoodId = mixesWithCheckedMixers[0].mixers[0].good_id;

            if(!referenceGoodId) return

            let ifSpecGoodCommon = true;

            // проверяем все ли заводы имеют одинаковую спецификацию
            for (const obj of mixesWithCheckedMixers) {
                for (const mixer of obj.mixers) {
                    if (mixer.good_id !== referenceGoodId) {
                        ifSpecGoodCommon = false
                        break
                    }
                }
            }

            if(ifSpecGoodCommon) {
                this.specGoodSelectionType = true;
                this.commonSpecGoodId = referenceGoodId
            }
        },
        updateMixersGoodIds(id) {
            this.mixesData = this.mixesData.map(mix => {
                return {
                    ...mix,
                    mixers: mix.mixers.map(mixer => {
                        if(mixer.checked) {
                            return {
                                ...mixer,
                                good_id: id
                            }
                        } return mixer
                    })
                }
            })
        }
    },
    watch: {
        commonSpecGoodId: {
            handler: function (id) {
                this.validateSpecGood(id)
            }
        },

        mixesData: {
            deep: true,
            immediate: true,
            handler: function () {
                if(this.specGoodSelectionType) {
                    this.validateSpecGood(this.commonSpecGoodId)
                }
            }
        },
        specGoodSelectionType: {
            deep: true,
            immediate: true,
            handler: function (val) {
                if(val) {
                    if(this.commonSpecGoodId) this.validateSpecGood(this.commonSpecGoodId)
                } else this.errorMessage = null
            }
        }
    }
}
</script>

<style scoped>

</style>
