<template>
    <div class="mix-order-group-table-icons-group">

        <!-- Насос -->
        <MixOrderGroupTableIcon
        v-if="orderGroup.deliveryType !== 'take-away'"
        :isActive="orderGroup.pump" letter="Н" type="pump">
            <template #tooltip>
                <p class="title" v-if="orderGroup.info.pumpInfo">
                    Номер насоса: {{ getVehicleNumber(orderGroup.info.pumpInfo.number) }}
                </p>
                <p class="text" v-else>Для заказа не требуется насос</p>
            </template>
        </MixOrderGroupTableIcon>

        <!-- Самовывоз -->
        <MixOrderGroupTableIcon
            v-if="orderGroup.deliveryType === 'take-away'"
            :isActive="true" letter="С" type="take-away">
            <template #tooltip>
                <p class="title">Самовывоз</p>
                <hr/>
                <p class="text">Внимание! Необходимо ручное управление отгрузками</p>
            </template>
        </MixOrderGroupTableIcon>

        <!-- Гидролоток -->
        <MixOrderGroupTableIcon
            v-if="orderGroup.deliveryType !== 'take-away'"
            :isActive="orderGroup.gidrolotok" letter="Г" type="gidrolotok">
            <template #tooltip>
                <p class="text">{{
                        `Для заказа ${orderGroup.gidrolotok ? 'требуется' : 'не требуется'} гидролоток`
                    }}</p>
            </template>
        </MixOrderGroupTableIcon>

        <!-- Комментарии -->
        <MixOrderGroupTableIcon :isActive="isCommentIconActive" type="comments">
            <template #icon>
                <ChatCircleDotsIcon/>
            </template>

            <template #tooltip>
                <ul v-if="isCommentIconActive">
                    <li v-if="orderGroup.info.commentDriver">
                        <p class="subtitle">Комментарий водителю</p>
                        <div v-html='getFormattedComment(orderGroup.info.commentDriver)' class="comment"/>
                    </li>
                    <li v-if="orderGroup.info.commentDispatch">
                        <p class="subtitle">Комментарий диспетчеру</p>
                        <div v-html='getFormattedComment(orderGroup.info.commentDispatch)' class="comment"/>
                    </li>
                    <li v-if="orderGroup.info.commentOperator">
                        <p class="subtitle">Комментарий оператору</p>
                        <div v-html='getFormattedComment(orderGroup.info.commentOperator)' class="comment"/>
                    </li>
                    <li v-if="orderGroup.info.commentPumpDriver">
                        <p class="subtitle">Комментарий водителю насоса</p>
                        <div v-html='getFormattedComment(orderGroup.info.commentPumpDriver)' class="comment"/>
                    </li>
                </ul>
                <p class="text" v-else>Нет комментариев по заказу</p>
            </template>
        </MixOrderGroupTableIcon>

        <!-- Заводы -->
        <MixOrderGroupTableIcon v-if="mixModulesAmount > 1" :isActive="true" type="mixes">
            <template #icon>
                <BuildingIcon :current-color='true'/>
            </template>

            <template #tooltip>
                <div class="orders">
                    <table class="el-table__body" cellspacing="0" cellpadding="0" border="0">
                        <thead>
                        <tr>
                            <th>
                                <p class="cell subtitle pt-0 pb-0 pr-5">
                                    Завод
                                </p>
                                <hr>
                            </th>
                            <th>
                                <p class="cell subtitle pt-0 pb-0 pl-10">
                                    Интеграция
                                </p>
                                <hr>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="mixId in orderGroup.info.mixerIds" :key="mixId" class="hover-row">
                            <td>
                                <div class="cell textXS textMedium primary600 pt-2-5 pb-2-5 pr-5 w-space-normal">
                                    {{ getMixName(mixId) }}
                                </div>
                            </td>
                            <td>
                                <div
                                    :class="['cell text pt-2-5 pb-2-5 no-wrap pr-5 w-space-normal pl-10']">
                                    {{ getIntegrationProduct(mixId) ?? '-' }}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </MixOrderGroupTableIcon>

        <!-- Лаборатория -->
        <MixOrderGroupTableIcon
            v-if="isLabModuleConnected"
            type="lab"
            :isActive="orderGroup.lab && orderGroup.info.specInfo.length"
            :isWaiting="orderGroup.lab && !orderGroup.info.specInfo.length">
            <template #icon>
                <LabBoldIcon/>
            </template>

            <template #tooltip>
                <p v-if="orderGroup.lab && !orderGroup.info.specInfo.length">Ожидаем спецификацию от Лаборатории</p>
                <div v-else-if="orderGroup.lab && orderGroup.info.specInfo.length">
                    <table class="el-table__body" cellspacing="0" cellpadding="0" border="0">
                        <thead>
                        <tr>
                            <th>
                                <p class="cell subtitle mt-0 mb-0 pr-5">
                                    Завод
                                </p>
                                <hr>
                            </th>
                            <th>
                                <p class="cell subtitle mt-0 mb-0 pl-5">
                                    Спецификация
                                </p>
                                <hr>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="spec in orderGroup.info.specInfo" :key="spec.spec_good_id" class="hover-row">
                            <td>
                                <div class="cell textXS textMedium primary600 pt-2-5 pb-2-5 pr-5 w-space-normal">
                                    {{ getMixerName(spec.mix_id, spec.mixer_id) }}
                                </div>
                            </td>
                            <td>
                                <div
                                    class="cell text pt-2-5 pb-2-5 no-wrap pl-5 pr-5 w-space-normal">
                                    {{ spec.spec_good_name }}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <p class="title">Заказ не требует участия Лаборатории</p>
                    <div class="spec" v-if="orderGroup.specGood">
                        <hr/>
                        <p class='subtitle'>Прикреплена следующая спецификация:</p>
                        <p class="text">"{{ orderGroup.specGood }}"</p>
                    </div>

                </div>
            </template>
        </MixOrderGroupTableIcon>

        <!-- Представитель -->
        <MixOrderGroupTableIcon :isActive="orderGroup.companyManager.id" type="companyManager">
            <template #icon>
                <UserProfileBoldIcon :currentColor="true"/>
            </template>

            <template #tooltip>
                <p class="title">
                    Представитель контрагента{{
                        !orderGroup.companyManager.name ? ' не указан' : ' '
                    }}
                </p>
                <template v-if="orderGroup.companyManager.name">
                    <hr/>
                    <p class="subtitle">ФИО: <span class='text'>{{ orderGroup.companyManager.name }}</span></p>
                    <p v-if="orderGroup.companyManager.phone" class="subtitle">
                        Телефон: <span class="text">{{ formatPhoneNumber(orderGroup.companyManager.phone) }}</span>
                    </p>
                </template>
            </template>
        </MixOrderGroupTableIcon>
    </div>
</template>

<script>
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import functionsMixin from "@/mixins/functionsMixin";
import TTSProducts from "@/mixins/TTSProducts";

// icons
import MixOrderGroupTableIcon from "@/views/components/MixOrderGroup/components/MixOrderGroupTableIcon.vue";
import ChatCircleDotsIcon from "@/views/components/Icons/ChatCircleDotsIcon.vue";
import LabBoldIcon from "@/views/components/Icons/LabBoldIcon.vue";
import BuildingIcon from "@/views/components/Icons/BuildingIcon.vue";
import UserProfileBoldIcon from "@/views/components/Icons/UserProfileBoldIcon.vue";

export default {
    name: "MixOrderGroupTableIconsGroup",
    components: {
        MixOrderGroupTableIcon, ChatCircleDotsIcon, LabBoldIcon, BuildingIcon, UserProfileBoldIcon
    },
    props: ['orderGroup'],
    mixins: [functionsMixin, TTSProducts],
    computed: {
        ...mapState(useCommonStore, {
            modules: "getModules",
            integrations: "getIntegrations",
        }),
        isCommentIconActive() {
            if (!this.orderGroup?.info) return false

            const {commentDriver, commentDispatch, commentOperator, commentPumpDriver} = this.orderGroup.info;
            return commentDriver || commentDispatch || commentOperator || commentPumpDriver
        },
        mixersData() {
            return this.modules.find(el => el.accountModuleId == this.$route.params.id)?.mixers ?? []
        },
        mixModulesAmount() {
            return this.mixersData?.length
        },
        isLabModuleConnected() {
            return this.modules.find((module) => module.key === 'lab')
        },
    },
    methods: {
        getMixName(mixModuleId) {
            const mixer = this.mixersData.find((mixer) => mixer.id === mixModuleId)
            return mixer?.name
        },
        getIntegrationProduct(mixerId) {
            const mixer = this.mixersData.find((mixer) => mixer.id === mixerId)
            const integration = this.integrations.find((integration) => integration.account_module?.id === mixer.account_mix_module_id)

            if (!integration) return null
            let integrationProduct = null;
            const {settings, product} = integration

            integrationProduct = product ?? JSON.parse(settings)?.product

            if (!integrationProduct) return null

            const productName = this.TTSProducts.find((el) => el.id === integrationProduct).name

            return productName
        },
        getMixerName(mixId, mixerId) {
            return this.mixersData.find((mixer) => mixer.id === mixerId)?.name;
        }
    }
}
</script>
