<template>
  <div class='fileUploader'>
    <div :class="['top']">
      <div class="input">
        <input id="file-uploader-input" type="file" accept=".doc,.docx" @change="handleUpload" />
        <el-button :class="['fileUploader__upload-btn', { 'required': props.required }]" :loading="loading" :icon="Upload" plain type="primary" @click="handleClick">
          {{ !file ? 'Загрузить документ' : 'Загрузить новый документ' }}
        </el-button>
      </div>
    </div>
    <div v-if="file" class="file">
        <div class="file-details">
            <Document width="14" height="14" />
            <p class="m-0">{{ file.name }}</p>
        </div>
        <el-button class="clear-btn" v-if="file" plain @click="handleClear" :icon="CircleClose">Удалить</el-button>
    </div>
    <p v-if="props.validationMessage" class="fileUploader__validation-message textXS m-0">
        {{validationMessage}}
    </p>
  </div>
</template>

<script setup>
//name: 'FileUploader',
import {defineProps, defineEmits, ref, onMounted, watch} from 'vue';
import {Document, Upload, CircleClose} from "@element-plus/icons-vue";


const props = defineProps(['modelValue', 'required', 'validationMessage']);
const emits = defineEmits(['update:modelValue']);

const file = ref(null);
const is_delete_file = ref(0);

const handleClick = () => document.getElementById('file-uploader-input').click();

const handleUpload = (e) => {
    if (e.target.files) {
        file.value = e.target.files[0];
        emits('update:modelValue', file.value);
        is_delete_file.value = 0;
    }
}
const handleClear = () => {
    document.getElementById('file-uploader-input').value = "";
    file.value = null;
    emits('update:modelValue', file.value);
    is_delete_file.value = 1;
}

watch(file, () => {
    emits('update:modelValue', file.value);
})
watch(is_delete_file, () => {
    emits('setIsDeleteFile', is_delete_file.value);
})

onMounted(() => {
    // No preview for Word files, just show file name
    file.value = props.modelValue;
})
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.fileUploader {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  &__upload-btn.required {
    position: relative;

    &::after {
        content: "*";
        color: #f56c6c;
        position: absolute;
        transform: translate(0, 0);
        right: 8px;
        top: 8px;
    }
}

  &__validation-message {
        position: absolute;
        transform: translate(0, 100%);
        bottom: -1px;
        left: 0;
        color: #f56c6c;
    }

  .top {
    display: flex;
    align-items: center;
    gap: 37px;
  }

  span {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
  }

  .input {
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      display: none;
      cursor: pointer;
    }

    span {
      color: variables.$primary600;
    }
  }

  .clear {
    color: variables.$neutral700;
    cursor: pointer;
  }

  & .file {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & .file-details {
    display: flex;
    gap: 4px;
    align-items: center;
    border: 1px solid variables.$neutral50;
    width: fit-content;
    padding-inline: 8px;
    border-radius: 4px;

    p {
        margin-block: 0;
        font-size: 14px;
        color: variables.$neutral700;
    }
  }

  & .clear-btn {
    cursor: pointer;
    height: fit-content;
    padding: 0;
    border: none;

    span {
        margin-left: 2px;
    }
  }
}
</style>
