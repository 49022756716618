<template>
    <svg
         :class='[{"currentColor": currentColor}]'
         :width="width ?? 24"
         :height="height ?? 24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M14 18L21 18"
              stroke="#49A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M3 18H5"
              stroke="#49A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M7.5 20.5C8.88071 20.5 10 19.3807 10 18C10 16.6193 8.88071 15.5 7.5 15.5C6.11929 15.5 5 16.6193 5 18C5 19.3807 6.11929 20.5 7.5 20.5Z"
              stroke="#49A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M20 12H21"
              stroke="#49A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M3 12H10"
              stroke="#49A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M16.5 14.5C17.8807 14.5 19 13.3807 19 12C19 10.6193 17.8807 9.5 16.5 9.5C15.1193 9.5 14 10.6193 14 12C14 13.3807 15.1193 14.5 16.5 14.5Z"
              stroke="#49A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M13 6H21"
              stroke="#49A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M3 6H4"
              stroke="#49A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M10.5 8.5C11.8807 8.5 13 7.38071 13 6C13 4.61929 11.8807 3.5 10.5 3.5C9.11929 3.5 8 4.61929 8 6C8 7.38071 9.11929 8.5 10.5 8.5Z"
              stroke="#49A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
    name: "FilterIcon",
    props: ['currentColor', 'width', 'height']
}
</script>

<style scoped>
.currentColor {
    path {
        stroke: currentColor;
    }
}
</style>
