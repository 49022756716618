<template>
    <Auth title="Объекты">

        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-update-button="true"
                    create-button-label="Добавить объект"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="() => factoryCreateDrawer = true"
                />

                <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name ?? '-'"
                        />

                        <MobileTableInfo
                            title="Тип"
                            :body="item.type ? item.type.name : '-'"
                        />

                        <MobileTableInfo
                            title="Часовой пояс"
                            :body="item.timezone ? getInfo(item.timezone, timezones) : 'MSK'"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Редактировать"
                            @action="openFactoryDetails(index, item)"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="displayData"
                    v-if="!mobile"
                    empty-text="Информация не найдена"
                    class="mt-15 w-100">
                    <el-table-column
                        prop="name"
                        label="Название"
                        sortable>
                        <template v-slot="scope">
                            <span class="textMedium textSM neutral900">{{ scope.row.name ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Тип"
                        sortable>
                        <template v-slot="scope">
                            <span class="textRegular neutral300 cutOverflow">{{ scope.row.type ? scope.row.type.name : '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="timezone"
                        label="Часовой пояс"
                        sortable>
                        <template v-slot="scope">
                            <span class="textMedium primary600 cutOverflow">{{ scope.row.timezone ? getInfo(scope.row.timezone, timezones) : 'MSK' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150">
                        <template v-slot="scope">
                            <el-button
                                size="mini"
                                @click="openFactoryDetails(scope.$index, scope.row)">
                                Редактировать
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>

        <el-drawer
            v-model="factoryCreateDrawer"
            title=""
            direction="rtl"
            :size="drawerSize"
            :before-close="closeFactoryDetails"
            :destroy-on-close="true">
            <FactoryCreate
                @moduleGet="moduleGet"
                :factory="factoryDetails"
                @closeDrawer="closeFactoryDetails"
            />
        </el-drawer>
    </Auth>
</template>

<script>
import Auth from './../../layouts/Auth'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import TablePagination from '@/views/components/Table/TablePagination'
import FactoryCreate from '@/views/components/SettingsPage/FactoriesPage/FactoryCreate'
import timezonesMixin from '@/mixins/timezonesMixin'
import functionsMixin from '@/mixins/functionsMixin'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import { httpService } from '@/services/http.service'
import { useCommonStore } from '@/store/common'
import {mapActions} from "pinia";

export default {
    name: 'FactoriesIndex',
    components: {
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        FactoryCreate,
        TablePagination,
        TableHeadBlock,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, tableMixin, timezonesMixin, functionsMixin
    ],
    data() {
        return {
            loading: true,
            factoryCreateDrawer: false,
            factoryDetails: null,
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        ...mapActions(useCommonStore, [
            'setFactories'
        ]),
        moduleGet() {
            httpService().post(this.api.settings.getFactories).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.values = data.factories;

                useCommonStore().setFactories(data.factories);

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        },
        openFactoryDetails(index, row) {
            this.factoryDetails = null
            this.factoryCreateDrawer = true
            this.factoryDetails = row
        },
        closeFactoryDetails() {
            this.factoryDetails = null
            this.factoryCreateDrawer = false
        },
        filterValues(values, search) {
            let filtered = values

            if (search) filtered = filtered.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))

            this.valueLength = filtered.length
            return filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
}
</script>

<style scoped>

</style>
