<template>
    <Auth title="Заказы" :tabs="tabs" :settings="$route.params.id">
        <template v-slot:additionalButtons>
            <div class="d-flex justify-between flex-1">
                <DispatchOrderHeaderButton :to="{ name: 'DispatchOrderGroupsCreate', params: { redirectedFrom: 'DispatchOrderGroups' }}"/>
                <EventButtons
                    :account-events="accountEvents"
                    :dialog="eventDialogOpened"
                    v-model="selectedEventTab"
                    @clickOpenDialogButton="toggleEventDialogOpened"
                    @readAllEvents="readAllEvents"
                />
            </div>
        </template>
        <div class="card">
            <div class="card-body dispatchCardBody">
                <Summary
                    class="summary-block"
                    :orders="displayData"
                    @changeDate="changeDate"
                />
                <MixOrderGroupTable
                    class="mixOrderGroupTable"
                    :loading="loading"
                    :order-groups="displayData"
                    @get="moduleGet"
                />

                <!-- <EventList
                    :selected-tab="selectedEventTab"
                    class="eventList"
                    :account-events="accountEvents"
                    @update-events="updateEvents"
                    :mix-order-groups="orderGroupsForEvents"
                    :loading="loading"
                /> -->

                <EventModal :account-events="accountEvents" :modelValue="eventDialogOpened"
                            :selectedEventTab="selectedEventTab" @update:modelValue="eventDialogOpened = $event"
                            @update-events="updateEvents" @readAllEvents="readAllEvents"
                            :mix-order-groups="orderGroupsForEvents" :loading="loadingEvents"/>
            </div>
        </div>
        <EventNotification ref="notification" @update-events="updateEvents" :mix-order-groups="orderGroupsForEvents"/>
        <el-dialog
            class="group-order-action-dialog"
            v-model="orderDetailsDialog"
            title=""
            :width="dialogSize"
            :destroy-on-close="true"
            @close="closeOrderDetailsDialog"
        >
            <MixOrderDetailsGroup
                :mix-order-id="selectedMixOrderId"
                :order-group="selectedMixOrderGroup"
                @close="closeOrderDetailsDialog"
            />
        </el-dialog>
    </Auth>
</template>
<script>
import Auth from '@/views/layouts/Auth.vue'
import tableMixin from '@/mixins/tableMixin'
import moment from 'moment/moment'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import {httpService} from "@/services/http.service";
import Summary from "@/views/dispatch/components/Summary.vue";
import {useOrderGroupsStore} from '@/store/orderGroups'
import {mapActions, mapState} from 'pinia'
import MixOrderGroupTable from "@/views/components/MixOrderGroup/MixOrderGroupTable.vue";
import {useCommonStore} from '@/store/common'
import EventModal from "@/views/components/Events/EventModal.vue";
import EventButtons from '../components/Events/EventButtons.vue';
import EventNotification from '@/views/components/Events/EventNotification.vue'
import MixOrderDetailsGroup from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroup.vue";
import DispatchOrderHeaderButton from "@/views/dispatch/components/buttons/DispatchOrderHeaderButton.vue";

export default {
    name: 'DispatchOrderGroups',
    provide() {
        return {
            // timezone: this.timezone
            timezone: 'Europe/Moscow'
        }
    },
    components: {
        MixOrderGroupTable,
        Summary,
        Auth,
        EventButtons,
        EventModal,
        EventNotification,
        MixOrderDetailsGroup,
        DispatchOrderHeaderButton
    },
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, dispatchTabs],
    inject: ['api'],
    data() {
        return {
            loading: true,
            loadingData: false,
            loadingEvents: false,
            selectedEventTab: 'new',
            //addNewMixOrderGroup: true,
            selectedGroupId: null,
            openActionDialog: false,
            filter: {
                date: {
                    value: moment(),
                    type: 'date'
                },
            },
            multipleSelection: [],
            createNewOrderShow: false,
            actionsModalShow: true,
            isAdd: false,
            firstLoad: true,
            eventDialogOpened: false,
            orderDetailsDialog: false,
            accountEvents: [],
            selectedMixOrderId: null,
            selectedMixOrderGroup: null,
        }
    },
    async created() {
        window.Echo.channel('laravel_database_ChannelDispatchOrderGroup')
            .listen(`.Algorithm.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Create.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Update.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Delete.${this.$route.params.id}`, () => {
                this.moduleGet()
            });

        this.moduleGet(true)
        this.getEvents();

        window.Echo.channel('laravel_database_ChannelAccountEvent')
            .listen(`.Create.${this.$route.params.id}`, (data) => {
                const newEvent = {...data.event, tz: data.tz ? data.tz : 'Europe/Moscow'};
                const isCountBehindByOne = this.accountEvents.length === data.eventsCount - 1;
                const isCountBehind = this.accountEvents.length < data.eventsCount;

                if (isCountBehindByOne) {
                    if (this.windowWidth < 1920 && !this.eventDialogOpened) {
                        this.showNotification(newEvent);
                    }
                    this.accountEvents.push(newEvent);
                } else if (isCountBehind) {
                    if (this.windowWidth < 1920 && !this.eventDialogOpened) {
                        this.showNotification(newEvent);
                    }
                    this.getEvents();
                }
            })
            .listen(`.Update.${this.$route.params.id}`, (data) => {
                let oldEvent = this.accountEvents.find((el) => el.id === data.event.id);

                if (oldEvent && oldEvent.status !== data.event.status) {
                    oldEvent.status = data.event.status
                }
            });
    },
    beforeUnmount: function () {
        // window.Echo.leave(`laravel_database_ChannelMixOrderGroupCreateNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderGroupUpdateNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);
        window.Echo.leave(`laravel_database_ChannelDispatchOrderGroup`);
        window.Echo.leave(`laravel_database_ChannelAccountEvent`);
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            orderGroups: 'getOrderGroups',
            orderGroupsForEvents: 'getOrderGroupsForEvents',
            timezone: 'getTimezone',
            pagination: 'getPagination',
            filters: 'getFilters',
            getDate: 'getDate',
        }),
        ...mapState(useCommonStore, {
            modules: 'getModules',
        })
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['pullOrderGroups', 'changeOrderGroup', 'setOrderGroupsForEvents']),
        moduleGet(loading) {
            if (this.$route.params.id === null) {
                return
            }

            if (!this.loadingData) {
                this.loadingData = true
                if (loading) this.loading = true;

                httpService().post(this.api.dispatch.orderGroups.getOrderGroups, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    params: this.filters
                }).then(({data}) => {
                    if (data.success) {

                        if(this.filters['filter[date]'] === moment().format('YYYY-MM-DD')) {
                            this.setOrderGroupsForEvents(data.values)
                        }

                        this.values = data.values.sort((a, b) => {
                            const statusGroups = {
                                confirmed: 1,
                                work: 1,
                                done: 2,
                                completed: 2,
                                pause: 3,
                                failed: 4,
                            };

                            if (statusGroups[a.status] !== statusGroups[b.status]) {
                                return statusGroups[a.status] - statusGroups[b.status];
                            }

                            return a.firstOrderTimeDelivery - b.firstOrderTimeDelivery;
                        })
                        this.loading = false
                    }
                    this.loadingData = false
                }).catch(() => {
                    // this.$message({
                    //     message: error.response.data.message,
                    //     showClose: true,
                    //     type: 'error'
                    // })
                    this.loading = false
                    this.loadingData = false
                })
            }
        },

        changeDate(value) {
            this.filters['filter[date]'] = moment(value).format('YYYY-MM-DD')
            this.values = []
            this.moduleGet(true)
        },
        getOrders(filter) {
            if (this.$route.params.id) {
                this.loading = true
                this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, filter)
                setTimeout(() => this.loading = false, 250)
            }
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.doc.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered
        },
        getAddress(companyId, addressId) {
            if (companyId && addressId) {
                let company = this.companies.find(el => el.id === companyId)
                if (company) {
                    let address = company.addresses.find(el => el.id === addressId)
                    if (address) {
                        return address.name
                    } else {
                        this.pullCompanies()
                        window.setTimeout(() => {
                            address = company.addresses.find(el => el.id === addressId)
                            if (address) {
                                return address.name
                            } else {
                                return null
                            }
                        }, 150)
                    }
                }
                return null
            }
            return null
        },
        toggleEventDialogOpened() {
            this.eventDialogOpened = !this.eventDialogOpened
        },
        getEvents() {
            this.loadingEvents = true
            
            httpService().post(this.api.accountEvents.index, {}).then(({data}) => {
                if (data.success) {
                    this.accountEvents = data.accountEvents.map((el) => {
                        return {...el, tz: data.tz ? data.tz : 'Europe/Moscow'}
                    })
                    this.loadingEvents = false
                }
            }).catch((error) => {
                this.loadingEvents = false
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        showNotification(event) {
            this.$refs.notification.addNotification({
                event: event,
            });
        },
        readAllEvents() {
            httpService().post(this.api.accountEvents.readAll, {}).then(() => {
                this.getEvents()
            })
        },
        updateEvents(mixOrderGroup, mixOrderId) {
            this.selectedMixOrderGroup = mixOrderGroup;
            this.selectedMixOrderId = mixOrderId
            this.orderDetailsDialog = true
            this.getEvents()
        },
        closeOrderDetailsDialog() {
            this.orderDetailsDialog = false
            this.selectedMixOrderId = null
            this.selectedMixOrderGroup = null
        }
    },
}
</script>

<style lang="scss">
//.eventList {
//    grid-area: eventList;
//    display: none;
//
//    @media screen and (min-width: 1920px) {
//        display: block;
//        border-left: 1px solid #eeeeee;
//    }
//}

.summary-block {
    grid-area: summary;
}

//.group-orders-table {
    //@media screen and (min-width: 1920px) {
    //    border-right: none !important;
    //}
//}

.mixOrderGroupTable {
    grid-area: mixOrderGroupTable;

    //@media screen and (min-width: 1920px) {
    //    border-right: none !important;
    //}
}

.group-order-action-dialog {
    border-radius: 12px !important;

    &__footer {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
    }

    .el-dialog__header {
        margin-right: 0;
    }
}
</style>
<style scoped lang="scss">
.dialog-header {
    display: flex;
    justify-content: end;

    .el-icon {
        cursor: pointer;
    }
}

.action-button {
    margin-left: 14px;
}

.card-body {
    position: relative;

    &__filters {
        padding: 12px 0;
    }

    //@media screen and (min-width: 1920px) {
    //    display: grid;
    //    grid-template-columns: minmax(0, 1fr) 456px;
    //    grid-template-areas: "summary eventList" 'mixOrderGroupTable eventList';
    //    grid-template-rows: auto 1fr;
    //}
}

.group-order {
    &__pagination {
        display: flex;
        justify-content: center;
    }
}
</style>
<style lang="scss" scoped>
.buyer-circle {
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;
}

.good-icon {
    vertical-align: middle;
    margin-right: 4px;
    width: 16px;
    height: 16px;
}


</style>
<style lang="scss">
.total-count .cell {
    padding: 0;
}


.cell-container {
    display: flex;
    justify-content: center;
}

.cell-padding {

    padding: 0 !important;

    .cell {
        padding: 6px 0 !important;
    }
}

.actions {
    display: flex;
    justify-content: space-between;
    height: 28px;

    button {
        min-height: auto;
        font-size: 12px;
        border-radius: 4px;
    }

    .plus-btn {
        border: none;
        width: 32px;
        height: 28px;
        padding: 0;
        justify-content: center;
    }

    .action-btn {
        padding: 0 12px;
    }
}

.address {
    font-size: 12px;
}
</style>
