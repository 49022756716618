<template>
    <el-tooltip effect="dark" content="Создать заказ" placement="bottom" :show-after="500"
                :disabled="windowWidth > 900">
        <router-link type="primary" :to="to" class="dispatchOrderHeaderButton">
            <span v-if="windowWidth > 1120">Создать заказ</span>
            <Plus width="20" height="20" v-else/>
        </router-link>
    </el-tooltip>
</template>

<script>
import {Plus} from '@element-plus/icons-vue';
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "DispatchOrderHeaderButton",
    props: [
        'to',
    ],
    mixins: [mobileCheckMixin],
    components: {
        Plus
    }
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.dispatchOrderHeaderButton {
    padding: 12px 24px;
    border-radius: 8px 8px 0 0;
    text-decoration: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    height: 45px;
    background: variables.$primary600;
    color: variables.$white;
    display: block;

    @media screen and (max-width: 1150px) {
        padding-inline: 16px;
    }
}
</style>
