<template>
    <el-dialog
        :modal-class="'contracts-filter-dialog'"
        title="Выберите контрагента"
        :width="mobile ? '100%' : '60%'"
    >
        <el-table
            ref="tableRef"
            :reserve-selection="true"
            @row-click="handleRowClick"
            @selection-change="handleSelectionChange"
            :data="companies"
            :row-key="getRowKey"
            class="w-100 mt-15 mb-15 integration-table"
            :row-class-name="'integration-table-row'"
        >
            <el-table-column prop="name" label="Название" />

            <el-table-column type="selection" fixed="right" width="120px" />
        </el-table>
    </el-dialog>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";
import { useCommonStore } from "@/store/common";
import { mapState } from "pinia";

export default {
    name: "ModalWithScroll",
    props: ["companiesModel", "submit"],
    data() {
        return {
            multipleSelection: this.companiesModel,
        };
    },
    mixins: [mobileCheckMixin, functionsMixin, iconsMixin],
    methods: {
        handleSelectionChange(data) {
            this.multipleSelection = data;
        },
        handleRowClick(row) {
            this.$refs.tableRef.toggleRowSelection(row);
        },
        getRowKey(row) {
            return !this.multipleSelection.length ? null : row.id;
        },
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: "getCompanies",
        }),
    },
    watch: {
        multipleSelection: {
            handler: function () {
                this.$nextTick(() => {
                    if (!this.multipleSelection.length && this.$refs.tableRef) {
                        this.$refs.tableRef.clearSelection();
                    }
                });
                this.$emit("update-data", this.multipleSelection);
            },
            deep: true,
            immediate: true,
        },
        companiesModel: {
            handler: function () {
                this.multipleSelection = this.companiesModel;
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
@use "@/sass/_variables.scss";
.contracts-filter-dialog .el-dialog__header {
    padding-bottom: 0;
    padding-left: 10px;
}

.contracts-filter-dialog .el-table__row {
    cursor: pointer;
    font-size: 16px;
}

.contracts-filter-dialog .el-scrollbar__wrap {
    max-height: 50vh;
}

.contracts-filter-dialog .el-dialog__title {
    font-size: 24px;
}

.integration-table.integration-table .el-table__header .cell {
    padding-top: 0;
    font-weight: 500;
    font-size: 16px;
}

.integration-table-row.integration-table-row .cell {
    color: variables.$neutral700;
    font-weight: 500;
}

.contracts-filter-dialog .el-table .el-table__cell {
    padding-block: 12px;
}
</style>
