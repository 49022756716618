<template>
    <div class="card dispatch-order-groups-create">
        <div class="card-body">

            <DistributeOrderGroup
                v-if="distributing"
                :orders="distributeOrders"
                :metrics="metrics"
                @create="updateCurrentOrderGroup"
                @update="updateOrderGroup"
            />

            <div v-show="!distributing">
                <div class="primary500 textLG textMedium mt-15 mb-15">
                    Заказ
                </div>

                <MixOrderGroupCreateForm
                    v-model="form"
                    :updateMode="true"
                    :mixers="mixers"
                    :defaultFormData="defaultFormData"
                    :buyerCompanyId="buyerCompanyId"
                    @updateBuyerCompanyId="updateBuyerCompanyId"
                />
                <el-button
                    type="primary"
                    @click="updateCurrentOrderGroup"
                >
                    Обновить
                </el-button>
                <el-button
                    v-if="form.delivery_type === 'delivery'"
                    @click="getDistribute"
                >
                    Распределить заказ
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import MixOrderGroupCreateForm from "@/views/components/MixOrderGroup/MixOrderGroupCreateForm.vue";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import {mapState, mapStores} from "pinia";
import {useCommonStore} from "@/store/common";
import {useMixStore} from "@/store/mix";
import moment from "moment";
import DistributeOrderGroup from "@/views/dispatch/DistributeOrderGroup.vue";

export default {
    name: "MixOrderGroupUpdate",
    components: {MixOrderGroupCreateForm,DistributeOrderGroup},
    mixins: [functionsMixin],
    inject: ['api'],
    data() {
        return {
            mixers: [],
            metrics: [],
            distributeOrders: [],
            form: {},
            defaultFormData: {},
            buyerCompanyId: null,
            distributing: false,
        }
    },
    created() {
        this.getGroup(this.$route.params.groupId)
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
        }),
        ...mapStores(useMixStore),
    },
    methods: {
        getGroup(groupId) {
            httpService().post(this.api.dispatch.getOrderGroupById, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                group_id: groupId
            }).then((response) => {
                const {data} = response.data

                data.pumpInfo = {
                    company_id: data?.pump_vehicle?.rent_company_id ?? null,
                    vehicle_number: data?.pump_vehicle?.number ?? null,
                    driver_name: data?.pump_vehicle?.pump_driver_name ?? null,
                    driver_phone: data?.pump_vehicle?.pump_driver_phone ?? null,
                    company_phone: data?.pump_vehicle?.rent_company?.phone ?? null,
                    price: 0,
                }

                this.form = {
                    ...data,
                    good_price: data.good_price === 0 ? null : data.good_price,
                }
                this.defaultFormData = {
                    ...this.form
                };

                this.getSchedules(data)
            })
        },
        updateCurrentOrderGroup() {
            let formData = {...this.form}

            if (!formData.pump) {
                delete formData.pumpInfo;
            }

            httpService().post(this.api.dispatch.orderGroups.updateOrderGroups, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                group_id: this.$route.params.groupId,
                form: formData
            }).then(() => {
                if (this.$route.meta.moduleKey === 'crm') {
                    this.$router.push({name: 'CRMOrders', params: {id: this.$route.params.id}})
                } else {
                    this.$router.push({name: 'DispatchOrderGroups', params: {id: this.$route.params.id}});
                }

            }).catch((error) => {

                const {errors} = error.response.data

                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
            })
        },
        getSchedules(orderGroup) {
            httpService().post(this.api.dispatch.schedules.getPosts, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: moment(orderGroup.date_shipment).format('YYYY-MM-DD')
            }).then(({data}) => {
                if (data.success) {
                    this.mixers = data.posts
                    this.form.mixer_ids = orderGroup.mixers.map(id => id.id)
                    this.form.mix_ids = orderGroup.mixes.map(id => id.id)
                }
            })
        },
        getDistribute() {
            this.distributing = true
            let formData = {...this.form};

            httpService().post(this.api.dispatch.orderGroups.distributeUpdateOrderGroup, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: formData
            }).then(({data}) => {
                if (data.success) {
                    this.distributeOrders = data.orders;
                    this.metrics = data.metrics;
                }
            }).catch((error) => {
                this.distributing = false
                const {errors} = error.response.data

                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
            })
        },
        updateOrderGroup() {
            this.distributing = false
            this.distributeOrders = []
        },
        updateBuyerCompanyId(value) {
            this.buyerCompanyId = value
        },
    },
}
</script>
<style scoped lang="scss">
.selectedMixes {
    color: #393F4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.mix-action {
    align-items: center;
}

.mix-section {
    display: flex;
    justify-content: space-between;
}

.mix-load {
    color: #BD2626;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-right: 14px;
}
</style>
