<template>
    <TableHeadBlock
        :new="true"
        v-model="search"
        :loading="loading"
        :disable-create-button="true"
        :disable-update-button="true"
        :disable-filter="true"
    />

    <el-table
        empty-text="Информация не найдена"
        class="mt-15 w-100 defaultTableHeight"
        v-loading="loading"
        @current-change="handleCurrentChange"
        :data="displayData">

        <el-table-column
            prop="id"
            label="#"
            class-name="pointer"
            width="100"
            sortable>
            <template v-slot="scope">
                <span class="textSM textMedium neutral200">
                    # {{ isSchedule ? formatNumber(scope.row.vehicle.id) : formatNumber(scope.row.id) }}
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Название"
            class-name="pointer"
            sortable>
            <template v-slot="scope">
                <span class="textSM textMedium neutral900 cutOverflow">
                    {{(isSchedule ? getVehicleNumber(scope.row.vehicle.number) : getVehicleNumber(scope.row.number)) ?? '-' }}
                </span>
            </template>
        </el-table-column>

    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>

import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";

export default {
    name: 'EditSelectVehicles',
    components: {TableHeadBlock, TablePagination},
    mixins: [tableMixin, functionsMixin],
    props: {
        vehicles: {

        },
        schedule: {
            default: false,
            type: Boolean
        }
    },
    inject: ['api'],
    data() {
        return {
            loading: true,
            isSchedule: this.schedule
        }
    },
    created() {
        if (this.vehicles && this.vehicles.length) {
            this.values = this.vehicles
            this.loading = false
        } else {
            this.getVehicles()
        }
    },
    methods: {
        getVehicles(){
            this.isSchedule = false;
            httpService().post(this.api.vehicle.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                    this.values = data.vehicles
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || (data.name.toLowerCase().includes(this.search.toLowerCase()) || data.number.toLowerCase().includes(this.search.toLowerCase())))
            this.valueLength = this.filtered.length

            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleCurrentChange(val) {
            if (val) {
                if (this.isSchedule) {
                    val.vehicle.driver_id = val.driver_id ?? val.vehicle.driver_id;
                    this.$emit('update', val.vehicle)
                } else {
                    this.$emit('update', val)
                }
            }
        },
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
