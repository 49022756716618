<template>
    <div class="time-header">
        <div v-for="time in timeSlots" :key="time" class="time-slot">
            <div class="tick" v-for="i in 5" :key="i"></div>
            <p class="time-slot__value">{{ time }}</p>
        </div>
    </div>
</template>
<script>

export default {
    name: "SelfGanttTimeHeader",
    props: {
        timeSlots: Array
    },
    created() {
    },
}
</script>
<style scoped lang="scss">
@use "@/sass/_variables.scss";

.time-header {
    display: flex;
    position: sticky;
    top: 0;
    background-color: variables.$white;
    z-index: 7;
    border-left: 1px solid variables.$light-border-color;
    min-height: 32px;
    height: 100%;
}

.time-slot {
    position: relative;
    flex: 1;
    text-align: center;
    border-right: 1px solid #efefef;
    font-size: 12px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    height: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &:hover {
        background-color: variables.$neutral30;
    }

    &__value {
        color: variables.$neutral100;
        font-size: 10px;
        margin: 0;
        width: fit-content;
        rotate: 300deg;
        position: absolute;
    }
}

/* Добавляем засечки каждые 10 минут под каждым часовым интервалом */
.time-slot .tick {
    position: absolute;
    bottom: 0;
    width: 1px; /* Ширина засечки */
    height: 6px; /* Высота засечки */
    background-color: variables.$light-border-color;
}

/* Распределяем засечки по всей ширине временной ячейки */
.time-slot .tick:nth-child(1) {
    left: 10%;
}

.time-slot .tick:nth-child(2) {
    left: 30%;
}

.time-slot .tick:nth-child(3) {
    left: 50%;
}

.time-slot .tick:nth-child(4) {
    left: 70%;
}

.time-slot .tick:nth-child(5) {
    left: 90%;
}
</style>
