<template>
    <Auth title="Настройки приложений">
        <div class="card">
            <div class="card-body">

                <p class="textRegular textSM neutral400">Ниже представлены настройки приложений. Они действуют для всех пользователей во всех модулях:</p>
                <div class="d-block w-100" v-for="(value, key) in settings" :key="key">
                    <el-checkbox
                        :true-label="1"
                        :false-label="0"
                        :checked="settings[key] === '1'"
                        @change="updateSetting(key, $event)"
                        :label="getLabel(key)"
                        size="large"
                    />
                </div>

                <hr class="mt-30 mb-30"/>

                <el-button :disabled="loading" @click="save()" type="primary">Сохранить настройки</el-button>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../../layouts/Auth'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import {useCommonStore} from '@/store/common'
import {mapActions} from "pinia";

export default {
    name: 'AccountSettingsIndex',
    components: {
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, functionsMixin
    ],
    data() {
        return {
            loading: true,
            settings: [],
            all: [],
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            httpService().post(this.api.settings.getAppSettings).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.loadingButton = false
                    this.settings = data.settings
                    this.all = data.all
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        save() {
            this.loading = true
            const settings = this.settings
            httpService().post(this.api.settings.updateAppsSettings, {
                settings: settings
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.$message({
                        message: 'Сохранено',
                        showClose: true,
                        type: 'success'
                    })
                    this.setAppSettings(settings);
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getLabel(key) {
            return this.all.find(el => el.key === key)?.label ?? key
        },
        updateSetting(key, newValue) {
            this.settings[key] = newValue ? "1" : "0";
        },
        ...mapActions(useCommonStore, [
            'setAppSettings'
        ]),
    },
}
</script>

<style scoped>

</style>
