<template>
    <div class="TableHeadBlock">
        <div class="d-flex align-center">
            <div class="mr-40 switcherBlock d-flex" v-if="editView">
                <slot name="switchBlock"/>
            </div>
            <div class="mr-40 searchAndFilter d-flex" v-if="showDate">
                <div class="dates date-picker-container">
                    <div class="current-day">{{ currentDayName }}</div>
                    <el-date-picker
                        class="summary-date-picker pointer"
                        v-model="filtersData['filter[date]']"
                        :type="'date'"
                        :format="'D MMMM'"
                        :clearable="false"
                        :editable="false"
                        :size="'default'"
                        @change="$emit('changeDate', filtersData['filter[date]'])"
                    />
                </div>
            </div>
            <div class="d-flex justify-between align-center">
                <slot name="scheduleDefault"></slot>
            </div>
        </div>
        <div class="btns-group">
            <slot name="buttonsBlock"></slot>
        </div>
    </div>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import iconsMixin from "@/mixins/iconsMixin";
import moment from "moment";

export default {
    name: 'ComponentsHeadBlockMixer',
    components: {
    },
    props: [
        'loading',
        'getDate',
        'filters',
        'showDate',
        'editView',
    ],
    mixins: [
        mobileCheckMixin, iconsMixin
    ],
    data() {
        return {
            filtersData: this.filters,
        }
    },
    mounted() {
    },
    methods: {
    },
    computed: {
        currentDayName() {
            const format = "YYYY-MM-DD";
            const date = moment(this.getDate).toDate()
            const currentMomentDate = moment(date).format(format)
            const today = moment().format(format);

            if (currentMomentDate === today) {
                return 'Сегодня'
            }

            const tomorrow = moment().add({day: 1}).format(format);

            if (currentMomentDate === tomorrow) {
                return 'Завтра'
            }

            const yesterday = moment().add({day: -1}).format(format);

            if (currentMomentDate === yesterday) {
                return 'Вчера'
            }

            const lowerDate = moment(date, null, 'ru').format('dddd')
            return lowerDate.substring(0, 1).toUpperCase() + lowerDate.substring(1)
        }
    },
}
</script>

<style scoped lang="scss">

</style>
