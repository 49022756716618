<template>
    <Auth title="Спецификации" :tabs="tabs">
        <div class="card">
            <div class="card-body lab-card-body">

                <TableHeadBlock
                    :new="true"
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    @moduleGet="moduleGet()"
                    create-button-label="Создать спецификацию"
                    @openCreateValueDrawer="() => $router.push({ name: 'LabGoodsCreateSpec' })"
                >
                <template v-slot:beforeButtonsBlock>
                    <div class="ml-12 d-flex align-center gap-16">
                        <DropdownFilterCountButton label="Активные" @click="setFilter('active')" :isActive="filter.active" :counter="activeGoods.length" />
                        <DropdownFilterCountButton label="Без рецептов" @click="setFilter('withoutRecipes')" :isActive="filter.withoutRecipes" :counter="noRecipesGoods.length" />
                        <DropdownFilterCountButton label="Без продукции" @click="setFilter('withoutGoods')" :isActive="filter.withoutGoods" :counter="noProductsGoods.length" />

                        <BadgeFilterButton class="ml-8" :badgeAmount="badgeAmount" ref="badgeFilter" @visibleChangeDropdown="visibleChangeDropdown">
                            <template v-slot:filter-dropdown>
                                <div class="d-flex f-direction-column gap-20">
                                    <div class="d-flex f-direction-column gap-8">
                                        <MultipleChoiceFormSelect
                                            label="Класс бетона (В)"
                                            placeholder="В22,5"
                                            v-model="concreteClasses"
                                            :options="classes"
                                        />
                                        <div class="d-flex f-direction-column gap-8">
                                            <hr class="w-100 mb-0"/>
                                            <el-tooltip placement="bottom" content="Нельзя выбрать спецификации в архиве, пока выбраны активные" :disabled="!filter.active">
                                                <DropdownFilterCheckbox v-model="isArchived" label="В архиве" :disabled="filter.active" />
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div class="d-flex d-flex-end">
                                        <el-button @click="resetBadgeFilters">Сброс</el-button>
                                        <el-button @click="applyBadgeFilters" type="primary">Применить</el-button>
                                    </div>
                                </div>
                            </template>
                        </BadgeFilterButton>

                        <el-tooltip content="Запросить с АСУ" placement="top" :disabled="windowWidth > 1300">
                            <el-button v-if="integrations.length" class="ci-btn lab-card-body__asu-button"
                                   :icon="windowWidth <= 1300 ? icons.switch : null"
                                   @click="generateTaskForGetRecipes()"
                                >Запросить с АСУ
                            </el-button>
                        </el-tooltip>
                    </div>
                </template>
                </TableHeadBlock>

                <EmptyState
                    :values="displayData"
                    :search="search"
                    :loading="loading"
                    list-types="спецификаций"
                />

                <el-table
                    v-loading="loading"
                    v-if="displayData.length > 0"
                    :data="displayData"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="90"
                    />
                    <el-table-column
                        prop="name"
                        label="Спецификация"
                    >
                        <template v-slot="scope">
                            <div class="d-flex gap-8 align-center">
                                <a class="text-none textMedium primary600"
                                   :class="[scope.row?.actual ? 'primary600' : 'neutral400']"
                                    size="small"
                                    :href="'/settings/goods/' + scope.row.id"
                                    target="_blank">
                                    <span v-html="highlightMatch(scope.row.name, search)"></span>
                                </a>
                                <Label v-if="!scope.row?.actual" text="В архиве" :bold="true"/>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="specGoods"
                        label="Продукция"
                    >
                        <template v-slot="scope">
                            <template v-if="scope.row.specGoods.length">
                                <a class="text-none textMedium neutral900 d-block"
                                   size="small"
                                   v-for="(product, index) in scope.row.specGoods" :key="index"
                                   :href="'/settings/goods/' + product.id"
                                   target="_blank">
                                    <span :class="[product.default_spec ? 'textMedium' : 'textRegular']" v-html="highlightMatch(product.name, search)"></span>
                                    <span class="textMedium" v-if="product.default_spec"> (спецификация по ум.)</span>
                                    <span class="'textRegular neutral300 ml-5'"
                                          v-if="product.integration_unit_id">({{
                                            getIntegrationLabel(product.integration_unit_id)
                                        }})</span>
                                </a>
                            </template>
                            <div v-else class="textMedium neutral900">-</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Рецепты"
                    >
                        <template v-slot="scope">
                            <div v-if="scope.row.mixGoods.length">
                                <a class="text-none textRegular neutral900 d-block"
                                   v-for="(good, index) in scope.row.mixGoods" :key="index"
                                   size="small"
                                   :href="'/settings/goods/' + good.id"
                                   target="_blank"
                                   :class="{ 'mb-5': index < scope.row.mixGoods.length - 1 }">
                                    <span><span v-html="highlightMatch(good.name, search)"></span>
                                        <span class="textRegular neutral300 ml-5"
                                              v-if="good.integration_unit_id">({{
                                                getIntegrationLabel(good.integration_unit_id)
                                            }})</span></span>
                                </a>
                            </div>
                            <div v-else>
                                -
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="120px">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                @click="() => $router.push({ name: 'LabGoodsEditSpec', params: { id: $route.params.id, specGoodId: scope.row.id } })"
                            >
                                Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>


                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                    :current-page="page"
                    ref="tablePagination"
                />

                <div class="card labSpecView" v-if="false">
                    <div class="card-body">
                        <template v-if="selectedSpec">
                            <h3 class="neutral900 textMedium textLG">
                                {{ selectedSpec?.name }}
                            </h3>

                            <div class="textRegular textSM"
                                 v-if="selectedSpec.relation_group?.goods?.length && selectedSpec.relation_group.goods.some(el => el.id !== selectedSpec.id)">
                                <span class="neutral300">Связь с продукцией:</span>

                                <LabSpecGoods
                                    :goods="selectedSpec.relation_group.goods.filter(el => el.id !== selectedSpec.id)"
                                />
                            </div>

                            <LabSpecComponents
                                :components="selectedSpec.components"
                            />
                        </template>
                        <template v-else>
                            <div id="emptyState">
                                <EmptyListIcon/>
                                <h3 class="mb-0 mt-0 neutral900">Не выбрана спецификация</h3>
                                <p class="mt-0 textRegular textSM neutral400">Выберите или создайте
                                    спецификацию для работы</p>
                            </div>
                        </template>
                    </div>
                </div>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import LabTabs from "@/mixins/tabs/labTabs";
import EmptyListIcon from "@/views/components/Icons/EmptyListIcon.vue";
import LabSpecGoods from "@/views/lab/components/LabSpecGoods.vue";
import LabSpecComponents from "@/views/lab/components/LabSpecComponents.vue";
import {mapState, mapActions} from "pinia";
import {useCommonStore} from "@/store/common";
import BadgeFilterButton from '@/views/lab/BadgeFilterButton.vue';
import MultipleChoiceFormSelect from "@/views/components/Form/DropdownFilter/MultipleChoiceFormSelect.vue";
import concreteInfo from "@/mixins/concreteInfo";
import DropdownFilterCheckbox from "@/views/components/Form/DropdownFilter/DropdownFilterCheckbox.vue";
import DropdownFilterCountButton from '@/views/components/Form/DropdownFilter/DropdownFilterCountButton.vue';
import {useLabStore} from "@/store/lab/lab";
import Label from "@/views/components/Label/Label.vue";

export default {
    name: 'LabGoods',
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
        }),
        ...mapState(useLabStore, {
            labFilters: 'getFilters',
            labValues: 'getValues',
            isSpecUpdated: 'isSpecUpdated',
        }),
        activeGoods() {
            return this.filtered.filter((el) => el.specStatus.includes('active'))
        },
        noRecipesGoods() {
            return this.filtered.filter((el) => el.specStatus.includes('no_recipe'))
        },
        noProductsGoods() {
            return this.filtered.filter((el) => el.specStatus.includes('no_products'))
        },
        badgeAmount() {
            return Number(this.filter.archived) + Number(!!this.filter.classes.length)
        }
    },
    mixins: [LabTabs, tableMixin, iconsMixin, functionsMixin, mobileCheckMixin, concreteInfo],
    data() {
        return {
            loading: false,
            createDrawerOpen: false,
            selectedSpec: null,
            isArchived: false,
            concreteClasses: [],
            filter: {
                classes: [],
                archived: false,
                active: false,
                withoutRecipes: false,
                withoutGoods: false
            },
        }
    },
    components: {
        LabSpecComponents,
        LabSpecGoods,
        EmptyListIcon,
        TablePagination, EmptyState,
        TableHeadBlock,
        Auth,
        BadgeFilterButton,
        MultipleChoiceFormSelect,
        DropdownFilterCheckbox,
        DropdownFilterCountButton,
        Label
    },
    inject: [
        'api'
    ],
    created() {
        if(!this.labValues.length || this.isSpecUpdated) {
            this.moduleGet()
            this.setIsSpecUpdated(false)
        } else {
            this.values = this.labValues
            this.valueLength = this.values.length
        }
        this.setFiltersData()
    },
    methods: {
        ...mapActions(useLabStore, [
            'setLabFilter',
            'setLabValues',
            'setIsSpecUpdated'
        ]),
        highlightMatch(text, search) {
            if (!search) return text;

            const keywords = search.split(' ').filter(Boolean).map(kw => kw.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
            const regex = new RegExp(`(${keywords.join('|')})`, 'gi');

            return text.replace(regex, (match) => `<mark style='background: #FFF58A'>${match}</mark>`);
        },
        moduleGet() {
            this.loading = true

            httpService().post(this.api.lab.getSpecGoods, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.setLabValues(data.values)
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getIntegrationLabel(integrationUnitId) {
            let integrationUnit = this.integrations.find(integration => integration.id === integrationUnitId)
            let label = null

            if (integrationUnit) {
                let key = integrationUnit.integration.key

                label = key

                if (key === 'SmartMix') {
                    label = integrationUnit.product + ': ' + integrationUnit.account_module.name
                }
            }

            return label
        },
        setCreateDrawerOpen() {
            this.createDrawerOpen = true
        },
        searchFilter(arr, query) {
            const keywords = query.split(' ').filter(Boolean);

            if (keywords.length === 0) return [];

            let results = [...arr];

            for (const keyword of keywords) {
                results = results.filter(item => {
                    const searchCondition = item.name.toLowerCase().includes(keyword.toLowerCase());
                    const specGoodsCondition = item.specGoods?.some(specGood => specGood.name.toLowerCase().includes(keyword.toLowerCase()));
                    const mixGoodsCondition = item.mixGoods?.some(mixGood => (mixGood.name?.toLowerCase() ?? '').includes(keyword.toLowerCase()));
                    return searchCondition || specGoodsCondition || mixGoodsCondition;
                });

                if (results.length === 0) break;
            }
            return results;
        },
        filterValues() {
            const { archived, classes, active, withoutRecipes, withoutGoods } = this.filter;

            let filteredValues = [...this.values];

            if (archived !== undefined) {
                filteredValues = filteredValues.filter(data => archived ? !data.actual : data.actual);
            }

            if (this.search) {
                filteredValues = this.searchFilter(filteredValues, this.search);
            }

            if (classes?.length) {
                filteredValues = filteredValues.filter(data => {
                    const hasClass = data.class && classes.includes(data.class);
                    const hasSpecGoodClass = data.specGoods.some(specGood => classes.includes(specGood.class));
                    const hasMixGoodClass = data.mixGoods.some(mixGood => classes.includes(mixGood.class));

                    return hasClass || hasSpecGoodClass || hasMixGoodClass;
                });
            }

            if (active) {
                filteredValues = filteredValues.filter(data => data.specStatus.includes('active'));
            }

            if (withoutRecipes) {
                filteredValues = filteredValues.filter(data => data.specStatus.includes('no_recipe'));
            }

            if (withoutGoods) {
                filteredValues = filteredValues.filter(data => data.specStatus.includes('no_products'));
            }

            const paginatedValues = filteredValues.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);

            this.filtered = filteredValues;
            this.valueLength = filteredValues.length;

            return paginatedValues;
        },
        generateTaskForGetRecipes() {
            httpService().post(this.api.good.generateTaskForGetRecipes, {})
                .then((response) => {
                    let data = response.data;

                    if (data.success) {
                        this.$message({
                        message: 'Запрос отправлен. Ожидайте',
                        showClose: true,
                        type: 'success',
                    });
                    }
                })
                .catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        type: 'error',
                        showClose: true,
                    })
                });
        },
        setFilter(name) {
            this.filter[name] = !this.filter[name]
        },
        resetBadgeFilters() {
            this.filter.classes = [];
            this.concreteClasses = [];
            this.filter.archived = false;
            this.isArchived = false;
            this.$refs.badgeFilter.$refs.dropdown.handleClose()
        },
        applyBadgeFilters() {
            this.filter.classes = this.concreteClasses
            this.filter.archived = this.isArchived
            this.$refs.badgeFilter.$refs.dropdown.handleClose()
        },
        setFiltersData() {
            this.search = this.labFilters.search
            this.page = this.labFilters.page
            this.pageSize = this.labFilters.pageSize
            this.isArchived = this.labFilters.archived
            this.filter.archived = this.labFilters.archived
            this.concreteClasses = this.labFilters.classes;
            this.filter.classes = this.labFilters.classes
            this.filter.active = this.labFilters.active
            this.filter.withoutRecipes = this.labFilters.withoutRecipes
            this.filter.withoutGoods = this.labFilters.withoutGoods
        },
        visibleChangeDropdown(visible) {
            if(!visible) {
                this.concreteClasses = this.filter.classes;
                this.isArchived = this.filter.archived
            }
        }
    },
    watch: {
        'filter.active': {
            handler(val) {
                if(val) {
                    this.filter.archived = false;
                    this.isArchived = false;
                }
                this.setLabFilter('active', val)
            }
        },
        page: {
            handler(val) {
                this.setLabFilter('page', val)
            }
        },
        pageSize: {
            handler(val) {
                this.setLabFilter('pageSize', val)
            }
        },
        search: {
            handler(val) {
                this.setLabFilter('search', val)
            }
        },
        'filter.archived': {
            handler(val) {
                this.setLabFilter('archived', val)
            }
        },
        'filter.classes': {
            handler(val) {
                this.setLabFilter('classes', val)
            }
        },
        'filter.withoutRecipes': {
            handler(val) {
                this.setLabFilter('withoutRecipes', val)
            }
        },
        'filter.withoutGoods': {
            handler(val) {
                this.setLabFilter('withoutGoods', val)
            }
        },
    }
}
</script>

<style scoped>
.lab-card-body:deep(.el-input__wrapper) {
    width: 100%;
    @media screen and (min-width: 1130px) {
        min-width: 300px;
    }
}


.lab-good-select {
    flex-direction: column;
}

.lab-card-body__asu-button {
    @media screen and (max-width:1300px) {
        width: 62px;

        &:deep(span) {
            display: none;
        }
        &:deep(.el-icon) {
            rotate: 90deg;
            width: 24px;
            height: 24px;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
</style>
