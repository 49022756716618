<template>
    <svg width="20" height="20" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99935 9.33365C10.7357 9.33365 11.3327 8.73672 11.3327 8.00032C11.3327 7.26392 10.7357 6.66699 9.99935 6.66699C9.26295 6.66699 8.66602 7.26392 8.66602 8.00032C8.66602 8.73672 9.26295 9.33365 9.99935 9.33365Z" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.334 12C16.174 10.8867 16.6673 9.5 16.6673 8C16.6673 6.5 16.174 5.11333 15.334 4" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.66732 4C3.82732 5.11333 3.33398 6.5 3.33398 8C3.33398 9.5 3.82732 10.8867 4.66732 12" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.1992 10.3996C13.6992 9.73293 13.9993 8.89959 13.9993 7.99959C13.9993 7.09959 13.6992 6.26627 13.1992 5.59961" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.80001 5.59961C6.30001 6.26627 6 7.09959 6 7.99959C6 8.89959 6.30001 9.73293 6.80001 10.3996" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/>
        <path v-if="disabled" d="M1 8H19" stroke="#C4C4C4" stroke-width="0.5" stroke-linecap="round"/>
        </svg>
</template>

<script>
export default {
    name: "SignalIcon",
    props: ['currentColor', 'disabled']
}
</script>

<style scoped lang="scss">
.icon {
    &--disabled {
        position: relative;

        path {
            stroke: #c4c4c4;
        }
    }
}
</style>
