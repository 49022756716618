<template>
    <el-form-item :label="label" :label-width="labelWidth" v-bind:class="[extraClass, { 'is-required': required }]">
            <div class="inputModal"
                 :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                 ]">
                <div class="d-flex">
                    <div class="inputModalWrapper" @click="openSelectAddressDialog">
                        {{ itemId ? getAddressName : this.placeholder }}
                    </div>
                    <div class="inputModalClear" v-if="itemId" @click="clearItemId()">
                        <i class="el-icon el-input__icon el-input__clear">
                            <CloseRoundIcon/>
                        </i>
                    </div>
                </div>
            </div>
    </el-form-item>

    <el-dialog
        v-model="dialog"
        :title="getDialogTitle()"
        :width="mobile ? '100%' : '70%'"
        :destroy-on-close="true"
    >
        <el-form ref="form" v-loading="loading" :model="form" size="small" label-position="top">
                <AddressDeliveryModalCreate
                    :company-id="companyId"
                    :company-name="additionalInfo"
                    @onAdd="handleAddAddress"
                />
        </el-form>
    </el-dialog>

    <el-dialog
        v-model="selectAddressDialog"
        title="Выберите объект"
        :width="dialogSize"
        @close="clearSelectAddressDialog"
        :destroy-on-close="true"
    >
        <template v-if="loadingData">
            <TableDataLoader text="адресов"/>
        </template>
        <template v-else>
            <template v-if="!createAddressFormShow">

                <el-row :gutter="15">
                    <el-col :sm="16" class="d-flex f-direction-column">

                        <TableHeadBlock
                            v-model="search"
                            :loading="loading"
                            :disable-filter="true"
                            :disable-update-button="true"
                            :disable-create-button="disableCreateButton"
                            create-button-label="Добавить адрес"
                            @moduleGet="getAddressData()"
                            @openCreateValueDrawer="() => createAddressFormShow = true"
                        />
                        <el-table
                            empty-text="Информация не найдена"
                            class="mt-15 w-100 defaultTableHeight flex-1"
                            v-loading="loadingTable"
                            @current-change="handleCurrentChange"
                            @cellMouseEnter="hoverAddress"
                            :row-class-name="getRowClassName"
                            :data="displayData">

                            <el-table-column
                                prop="id"
                                label="#"
                                width="100"
                                class-name="pointer"
                                sortable>
                                <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="Название"
                                class-name="pointer"
                                sortable>
                                <template v-slot="scope">
                                    <div class="d-flex flex-wrap gap-8">
                                        <span class="textSM textMedium neutral900">{{
                                            scope.row.name ?? '-'
                                        }}</span>
                                        <Label
                                            v-if="!scope.row.latitude || !scope.row.longitude"
                                            :bold="true"
                                            color="red"
                                            text="Без координат" />
                                    </div>

                                </template>
                            </el-table-column>
                        </el-table>

                        <TablePagination
                            v-model="pageSize"
                            :total="valueLength"
                            :loading="loading"
                            @action="setPage"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <div class="mapContainer" ref="mapContainer">
                            <div v-if="coords" class="logisticsInfoMainMap" ref="map">
                                <div class="map">
                                    <YandexMap v-if="coords"
                                        :drag-off="true"
                                        :coords="coords"
                                    />
                                </div>
                            </div>
                            <div v-else class="d-flex-center justify-center d-flex-direction-column h-100 formSelectObject__withoutCoords" ref="withoutCoords">
                                <div class="d-flex-direction-column" v-if="!loadingCoords">
                                    <h3 class="text-500 mb-15 text-center">У адреса нет координат</h3>
                                    <el-button plain type="primary" @click="getCoords" :icon="icons.addLocation">Запросить координаты</el-button>
                                </div>
                                <TableDataLoader v-else text="координат" />
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </template>
            <template v-else>
                <el-form label-position="top">
                    <AddressDeliveryModalCreate
                        :company-id="companyId"
                        :company-name="additionalInfo"
                        @onAdd="handleAddAddress"
                    />
                </el-form>
            </template>
        </template>
    </el-dialog>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import AddressDeliveryModalCreate from "@/views/components/AddressDeliveryModalCreate.vue";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import YandexMap from '@/views/widgets/map/YandexMap';
import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import CloseRoundIcon from "@/views/components/Icons/CloseRoundIcon.vue";
import iconsMixin from "@/mixins/iconsMixin";
import Label from "@/views/components/Label/Label.vue";
import {ElMessage} from "element-plus";

export default {
    name: 'FormSelectObject',
    components: {
        TableDataLoader,
        TableHeadBlock,
        TablePagination,
        AddressDeliveryModalCreate, YandexMap, CloseRoundIcon, Label
    },
    mixins: [functionsMixin, mobileCheckMixin, tableMixin, iconsMixin],
    inject: ['api'],
    props: [
        'modelValue',
        'items',
        'label',
        'labelWidth',
        'placeholder',
        'loadFromStore',
        'labelKey',
        'loading',
        'companyId',
        'disabled',
        'carrierCompany',
        'companyType',
        'required',
        'additionalInfo',
        'extraClass', // дополнительные классы для стилизации,
        'disableCreateButton' // cкрытие кнопки добавления нового элемента в модалке
    ],
    data() {
        return {
            itemId: this.modelValue,
            itemWithoutCoords: null,
            loadingButton: false,
            dialog: false,
            filters: {
                companyType: null,
                carrier: null,
            },
            loadingData: true,
            loadingTable: false,
            loadingCoords: false,
            addressData: [],
            selectAddressDialog: false,
            mapZoom: 13,
            latitude: 0,
            longitude: 0,
            createAddressFormShow: false,
            firstOpen: true
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            modules: 'getModules',
        }),
        coords() {
            if (this.selectAddressDialog) {
                if (!this.latitude || !this.longitude) {

                    // if (this.modules.length > 0) {
                    //     let key = this.$route.meta.moduleKey
                    //     let id = this.$route.params.id
                    //     let module = this.modules.find((m) => m.id == id)

                    //     if (key === 'dispatch' || key === 'crm') {
                    //         module = this.modules.find((m) => m.accountModuleId == id)
                    //     }

                    //     if (module) {
                    //         if (module.factory) {
                    //             return [parseFloat(module.factory.latitude), parseFloat(module.factory.longitude)];
                    //         }
                    //     }
                    // }
                    // return [55.75165053565251, 37.61758420869977];
                    return null
                }

                return [parseFloat(this.latitude), parseFloat(this.longitude)];
            } else {
                return [55.75165053565251, 37.61758420869977];
            }
        },
        getAddressName() {
            if (this.itemId) {
                return this.getInfo(this.itemId, this.values)
            } else {
                return null
            }
        },
    },
    created() {
        this.firstOpen = false
    },
    methods: {
        getLabel(item) {
            if (this.labelKey) {
                    let label = this.deepFind(item, this.labelKey);
                    if (label) {
                        return label
                    }
                }

            if (!this.loadFromStore) {
                return item.name
            } else {
                let param = this.loadFromStore
                return this.getData(item[param], 'good')
            }
        },
        handleCurrentChange(val) {
            if(!val) return
            if (!val?.latitude || !val?.longitude) {
                this.itemWithoutCoords = val
            } else {
                this.selectItem(val.id)
                this.$emit('getChangedValue', val)
                this.itemWithoutCoords = null
            }
        },
        openSelectAddressDialog() {
            if(this.firstOpen && this.values.length === 0) {
                this.getAddressData()
                this.firstOpen = false;
                return
            }

            if (!this.disabled) {
                this.search = null
                this.page = 1
                if (this.page === 1) {
                    this.getAddressData()
                }

                if (this.itemId) {

                    this.values.sort((a, b) => {
                        if (a.id === this.itemId) return -1;
                        if (b.id === this.itemId) return 1;
                        return 0;
                    });
                }

                this.createAddressFormShow = false
                this.selectAddressDialog = true
            }
        },
        getAddressData() {
            this.loadingTable = true
            httpService().post(this.api.company.getCompanyAddresses, {
                companyId: this.companyId,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                full: true
            }).then((response) => {

                let data = response.data

                if (data.success) {
                    this.values = data.items
                    this.valueLength = data.items.length
                    this.loadingData = false
                    this.loadingTable = false
                }

                if (this.itemId) {
                    let elem = this.values.find(el => el.id === this.itemId)
                    if (elem) {
                        this.latitude = elem.latitude
                        this.longitude = elem.longitude
                    }
                    this.values.sort((a, b) => {
                        if (a.id === this.itemId) return -1;
                        if (b.id === this.itemId) return 1;
                        return 0;
                    });
                }
            })
        },
        selectItem(id) {
            this.itemId = id
            this.$emit('changeValue')
            this.selectAddressDialog = false
        },
        filterValues() {
            if(this.items) {
                this.filtered = this.items
            } else this.filtered = this.values
            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || (data.name.toLowerCase().includes(this.search.toLowerCase()) || data.address.toLowerCase().includes(this.search.toLowerCase())))
            }

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        getDialogTitle() {
            return 'Добавление адреса доставки'
        },
        hoverAddress(e) {
            if(this.itemWithoutCoords && (!this.itemWithoutCoords.latitude || !this.itemWithoutCoords.longitude)) {
                this.latitude = null
                this.longitude = null
                return
            }
            this.latitude = e.latitude
            this.longitude = e.longitude
        },
        handleAddAddress({values, addressId}) {
            this.values = values;
            this.itemId = addressId;
            this.dialog = false;
            this.selectAddressDialog = false
        },
        clearItemId() {
            this.itemId = null
            this.$emit('clearAction')
        },
        getCoords() {
            this.loadingCoords = true
            httpService().post(this.api.company.getCoordinatesByAddress, {
                id: this.itemWithoutCoords.id,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    const {geo_lon, geo_lat} = data.coordinates
                    this.longitude = geo_lon
                    this.latitude = geo_lat
                    this.itemWithoutCoords.latitude = geo_lat;
                    this.itemWithoutCoords.longitude = geo_lon;
                    this.getAddressData()
                }
            }).catch(() => {
                ElMessage({
                    message: 'Не удалось получить координаты. Попробуйте еще раз',
                    type: 'error'
                 });
            }).finally(() => {
                this.loadingCoords = null
            })
        },
        getRowClassName({ row }) {
            return row.id === this.itemWithoutCoords?.id ? "selected-row" : "";
        },
        clearSelectAddressDialog() {
            this.itemWithoutCoords = null
        },
        resizeModal() {
            const mapContainer = this.$refs.mapContainer;
            const map = this.$refs.map;

            if(mapContainer && map) {
                mapContainer.style.minHeight = `${map.clientHeight}px`;
            }
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemId = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'itemId': {
            handler: function () {
                if(!this.itemWithoutCoords) {
                   this.$emit('update:modelValue', this.itemId)
                    if (this.itemId) {
                        this.getAddressData()
                    }
                }

            },
            deep: true,
            immediate: true
        },
        'items': {
            handler: function (val, prevVal) {
                if (this.items) {
                        if(this.items.length) {
                            this.values = this.items
                        }
                } else if(!val?.length && prevVal?.length) {
                    this.getAddressData()
                }
            },
            deep: true,
            immediate: true
        },
        'companyId': {
            handler: function (val) {
                // if (this.itemId && !this.items?.length) {
                if(val) {
                    this.getAddressData()
                }
            },
            deep: true,
            immediate: true
        },
        page() {
            this.resizeModal()
        }
    }
}
</script>

<style scoped lang="scss">
.map {
    width: 100%;
    height: 100%;
}

.mapContainer {
    width: 100%;
    height: 100%;
}

.formSelectObject {
    &__withoutCoords {
        min-height: 530px;
        height: 100%;
        max-height: 100%;
    }
}
</style>

<style>
.selected-row {
    background-color: #f0f9ff !important;
  }
</style>
