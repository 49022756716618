<template>
    <svg
        :class="{'currentColor': currentColor}"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_8584_46140)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 2H4C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2ZM4 0C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0H4Z"
                fill="#49515F"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 19L10 2L12 2L12 19H10Z"
                fill="#49515F"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19 8L12 8V6L19 6V8Z"
                fill="#49515F"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 14L12 14V12L20 12V14Z"
                fill="#49515F"
            />
        </g>
        <defs>
            <clipPath id="clip0_8584_46140">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "MultipleViewIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path {
            fill: currentColor;
        }
    }
</style>
