<template>
    <div>
        <div class="d-flex-full">
            <el-input
                class="searchNew w-100 mr-10"
                v-model="search"
                :prefix-icon="icons.search"
                placeholder="Поиск" clearable/>
            <el-button
                type="info"
                class="mr-10"
                @click="changeFactory(null)"
                :icon="icons.remove"
                plain
                style=" height: 46px; width: 46px; border-radius: 8px;"/>
        </div>
        <el-table
            :data="displayData"
            class="w-100 mt-15 mb-15 selectDialogFactory">
            <el-table-column
                prop="name"
                label=""
            />
            <el-table-column
                fixed="right"
                label=""
                width="120px">
                <template v-slot="scope">
                    <el-button
                        size="small"
                        class="w-100"
                        @click="changeFactory(scope.row.id)"
                    >
                        Выбрать
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <TablePagination
            v-model="pageSize"
            :total="valueLength"
            @action="setPage"
        />
    </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";

export default {
    name: "SelectDialogFactory",
    components: {TablePagination},
    props: ['items'],
    mixins: [tableMixin, iconsMixin],
    inject: ['api'],
    data() {
        return {
            form: {
                name: null,
                phone: null
            },
        }
    },
    created() {
        this.values = this.items
    },
    methods: {
        changeFactory(id) {
            this.$emit('changeFactory', id)
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
    }
}
</script>

<style scoped lang="scss">
    .selectDialogFactory {
        &:deep(.el-table__row) {
            &:hover {
                &, .el-table__cell {
                    background: #f9f9f9;
                    transition: all .3s;
                }
            }
        }
    }
</style>
