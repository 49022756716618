<template>
    <TableHeadBlock
        :new="true"
        v-model="search"
        :loading="loading"
        :disable-create-button="true"
        :disable-update-button="true"
        :disable-filter="true"
    />

    <el-table
        empty-text="Информация не найдена"
        class="mt-15 w-100 defaultTableHeight"
        v-loading="loading"
        @current-change="handleCurrentChange"
        :data="displayData"
    >

        <el-table-column
            prop="id"
            label="#"
            class-name="pointer"
            width="100"
            sortable>
            <template v-slot="scope">
                <el-tooltip
                    effect="dark"
                    placement="left-start"
                >
                    <template #content>
                        <div class="d-flex-full" style="min-width: 220px">
                            <span class="deep-blue200">Водитель:</span>
                            <span>{{ driverLabel(scope.row) }}</span>
                        </div>
                        <div class="d-flex-row">
                            <span class="deep-blue200">Заводы:</span>
                            <span class="d-flex f-direction-column">
                                <span v-for="item in mixLabel(scope.row)" :key="item">
                                    {{ item }}
                                </span>
                            </span>
                        </div>
                        <div class="d-flex-full">
                            <span class="deep-blue200">Вместимость:</span>
                            <span>{{ `${scope.row.vehicle.volume} м³` }}</span>
                        </div>
                        <div>
                            <hr>
                        </div>
                        <div>Доставки</div>
                        <div class="d-flex-full">
                            <span class="deep-blue200">Количество:</span>
                            <span>{{ scope.row.count_orders }}</span>
                        </div>
                        <div class="d-flex-full">
                            <span class="deep-blue200">Общий объём:</span>
                            <span>{{ `${scope.row.total_volume} м³`}}</span>
                        </div>
                    </template>
                    <span class="textSM textMedium"
                          :class="order.vehicle_id === scope.row.vehicle.id ? 'primary600' : 'neutral200'">
                            #{{ formatNumber(scope.row.vehicle.id) }}
                    </span>
                </el-tooltip>

            </template>
        </el-table-column>

        <el-table-column
            prop="rent"
            label=""
            width="36"
            class-name="pointer"
        >
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.vehicle.rent ? 'Арендованное ТС' : 'Собственное ТС'"
                    placement="top"
                >
                    <span :class="'textSM textMedium ' + ( scope.row.vehicle.rent ? 'neutral500' : 'neutral100' )">
                        А
                    </span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column
            prop="gidrolotok"
            label=""
            width="36"
            class-name="pointer"
        >
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.vehicle.gidrolotok ? 'Есть гидролоток' : 'Нет гидролотка'"
                    placement="top"
                >
                    <span
                        :class="'textSM textMedium ' + ( scope.row.vehicle.gidrolotok ? 'primary500' : 'neutral100' )">
                        Г
                    </span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column
            prop="name"
            label="Название"
            class-name="pointer"
            width="220"
            sortable
        >
            <template v-slot="scope">
                <span class="textSM textMedium neutral900 cutOverflow">
                    {{ scope.row.vehicle.number ?? '-' }}
                </span>

            </template>
        </el-table-column>

        <el-table-column
            prop="time"
            label="График"
            class-name="pointer"
        >
            <template v-slot="scope">
                <span class="textSM textRegular neutral400 cutOverflow">
                    {{ scope.row.start_time + ' - ' + scope.row.end_time }}
                </span>
            </template>
        </el-table-column>

    </el-table>

    <TablePagination
        :disableSize="true"
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>

import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import moment from "moment/moment"

export default {
    name: "MixOrderDetailsGroupEditSelectVehicles",
    components: {TableHeadBlock, TablePagination},
    mixins: [tableMixin, functionsMixin],
    props: ['order', 'drivers', 'mixers'],
    inject: ['api'],
    data() {
        return {
            loading: true,
        }
    },
    created() {
        this.getSchedules()
    },
    methods: {
        getSchedules() {
            httpService().post(this.api.vehicle.getVehicleSchedulesForDay, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.order.date ?? moment()
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.schedules
                    this.valueLength = data.schedules.length
                    this.loading = false
                }
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || (data.vehicle.name.toLowerCase().includes(this.search.toLowerCase()) || data.vehicle.number.toLowerCase().includes(this.search.toLowerCase())))
            this.valueLength = this.filtered.length

            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleCurrentChange(val) {
            if (val) {
                this.$emit('updateItem', val)
            }
        },
        driverLabel(row) {
            const driver = this.drivers.find(el => el.id === row.driver_id)

            const parts = driver.name.trim().split(' ');
            if (parts.length < 2) return driver.name; // Если только одно слово

            const surname = parts[0]; // Фамилия
            const initials = parts.slice(1).map(part => `${part[0].toUpperCase()}.`).join(' '); // Инициал имени и отчества
            return `${surname} ${initials}`;
        },
        mixLabel(row) {
            return row.mixers.map(mixer => {
                const matched = this.mixers.find(t => t.id === mixer.id);
                return matched ? matched.name : null;
            });
        },
    }
}
</script>
