<template>
    <el-badge class="dropdown-filter-count" :value="counter" type="primary" :max="99" :show-zero="false">
        <el-button class="dropdown-filter-count-button" :class="{ 'dropdown-filter-count-button--active': isActive }">
            {{label}}
        </el-button>
    </el-badge>
</template>

<script>
export default {
    name: "DropdownFilterCountButton",
    props: ['label', 'counter', 'isActive'],
    data() {
        return {
        }
    },
    watch: {
    }

}
</script>

<style scoped lang="scss">
@use "@/sass/_variables.scss";

.dropdown-filter-count-button {
    &--active {
        color: variables.$primary600 !important;
        border-color: variables.$primary600 !important;
        background-color: variables.$primary50 !important;
    }
}

.dropdown-filter-count {
    &:deep(.el-badge__content) {
        padding-inline: 5px;
    }
}

</style>
