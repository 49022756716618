<template>
    <DrawerFramework class="drawerBordered" :loading="loading">
        <template v-slot:body>
            <div class="mixOrderDetails" v-if="orderDetails">
                <h2>{{ getTitle() }}</h2>
                <span class="color-main text-light d-block mb-20 d-block pointer"
                      @click="changeStatus()">
                    {{ getStatusLabel() }} | {{getDate() }}
                </span>

                <!--                <div class="mixOrderDetailsBlock">-->
                <!--                    <p class="mixOrderDetailsBlockTitle">Продукция и объем</p>-->

                <!--                    <div class="d-flex-full">-->
                <!--                        <div>-->
                <!--                            <h3>-->
                <!--                                <template v-if="orderDetails.goods">-->
                <!--                                    <span style="overflow-wrap: anywhere"-->
                <!--                                        v-for="(-->
                <!--                                            item, index-->
                <!--                                        ) in orderDetails.goods"-->
                <!--                                        :key="index"-->
                <!--                                    >-->
                <!--                                        {{ getData(item.good.good_id, "good")-->
                <!--                                        }}<span style="overflow-wrap: anywhere"-->
                <!--                                            v-if="-->
                <!--                                                index !==-->
                <!--                                                orderDetails.goods.length - 1-->
                <!--                                            "-->
                <!--                                            >,-->
                <!--                                        </span>-->
                <!--                                    </span>-->
                <!--                                </template>-->
                <!--                                <span v-else>-</span>-->
                <!--                            </h3>-->
                <!--                        </div>-->

                <!--                        <div class="d-flex" style="flex-shrink: 0">-->
                <!--                            <h3>-->
                <!--                                <template v-if="orderDetails.total">-->
                <!--                                    {{-->
                <!--                                        orderDetails.done-->
                <!--                                            ? formatNumber(orderDetails.done, 2)-->
                <!--                                            : 0-->
                <!--                                    }}-->
                <!--                                    <good-unit-->
                <!--                                        :unit="-->
                <!--                                            orderDetails.goods-->
                <!--                                                ? getUnits(orderDetails.goods)-->
                <!--                                                : ''-->
                <!--                                        "-->
                <!--                                    />-->
                <!--                                    <span class="color-main text-light">-->
                <!--                                        из-->
                <!--                                        {{-->
                <!--                                            orderDetails.total-->
                <!--                                                ? formatNumber(-->
                <!--                                                      orderDetails.total,-->
                <!--                                                      2-->
                <!--                                                  )-->
                <!--                                                : 0-->
                <!--                                        }}-->
                <!--                                        <good-unit-->
                <!--                                            :unit="-->
                <!--                                                orderDetails.goods-->
                <!--                                                    ? getUnits(-->
                <!--                                                          orderDetails.goods-->
                <!--                                                      )-->
                <!--                                                    : ''-->
                <!--                                            "-->
                <!--                                    /></span>-->
                <!--                                </template>-->
                <!--                            </h3>-->

                <!--                            <el-progress-->
                <!--                                color="#00cdc4"-->
                <!--                                style="margin-left: 24px"-->
                <!--                                type="circle"-->
                <!--                                :show-text="false"-->
                <!--                                :stroke-width="8"-->
                <!--                                :width="32"-->
                <!--                                :percentage="-->
                <!--                                    Math.round(-->
                <!--                                        (orderDetails.done /-->
                <!--                                            orderDetails.total) *-->
                <!--                                            100-->
                <!--                                    )-->
                <!--                                "-->
                <!--                            />-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->

                <el-tabs v-model="activeTab" class="mt-15">
                    <el-tab-pane label="Общая информация" name="info">
                        <MixPageOrderDetailsInfo
                            :orderDetails="orderDetails"
                        />
                    </el-tab-pane>
<!--                    <el-tab-pane label="Экономика" name="economies">-->
<!--                        <el-row :gutter="10">-->
<!--                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">-->
<!--                                <div class="mixOrderDetailsBlock">-->
<!--                                    <p class="mixOrderDetailsBlockTitle">Чистая прибыль</p>-->

<!--                                    <template v-if="orderDetails.economy.totalProfit > 0">-->
<!--                                        <h3 style="color: #00cdc4 !important">-->
<!--                                            {{-->
<!--                                                formatNumber(-->
<!--                                                    orderDetails.economy.totalProfit-->
<!--                                                )-->
<!--                                            }}-->
<!--                                            руб.-->
<!--                                        </h3>-->
<!--                                    </template>-->
<!--                                    <template-->
<!--                                        v-else-if="orderDetails.economy.totalProfit < 0"-->
<!--                                    >-->
<!--                                        <h3 style="color: #ff5253 !important">-->
<!--                                            - -->
<!--                                            {{-->
<!--                                                formatNumber(-->
<!--                                                    Math.abs(-->
<!--                                                        orderDetails.economy.totalProfit-->
<!--                                                    )-->
<!--                                                )-->
<!--                                            }}-->
<!--                                            руб.-->
<!--                                        </h3>-->
<!--                                    </template>-->
<!--                                    <template v-else>-->
<!--                                        <span class="headingXS headingRegular">Н/Д</span>-->
<!--                                        <Tooltip/>-->
<!--                                    </template>-->
<!--                                </div>-->
<!--                            </el-col>-->
<!--                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">-->
<!--                                <div class="mixOrderDetailsBlock">-->
<!--                                    <p class="mixOrderDetailsBlockTitle">Расходы</p>-->
<!--                                    <template v-if="orderDetails.economy.totalCosts !== 0">-->
<!--                                        <h3>-->
<!--                                            {{-->
<!--                                                formatNumber(-->
<!--                                                    orderDetails.economy.totalCosts-->
<!--                                                )-->
<!--                                            }}-->
<!--                                            руб.-->
<!--                                        </h3>-->
<!--                                    </template>-->
<!--                                    <template v-else>-->
<!--                                        <span class="headingXS headingRegular">Н/Д</span>-->
<!--                                        <Tooltip/>-->
<!--                                    </template>-->
<!--                                </div>-->
<!--                            </el-col>-->
<!--                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">-->
<!--                                <div class="mixOrderDetailsBlock">-->
<!--                                    <p class="mixOrderDetailsBlockTitle">-->
<!--                                        Сумма реализации-->
<!--                                    </p>-->
<!--                                    <template v-if="orderDetails.economy.totalPrice !== 0">-->
<!--                                        <h3>-->
<!--                                            {{-->
<!--                                                formatNumber(-->
<!--                                                    orderDetails.economy.totalPrice-->
<!--                                                )-->
<!--                                            }}-->
<!--                                            руб.-->
<!--                                        </h3>-->
<!--                                    </template>-->
<!--                                    <template v-else>-->
<!--                                        <span class="headingXS headingRegular">Н/Д</span>-->
<!--                                        <Tooltip/>-->
<!--                                    </template>-->
<!--                                </div>-->
<!--                            </el-col>-->
<!--                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">-->
<!--                                <div class="mixOrderDetailsBlock">-->
<!--                                    <p class="mixOrderDetailsBlockTitle">Рентабельность</p>-->
<!--                                    <template v-if="getProfitability() !== 0">-->
<!--                                        <h3>{{ formatNumber(getProfitability()) }}%</h3>-->
<!--                                    </template>-->
<!--                                    <template v-else>-->
<!--                                        <span class="headingXS headingRegular">Н/Д</span>-->
<!--                                        <Tooltip/>-->
<!--                                    </template>-->
<!--                                </div>-->
<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                        <MixPageOrderDetailsEconomy-->
<!--                            v-if="orderDetails.id"-->
<!--                            :order="orderDetails"-->
<!--                            :unit="-->
<!--                                orderDetails.goods-->
<!--                                    ? getUnits(orderDetails.goods)-->
<!--                                    : ''-->
<!--                            "-->
<!--                            :total="orderDetails.total"-->
<!--                        />-->
<!--                    </el-tab-pane>-->

                    <el-tab-pane label="Замесы" name="batches">
                        <div v-if="orderDetails.mixes.length > 0">
                            <MixPageOrderDetailsOneMix
                                :order-id="orderDetails.id"
                                @moduleGet="getOrder(true)"
                                v-for="(mix, index) in orderDetails.mixes"
                                :key="index"
                                :mix="mix"
                                :goodId="orderDetails.goodId"
                                :tz="orderDetails.tz"
                            />
                        </div>

                        <div v-else>
                            <div class="no-data-block">
                                <NoDataIcon/>
                                <p>
                                    Нет информации о замесах<br/>для данной
                                    отгрузки
                                </p>
                                <el-button
                                    size="small"
                                    :icon="icons.plus"
                                    class="mt-15"
                                    @click="openMixOrderCreateMix"
                                >
                                    Добавить замес
                                </el-button>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Документы" name="docs">
                        <div class="d-flex-full-stretch">
                            <div class="infoBlock">
                                <div class="textSM textRegular neutral300">Документация</div>
                                <div class="textMD textMedium neutral700">Паспорт продукции</div>
                                <div class="textSM textRegular primary500 mt-10 pointer" @click="print('passport')">Открыть документ</div>
                            </div>
                            <div class="infoBlock">
                                <div class="textSM textRegular neutral300">Документация</div>
                                <div class="textMD textMedium neutral700">Накладная</div>
                                <div class="textSM textRegular primary500 mt-10 pointer" @click="print('invoice')">Открыть документ</div>
                            </div>

                            <div class="infoBlock">
                                <div class="textSM textRegular neutral300">Документация</div>
                                <div class="textMD textMedium neutral700">Пропуск</div>
                                <div class="textSM textRegular primary500 mt-10 pointer" @click="printOrderPass()">Открыть документ</div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="История" name="log" v-if="$route.meta.moduleKey === 'dispatch'">
                        <MixOrderDetailsGroupLog :mix-order-id="orderDetails.id" :tab="activeTab"/>
                    </el-tab-pane>
                    <el-tab-pane label="Тех.лог" name="history">
                        <MixPageOrderDetailsHistory :id="orderDetails.id" :tab="activeTab"/>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </template>
        <template v-slot:footer>
            <DrawerButton
                :loading="loading"
                text="Отправить данные на завод"
                icon="switch"
                @action="sendMixOrderToSoft"
            />
            <!--            <DrawerAddButton-->
            <!--                :loading="loading"-->
            <!--                label="Добавить замес"-->
            <!--                @action="openMixOrderCreateMix"-->
            <!--            />-->
            <DrawerEditButton
                :loading="loading"
                @action="$emit('openEdit', orderDetails)"
            />
            <DrawerDeleteButton
                :loading="loading"
                @action="deleteOrder(orderDetails.id)"
            />
        </template>
    </DrawerFramework>

    <el-dialog
        v-model="createMixOrderMixDialog"
        title="Новый замес"
        :width="mobile ? '100%' : '65%'"
        :destroy-on-close="true"
    >
        <MixPageOrderMixCreate
            :order-id="orderDetails.id"
            :create-mix="orderDetails.createMix"
            :mixGoods="orderDetails.goods"
            @closeDialog="closeMixOrderCreateMix"
            @reset="setOrderDetailsCreateMix(false)"
            @moduleGet="getOrder(true)"
        />

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeMixOrderCreateMix">
                    Отмена
                </el-button>
                <el-button
                    type="primary"
                    @click="setOrderDetailsCreateMix(true)"
                >
                    Создать замес
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import moment from 'moment';
import DrawerFramework from '../../../Drawer/DrawerFramework';
import basicMixin from '../../../../../mixins/basicMixin';
import functionsMixin from '../../../../../mixins/functionsMixin';
import DrawerDeleteButton from '../../../Drawer/Buttons/DrawerDeleteButton';
import tableMixin from '../../../../../mixins/tableMixin';
import MixPageOrderDetailsOneMix from './MixPageOrderDetailsOneMix';
import NoDataIcon from '@/views/components/NoDataIcon';
import MixPageOrderMixCreate from '@/views/components/MixPage/Orders/OrderMix/MixPageOrderMixCreate';
import mobileCheckMixin from '@/mixins/mobileCheckMixin';
import DrawerEditButton from '@/views/components/Drawer/Buttons/DrawerEditButton';
import {httpService} from '@/services/http.service';
import iconsMixin from '@/mixins/iconsMixin';
import getUnitFromGoodsMixin from '@/mixins/getUnitFromGoodsMixin';
import DrawerButton from "@/views/components/Drawer/Buttons/DrawerButton.vue";
import MixPageOrderDetailsInfo from "@/views/components/MixPage/Orders/OrderDetails/MixPageOrderDetailsInfo.vue";
import MixPageOrderDetailsHistory from "@/views/components/MixPage/Orders/OrderDetails/MixPageOrderDetailsHistory.vue";
import MixOrderDetailsGroupLog from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroupLog.vue";
export default {
    name: "MixPageOrderDetails",
    components: {
        MixOrderDetailsGroupLog,
        MixPageOrderDetailsHistory,
        MixPageOrderDetailsInfo,
        DrawerButton,
        DrawerEditButton,
        MixPageOrderMixCreate,
        NoDataIcon,
        MixPageOrderDetailsOneMix,
        DrawerDeleteButton,
        DrawerFramework,

    },
    props: ["orderId", "tz"],
    mixins: [
        basicMixin,
        functionsMixin,
        tableMixin,
        mobileCheckMixin,
        iconsMixin,
        getUnitFromGoodsMixin,
    ],
    inject: ["api"],
    data() {
        return {
            loading: false,
            dateStart: null,
            createMixOrderMixDialog: false,
            dateEnd: null,
            mixerTimeFromBatches: 0,
            orderDetails: {
                id: null,
                mixes: [],
                date: null,
                total: null,
                done: null,
                goods: [],
                tz: null,
                economy: {
                    totalCosts: 0,
                    totalPrice: 0,
                    totalProfit: 0,
                },
            },
            activeTab: 'info',
            statuses: [
                {
                    key: 'new',
                    name: 'Очередь'
                },
                {
                    key: 'loading',
                    name: 'Загрузка'
                },
                {
                    key: 'delivery',
                    name: 'Доставка'
                },
                {
                    key: 'object',
                    name: 'На объекте'
                },
                {
                    key: 'pouring',
                    name: 'Заливка'
                },
                {
                    key: 'return',
                    name: 'Возвращается'
                },
                {
                    key: 'done',
                    name: 'Завершена'
                }
            ],
        };
    },
    created() {
        this.getOrder();
    },
    methods: {
        openMixOrderCreateMix() {
            this.createMixOrderMixDialog = true;
        },
        closeMixOrderCreateMix() {
            this.createMixOrderMixDialog = false;
        },
        setOrderDetailsCreateMix(value) {
            this.orderDetails.createMix = value;
        },
        changeStatus() {
            if (this.orderDetails.status !== 'done') {
                this.loading = true

                httpService().post(this.api.mix.changeOrderStatus, {
                    account_module_reserve_id: this.orderDetails.mixId,
                    module_key: 'mix',
                    order: this.orderDetails
                }).then((response) => {
                    let answer = response.data

                    if (answer.success) {
                        this.loading = false
                        window.setTimeout(() => {
                            this.getOrder(true)
                        }, 150)
                    }

                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            }
        },
        getOrder(moduleGet) {
            this.loading = true;

            httpService().post(this.api.mix.getOneOrder, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: "mix",
                    orderId: this.orderId,
                }).then((response) => {
                    let data = response.data;

                    if (data.success) this.orderDetails = data.order;

                    // ищем даты начала и окончания замесов
                    // проходим по массиву и ищем самое малое dateStart и самое больше dateEnd
                    var dateStart = 0;
                    var dateEnd = 0;

                    if (this.orderDetails.mixes.length > 0) {
                        this.orderDetails.mixes.map(function (obj) {
                            if (dateStart === 0) dateStart = obj.dateStart;
                            if (obj.dateStart < dateStart)
                                dateStart = obj.dateStart;
                            if (obj.dateEnd > dateEnd) dateEnd = obj.dateEnd;
                        });

                        this.dateStart = dateStart;
                        this.dateEnd = dateEnd;

                        var duration = moment.duration(
                            moment(dateEnd).diff(moment(dateStart))
                        );
                        this.mixerTimeFromBatches = Math.round(
                            duration.asMinutes() * 1000
                        );
                    }
                    this.loading = false;
                });

            if (moduleGet) this.$emit("moduleGet");
        },
        getStatusLabel() {
            let statusLabel = null
            this.statuses.forEach((element) => {
                if (element.key === this.orderDetails.status) {
                    statusLabel = element.name
                }
            })
            return statusLabel
        },
        printOrderPass() {
            httpService().post(this.api.mix.printOrderPass, {
                account_module_reserve_id: this.$route.params.id,
                module_key: "mix",
                id: this.orderDetails.id
            }).then(({data}) => {
                let a = window.open('', '', 'height=500, width=500');
                a.document.write(data);
                a.document.close();
                a.print();
            }).catch(error => {
                console.error('Произошла ошибка:', error);
            });
        },
        print(template) {
            httpService().post(this.api.mix.printOrderDoc, {
                account_module_reserve_id: this.$route.params.id,
                module_key: "mix",
                orderId: this.orderDetails.id,
                template: template
            }, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                let type = 'Паспорт качества '
                if (template === 'invoice') type = 'Накладная '
                let doc = ' №' + (this.orderDetails.doc ?? this.orderDetails.id)
                link.href = url
                link.setAttribute('download', type + 'для отгрузки' + doc + '.html')
                document.body.appendChild(link)
                link.click()

                let data = response.data

                if (data.success)
                    this.loadingButton = false
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        deleteOrder(id) {
            this.$confirm(
                "Вы действительно хотите удалить отгрузку?",
                "Подтвердите удаление",
                {
                    confirmButtonText: "Удалить",
                    cancelButtonText: "Отмена",
                    type: "warning",
                }
            )
                .then(() => {
                    this.loading = true;

                    httpService().post(this.api.mix.removeOrder, {
                            account_module_reserve_id: this.$route.params.id,
                            module_key: this.$route.meta.moduleKey,
                            orderId: id,
                        }).then((response) => {
                            let data = response.data;

                            if (data.success)
                                this.$message({
                                    message: "Отгрузка удалена",
                                    showClose: true,
                                    type: "success",
                                });
                            this.$emit("closeDrawer");
                            this.$emit("moduleGet");
                            this.loading = false;
                        });
                }).catch(() => {
                    this.$message({
                        message: "Удаление отменено",
                        showClose: true,
                        type: "info",
                    });
                    this.loading = false;
                });
        },
        getUnits() {
            let goodsList = [];

            if (this.orderDetails.goods.length > 0) {
                this.orderDetails.goods.forEach((element) =>
                    goodsList.push(element.good.good_id)
                );
            }

            return this.getUnitFromIds(goodsList);
        },
        getTitle() {
            let title = "Отгрузка";

            if (this.orderDetails.doc)
                title = title + " №" + this.orderDetails.doc;
            if (this.orderDetails.companyId)
                title =
                    title +
                    " для " +
                    this.getData(this.orderDetails.companyId, "company");

            return title;
        },
        getDate() {
            if (this.dateStart) {
                return (
                    this.getDateByTz(
                        this.dateStart,
                        "DD.MM.YY в HH:mm",
                        this.tz
                    ) +
                    " - " +
                    this.getDateByTz(this.dateEnd, "DD.MM.YY в HH:mm", this.tz)
                );
            }

            if (!this.dateStart && this.orderDetails.date) {
                return this.getDateByTz(
                    this.orderDetails.date,
                    "DD.MM.YY в HH:mm",
                    this.tz
                );
            }

            return "-";
        },
        getProfitability() {
            if (
                this.orderDetails.economy.totalProfit &&
                this.orderDetails.economy.totalPrice
            ) {
                return (
                    (this.orderDetails.economy.totalProfit /
                        this.orderDetails.economy.totalPrice) *
                    100
                );
            } else {
                return 0;
            }
        },
        sendMixOrderToSoft() {
            this.loading = true;

            httpService()
                .post(this.api.mix.sendMixOrderToSoft, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: "mix",
                    orderId: this.orderId,
                })
                .then((response) => {
                    let data = response.data;

                    if (data.success) {
                        let messageStatus = data.status

                        this.$message({
                            message: data.message,
                            showClose: true,
                            type: messageStatus === 'create' ? 'success' : 'info',
                        });
                    } else {
                        this.$message({
                            message: 'Интеграция не найдена',
                            showClose: true,
                            type: 'error',
                        });
                    }
                }).finally(() => {
                this.loading = false;
            });

        }
    },
};
</script>

<style lang="scss">
.mixOrderDetailsBlock {
    position: relative;

    .tooltipEl {
        position: absolute;
        right: 26px;
        bottom: 26px;
    }
}
</style>
