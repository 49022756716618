<template>
    <el-table style="height: 270px"
              v-if="tab === 'document_goods'"
              :data="data"
              class="w-100 mt-15 serviceTable"
              :default-sort="{ prop: 'id', order: 'descending' }">

        <!-- Продукция -->
        <el-table-column prop="type" :label="tableLabel">
            <template v-slot="scope">
                <span class="cutOverflow textSM textMedium neutral900">
                    {{ scope.row.good.name ?? '-' }}
                </span>
            </template>
        </el-table-column>
        <el-table-column prop="total" label="Кол-во" width="85">
            <template v-slot="scope">
                <span class="cutOverflow textSM textMedium"
                      :class="scope.row.price ? 'neutral900' : 'neutral200'">
                    {{ scope.row.total ? formatNumber(scope.row.total, 2) : '-' }}
                </span>
            </template>
        </el-table-column>

        <el-table-column prop="price" label="Цена (за ед.)" width="115">
            <template v-slot="scope">
                <span :class="['cutOverflow textSM textMedium', scope.row.name ? 'success400' : 'neutral200']">
                    {{ scope.row.price ? formatNumber(scope.row.price, 2) : '-' }}
                </span>
            </template>
        </el-table-column>

        <el-table-column prop="vat" label="НДС (%)" width="70">
            <template v-slot="scope">
                <span :class="['cutOverflow textSM textMedium', scope.row.price ? 'neutral900' : 'neutral200']">
                    {{ scope.row.vat ? formatNumber(scope.row.vat) : 0 }}%
                </span>
            </template>
        </el-table-column>
        <el-table-column prop="full_price" label="Итого" width="140">
            <template v-slot="scope">
                <span :class="['cutOverflow textSM textMedium', scope.row.name ? 'primary600' : 'neutral200']">
                    {{ scope.row.full_price ? formatNumber(scope.row.full_price, 2) : '-' }}
                </span>
            </template>
        </el-table-column>
    </el-table>

    <el-table v-else-if="tab === 'addresses'"
              style="height: 270px"
              :data="data"
              class="w-100 mt-15 serviceTable"
              :default-sort="{ prop: 'id', order: 'descending' }">

        <el-table-column prop="address" label="Объекты">
            <template v-slot="scope">
                <span :class="['cutOverflow textSM textMedium', scope.row.name ? 'neutral900' : 'neutral200']">
                    {{ scope.row.name ?? '' }}
                </span>
            </template>
        </el-table-column>
    </el-table>

    <el-table v-else-if="tab === 'mix' || tab === 'downtime' || tab === 'pump'"
              style="height: 270px"
              :data="data"
              class="w-100 mt-15 serviceTable"
              :default-sort="{ prop: 'id', order: 'descending' }">

        <!-- Тарифы -->
        <el-table-column :prop="tab" label="Тариф">
            <template v-slot="scope">
                <span :class="['cutOverflow textSM textMedium', scope.row.name ? 'neutral900' : 'neutral200']">
                    {{ scope.row.name ?? '' }}
                </span>
            </template>
        </el-table-column>
        <el-table-column :prop="tab" label="Тип тарифа" width="240">
            <template v-slot="scope">
                <span :class="['cutOverflow textSM textMedium', scope.row.depends_on ? 'neutral900' : 'neutral200']">
                    {{ getInfo(scope.row.depends_on, dependsTypes) }}
                </span>
            </template>
        </el-table-column>
        <el-table-column :prop="tab" width="120">
            <template v-slot="scope">
                <el-button class="crm-tabs-table__btn crm-tabs-table__btn--details ml-auto"
                           @click="openTariffDetailsDialog(scope.row)">
                    Подробнее
                </el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-dialog v-model="tariffDetailsDialog"
               @close="closeTariffDetailsDialog"
               :title="detailsTitle"
               :width="mobile ? '100%' : '80%'"
               :destroy-on-close="true">
        <ServiceTariffDetails :id="service?.id" />
    </el-dialog>
</template>

<script>

import ServiceTariffDetails from "@/views/settings/serviceTariffs/components/ServiceTariffDetails.vue";
import iconsMixin from '@/mixins/iconsMixin';
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import serviceInfo from "@/mixins/serviceInfo";

// icons
import BinIcon from '@/views/components/Icons/BinIcon.vue';

export default {
    name: 'CRMTabsDetailsTable',
    components: { ServiceTariffDetails },
    mixins: [iconsMixin, functionsMixin, serviceInfo, mobileCheckMixin],
    props: ['tab', 'data', 'tableLabel'],
    data() {
        return {
            binIcon: BinIcon,
            tariffDetailsDialog: false,
            service: null // выбранный по кнопке "подробнее" тариф
        }
    },
    methods: {
        openTariffDetailsDialog(tariff) {
            this.service = tariff
            this.tariffDetailsDialog = true
        },
        closeTariffDetailsDialog() {
            this.service = null
            this.tariffDetailsDialog = false
        },
    },
    computed: {
        detailsTitle() {
            if(!this.service) return 'Подробности тарифа'
            return "Подробности тарифа " + '"' + this.service.name + '"'
        }
    },
};
</script>
