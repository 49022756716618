<template>
    <el-checkbox class="dropdown-filter-checkbox" v-model="isChecked" :disabled="disabled">{{label}}</el-checkbox>
</template>

<script>
export default {
    name: "DropdownFilterCheckbox",
    props: ['modelValue', 'label', 'disabled'],
    data() {
        return {
            isChecked: this.modelValue
        }
    },
    watch: {
        'modelValue': {
            handler: function(val) {
                this.isChecked = val
            },
            deep: true,
            immediate: true
        },
        'isChecked': {
            handler: function (val) {
                this.$emit('update:modelValue', val)
            },
            deep: true,
            immediate: true
        }
    }

}
</script>

<style scoped lang="scss">
@use "@/sass/_variables.scss";

.dropdown-filter-checkbox {
    height: 20px;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &:deep(.el-checkbox__input.is-checked)  {
        .el-checkbox__inner {
            border-color: variables.$primary600 !important;
            background-color: variables.$primary50 !important;
            border-radius: 4px;

            &:after {
                border-color: variables.$primary600 !important;
            }
        }
    }
}

.dropdown-filter-checkbox.is-checked {
    .el-checkbox__label {
        color: variables.$primary600 !important;
    }
}
</style>
