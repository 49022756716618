<template>
    <el-form-item :label="label" :label-width="labelWidth" v-bind:class="[extraClass, { 'is-required': required }]">
            <div class="inputModal"
                 :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                 ]">
                <div class="d-flex">
                    <div class="inputModalWrapper" @click="openSelectCompanyDialog">
                        {{ itemId ? this.getData(itemId, 'company') : this.placeholder }}
                    </div>
                    <div class="inputModalClear" v-if="itemId && !disabled" @click="clearItemId()">
                        <i class="el-icon el-input__icon el-input__clear">
                            <CloseRoundIcon/>
                        </i>
                    </div>
                </div>
            </div>
    </el-form-item>

    <el-dialog
        class="formSelectCompanyDialog"
        v-model="selectCompanyDialog"
        title="Выберите компанию"
        :width="dialogSize"
        @close="handleClose"
        @close-on-click-modal="handleClose"
        :destroy-on-close="true"
    >
        <template v-if="loadingData">
            <TableDataLoader text="компаний"/>
        </template>
        <template v-else>
            <template v-if="!companyCreateFormShow">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-update-button="true"
                    :search-focused="true"
                    create-button-label="Добавить компанию"
                    @moduleGet="getCompaniesData()"
                    @openCreateValueDrawer="() => companyCreateFormShow = true"
                >
                    <template v-slot:afterSearchAndFilter>
                        <TableIntegrationFilter
                            v-if="!hideIntegrationFilter"
                            v-model="filters.integrationUnitId"
                            @moduleGet="getCompaniesData"
                            :table="true"
                        />

                        <el-select
                            v-model="filters.companyType"
                            placeholder="Тип компании"
                            size="large"
                            class="filterSelect"
                            style="width: 180px"
                            @change="getCompaniesData()"
                            clearable
                        >
                            <el-option
                                v-for="item in companyTypes"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </template>
                </TableHeadBlock>
                <el-table
                    empty-text="Информация не найдена"
                    class="mt-15 w-100 defaultTableHeight"
                    @current-change="handleCurrentChange"
                    v-loading="loadingTable"
                    :data="companiesData">

                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        class-name="pointer"
                        sortable>
                        <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        class-name="pointer"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900 cutOverflow">{{
                                    scope.row.short_name ? scope.row.short_name : scope.row.name
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="inn"
                        label="ИНН"
                        class-name="pointer"
                        width="140"
                    >
                        <template v-slot="scope">
                            <span :class="'textMedium textSM ' + (scope.row.inn ? 'primary600' : 'neutral400')">{{
                                    scope.row.inn ?? '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration"
                        label="Интеграция"
                        width="220px">
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <IntegrationLogo
                                    :integration-unit-id="scope.row.integration_unit_id"
                                />
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    :loading="loading"
                    @action="setPage"
                />
            </template>
            <template v-else>
                <el-form label-position="top">
                    <FormDadataINN
                        v-model="form.inn"
                        label="ИНН"
                        placeholder="ИНН или название компании"
                        @info="info"
                    />
                    <FormInput
                        v-model="form.name"
                        label="Название контрагента"
                        placeholder="Введите полное название контрагента"
                    />
                    <hr class="mb-30 mt-30"/>
                    <div>
                        <el-button @click="createCompany()" type="primary">Добавить контрагента</el-button>
                        <el-button @click="() => companyCreateFormShow = false">Отмена</el-button>
                    </div>
                </el-form>
            </template>
        </template>
    </el-dialog>

</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import FormInput from "@/views/components/Form/FormInput.vue";
import {httpService} from "@/services/http.service";
import {mapActions, mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import FormDadataINN from "@/views/components/Form/FormDadataINN.vue";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";
import companyTypes from "@/mixins/companyTypes";
import CloseRoundIcon from "@/views/components/Icons/CloseRoundIcon.vue";

export default {
    name: 'FormSelectCompany',
    components: {
        TableIntegrationFilter,
        TableDataLoader,
        IntegrationLogo,
        TableHeadBlock,
        TablePagination,
        FormDadataINN,
        FormInput,
        CloseRoundIcon,
    },
    mixins: [functionsMixin, mobileCheckMixin, tableMixin, companyTypes],
    inject: ['api'],
    props: [
        'modelValue',
        'items',
        'label',
        'labelWidth',
        'placeholder',
        'loading',
        'newItem',
        'disabled',
        'companyType',
        'required',
        'integrationUnitId', // фильтр по интеграции,
        'extraClass', // дополнительные классы для стилизации,
        'disableCreateButton', // cкрытие кнопки добавления нового элемента в модалке
        'hideIntegrationFilter', // скрыть фильтр интеграции
    ],
    data() {
        return {
            itemId: this.modelValue,
            loadingButton: false,
            dialog: false,
            filters: {
                goodType:  null,
                companyType: null,
                carrier: null,
                integrationUnitId: null
            },
            form: {
                name: null,
                phone: null,
                inn: null,
                fact_address: null,
                carrier: this.carrierCompany ?? false,
                type: this.companyType !== 'carrier' ? this.companyType : null,
                unit: null,
            },
            selectCompanyDialog: false,
            loadingData: true,
            companiesData: [],
            loadingTable: false,
            companyCreateFormShow: false,
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
            companiesFirstRequest: 'getCompaniesFirstRequest',
        }),
    },
    created() {
        if (this.companyType) {
            this.filters.companyType = this.companyType
            this.filters.companyType === 'carrier' ? this.filters.carrier = true : false
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'pullCompanies',
            'pullGoods',
        ]),
        openSelectCompanyDialog() {
            if (!this.disabled) {
                let storeItems = this.companiesFirstRequest?.companies?.data ?? []
                if (this.page === 1 && storeItems.length > 0 && !this.companyType  && !this.integrationUnitId) {
                    this.loadingData = false
                    this.loadingTable = false
                    this.companiesData = this.companiesFirstRequest.companies.data
                    this.valueLength = this.companiesFirstRequest.companies.total
                } else {
                    this.getCompaniesData()
                }
                this.search = null
                this.filters.integrationUnitId = this.integrationUnitId ?? null
                this.selectCompanyDialog = true
            }
        },
        handleCurrentChange(val) {
            if (val) {
                this.selectItem(val.id)
            }
            this.$emit('getChangedValue', val)
        },
        getCompaniesData() {
            this.loadingTable = true
            httpService().post(this.api.company.get, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                filter: {
                    ...this.filters,
                    companyType: this.filters.companyType !== 'carrier' ? this.filters.companyType : null,
                    carrier: this.filters.companyType === 'carrier' ? true : null
                },
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.companiesData = data.companies.data
                    this.valueLength = data.companies.total
                    this.loadingData = false
                    this.loadingTable = false
                }
            })
        },
        selectItem(id) {
            this.itemId = id
            this.$emit('changeValue')
            this.selectCompanyDialog = false
        },
        filterValues() {
             this.filtered = this.values
            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            }

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        getDialogTitle() {
            if (this.newItem === 'company' || this.newItem === 'companySimple') return 'Добавление контрагента'
            return 'Создание нового элемента'
        },
        info(info) {
            if (info) {
                this.form.name = info.name.short_with_opf
            }
        },
        clearItemId() {
            this.itemId = null
            this.$emit('clearAction')
        },
        createCompany() {
            this.loadingButton = true
            httpService().post(this.api.company.create, {
                company: this.form
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    })
                    this.values = data.companies
                    this.companiesData = data.companies
                    this.itemId = data.company.id
                    this.dialog = false
                    this.companyCreateFormShow = false
                    this.pullCompanies()
                    this.selectCompanyDialog = false

                } else {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка при создании контрагента',
                    showClose: true,
                    type: 'error'
                })
            })
            this.loadingButton = false
        },
        handleClose() {
            this.companyCreateFormShow = false;
            this.filters.integrationUnitId = this.integrationUnitId
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemId = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'itemId': {
            handler: function () {
                this.$emit('update:modelValue', this.itemId)
            },
            deep: true,
            immediate: true
        },
        'items': {
            handler: function () {
                if (this.items) {
                        this.values = this.items
                }
            },
            deep: true,
            immediate: true
        },
        'integrationUnitId': {
            handler: function (val) {
                if(val) {
                    this.filters.integrationUnitId = this.integrationUnitId
                }
            },
            deep: true,
            immediate: true
        },
        pageSize() {
                this.getCompaniesData()
        },
        page() {
                this.getCompaniesData()

        },
        search() {
                this.getCompaniesData()
        },
    }
}
</script>

<style scoped>
.map {
    width: 100%;
    height: 100%;
}
</style>
