<template>
    <div
        v-if="factories.length > 1 && !isDriver"
        class="object-selector"
        ref="selector"
        :class="{
            'opened-down': show && direction == 'down',
            'opened-up': show && direction == 'up',
        }"
    >
        <div @click.prevent="toggleSelector" class="object-selector-current-selected">
            <div v-if="!selectedFactory" class="object-selector-current-selected-label-text">
                Все объекты
            </div>

            <div v-else class="object-selector-current-selected-label-text object-selector-current-selected-label-text__selected">
                <div>Объект</div>
                <div>{{ selectedFactory.name }}</div>
            </div>
            <div class="object-selector-current-selected-arrow-icon">
                <ArrowDown v-if="!show"/>
                <ArrowUp v-else/>
            </div>
        </div>

        <div v-show="show" ref="options" class="object-selector-options">
            <div v-if="selectedFactory" @click.prevent="selectFactory(null)" class="object-selector-option">
                Все объекты
            </div>
            <div v-for="factory in factories" @click.prevent="selectFactory(factory)" :key="factory.id" class="object-selector-option">
                {{ factory.name }}
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import {ArrowDown, ArrowUp} from "@element-plus/icons-vue";

export default {
    name: "NavigationFactorySelector",
    components: {ArrowUp, ArrowDown},
    props: [
        'selectedFactory'
    ],
    computed: {
        ...mapState(useCommonStore, {
            factories: 'getFactories',
            isDriver: 'isDriver'
        })
    },
    data() {
        return {
            show: false,
            direction: 'down'
        }
    },
    methods: {
        toggleSelector() {
            this.show = !this.show;
            this.$nextTick(() => {
                if (window.innerHeight - this.$refs['selector'].getBoundingClientRect().bottom < this.$refs['options'].clientHeight) {
                    this.direction = 'up';
                } else {
                    this.direction = 'down';
                }
            });
        },

        selectFactory(factory) {
            this.$emit('selectedFactory', factory)
            this.show = !this.show;
        }
    }
}
</script>

<style scoped>

</style>
